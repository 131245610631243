import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Task } from "../models/types";
import { RESET_STORE } from '../utils/consts';


const initialState: Task = {
  id: 0 ,
  name: '',
  description: '',
  updatedAt: '',
  company_id: 0,
  actions: [],
  users: [],
  priority: false,
  logs:[],
  taskAssignLogs:[],
  taskForwardLogs: [],
  Tasks_Actions_joins: [],
  Tasks_Users_joins: [],
}

const selectedTaskSlice = createSlice({
name: 'SelectedAction',
initialState,
reducers: {
  setSelectedTask: (state, action: PayloadAction<Task>) => {
    return action.payload
  },
  resetSelectedTask: () => initialState
},
  extraReducers: (builder) => {
    builder.addCase(RESET_STORE, () => initialState);
  },
})

export const {setSelectedTask, resetSelectedTask} = selectedTaskSlice.actions
export default selectedTaskSlice.reducer
