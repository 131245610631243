import React, { useState, MouseEvent, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ActionObject, ActionWithEmpty, IMessage, Process } from "../../models/types";
import {setActionEditMode} from "../../slices/editActionModeSlice";
import {arrowsHover} from "../../slices/arrowsHoverSlice";
import { setSelectedAction } from "../../slices/selectedActionSlice";
import  {
  setCreateActionClicked,
} from "../../slices/createActionClickedSlice";

import { setActionClicked } from "../../slices/actionClickedSlice";
import { setHeatMapMode } from "../../slices/heatMapModeSlice";
import { setEditMapMode } from "../../slices/editMapModeSlice";
import ky from "ky";
import {initialState, setActionObject} from "../../slices/actionObjectSlice";
import { setTabName } from "../../slices/tabsInActionSlice";
import arrowsBuilder from "../../utils/functions/arrowsBuilder";
import arrowsHoverBuilder from "../../utils/functions/arrowsHoverBuilder";
import arrowsHeatmapBuilder from "../../utils/functions/arrowsHeatmapBuilder";
import { setProcess } from "../../slices/processObjectSlice";
import { setTaskClicked } from "../../slices/taskClickedSlice";
import rightMenuVisibility, { setRightMenuVisibility } from "../../slices/rightMenuVisibility";
import {resetSelectedTask} from "../../slices/selectedTaskSlice";
import { isNullOrUndef } from "chart.js/dist/helpers/helpers.core";


const card_width: number = 210;
const card_height: number = 88;
const gap_width: number = 80;
const gap_height: number = 80;
const padding_height_coef: number = 1;
const row_max: number = 9;
const line_max: number = 9;
const API_BASE_URL = process.env.REACT_APP_BASE_URL;

const Actions: React.FC = () => {

  const dispatch = useDispatch();
  const hoverArrows = useSelector((state: RootState) => state.arrowsHover)
  const process = useSelector((state: RootState) => state.process);
  const actionObj = useSelector((state: RootState) => state.actionObject);
  const [actionHoverId, setActionHoverId] = useState<number>(0)
  const data = useSelector((state: RootState) => state.process.actionsFromProcess.actions);
  const selected = useSelector((state: RootState) => state.actionClicked)
  const [linkActionIdStart, setLinkActionIdStart] = useState<number | boolean>(false)
  const placeholders = generatePlaceholders(row_max, line_max);
  const result: ActionWithEmpty[] = modifyData(placeholders, data);
  const allActionArr = Array.from(new Set<number>(result.map(el => el.id)));
  const user = useSelector((state: RootState) => state.user)
  const heatMapMode = useSelector((state: RootState) => state.heatMapMode)
  const editMapMode = useSelector((state: RootState) => state.editMapMode)

  const selectedAction = useSelector((state: RootState) => state.selectedAction)

  function generatePlaceholders(row_max: number, line_max: number) {
    const data: ActionWithEmpty[] = [];
    for (let line = 0; line < line_max; line++) {
      for (let row = 0; row < row_max; row++) {
        data.push({
          id: 0,
          empty: true,
          name: "",
          row,
          line,
          teams: [],
          description: "",
          filterTeams: [],
          tasks: [],
          processes_quantity: 0,
          From: [],
          To: [],
        });
      }
    }
    return data;
  }


  function modifyData(data: ActionWithEmpty[], info: ActionWithEmpty[]): ActionWithEmpty[] {
    for (let i = 0; i < info.length; i++) {
      const infoElement = info[i];
      for (let j = 0; j < data.length; j++) {
        const dataElement = data[j];
        if (
            dataElement.row === infoElement.row &&
            dataElement.line === infoElement.line
        ) {
          dataElement.name = infoElement.name;
          dataElement.empty = false;
          dataElement.id = infoElement.id;
          dataElement.row = infoElement.row;
          dataElement.line = infoElement.line;
          dataElement.teams = infoElement.teams;
          dataElement.description = infoElement.description;
          dataElement.filterTeams = infoElement.filterTeams;
          dataElement.tasks = infoElement.tasks;
          dataElement.processes_quantity = infoElement.processes_quantity
          dataElement.To = infoElement.To;
          dataElement.From = infoElement.From;
        }
      }
    }
    return data;
  }


  const handleActionClick = async (action: ActionWithEmpty) => {
    dispatch(setSelectedAction(action));
    if (action.id === 0) {
      dispatch(setCreateActionClicked(true));
      dispatch(setActionClicked(true));
      dispatch(setActionObject(initialState));
      dispatch(setActionEditMode(true));
      dispatch(arrowsHover(false));
      dispatch(setTaskClicked(false));
      dispatch(resetSelectedTask());

    } else {
      const actionObj: ActionObject = await ky.post(`${API_BASE_URL}/actions/focus`, {
        credentials: 'include',
        json: {actionid: action.id}
      }).json()
      dispatch(setActionEditMode(false));
      dispatch(setActionObject(actionObj))
      dispatch(setCreateActionClicked(false));
      setActionHoverId(action.id);
      if(heatMapMode){
        dispatch(setTabName({title: 'monitoring'}))
      }else{
      if(actionObj.tasks.length !== 0){dispatch(setTabName({title: 'tasks'}))}
      else{dispatch(setTabName({title: 'description'}))}

      }
      dispatch(setActionClicked(true))
      dispatch(arrowsHover(true));
      dispatch(setTaskClicked(false));
      dispatch(resetSelectedTask());
    }

    dispatch(setRightMenuVisibility(true));
  };

  const handleActionMouseOver = async (action: ActionWithEmpty) => {
    if (!selected) {
      dispatch(arrowsHover(!(action.id === 0)));
      setActionHoverId(action.id);
    }
  };

  
  const handleActionMouseLeave = async () => {
    if (!selected) {
      dispatch(arrowsHover(false));
      setActionHoverId(0);
    }
  };


  const processData :any = {

  }

  function noHover(actionId: number, e: MouseEvent) {
    e.stopPropagation()
    dispatch(setActionClicked(false))
    dispatch(arrowsHover(false));
    setLinkActionIdStart(actionId);
  }

  async function addLink(actionIdEnd: number, e: MouseEvent) {
    e.stopPropagation()
    dispatch(setActionClicked(false))
    dispatch(arrowsHover(false));
    const tryCreateLink: IMessage = await ky.post(`${API_BASE_URL}/actions/link`, {
      credentials: 'include',
      json: {
        fromActionId: linkActionIdStart,
        toActionId: actionIdEnd
      }
    }).json();

    if (tryCreateLink.message === 'created') {
      const data: Process = await ky.post(`${API_BASE_URL}/process/focus`, {
        json: {processId: process.actionsFromProcess.id},
        credentials: 'include'
      }).json();
      dispatch(setProcess(data));
    }
    setLinkActionIdStart(false)
  }

  //drag action
  const dragItem = useRef<any>(null);
  const dragOverItem = useRef<{ row: number, line: number } | null>();

  const dragStart = (e: MouseEvent, dragData: { row: number, line: number, id: number }) => {
    dragItem.current = null
    dragItem.current = dragData;
  };

  const dragEnter = (e: MouseEvent, position: { row: number, line: number }) => {
    dragOverItem.current = position;
  };

  const drop = async (e: MouseEvent, bool: boolean) => {

    const arrayOfNonEmpty = result.filter((el) => el.empty !== true)

    const credo = arrayOfNonEmpty.some((el) => el.row === dragOverItem.current?.row && el.line === dragOverItem.current?.line)

    const dragItem_temp = dragItem.current
    
    if (credo || !dragItem.current) {
      dragItem.current = null;
      dragOverItem.current = null;
      return null
    }


    const temporary_actionsFromProcess_actions = process.actionsFromProcess.actions.map((el)=> {
      if(el.id !== dragItem.current.id){
        return el
      }else{
        return {...el, row: dragOverItem.current?.row || 0, line:dragOverItem.current?.line|| 0}
      }
    })



    const temporary_arrowsData = process.arrowsData.map((el)=> {
      if(el.from_row === dragItem.current?.row && el.from_line === dragItem.current?.line){
        return {...el, from_row : dragOverItem.current?.row || 0, from_line: dragOverItem.current?.line ||0}
      }
      if(el.to_row === dragItem.current?.row && el.to_line === dragItem.current?.line){
        return {...el, to_row : dragOverItem.current?.row || 0, to_line: dragOverItem.current?.line ||0}
      }
      return el
    })

    dispatch(setProcess({...process, 
      actionsFromProcess: {
        ...process.actionsFromProcess, 
        actions :temporary_actionsFromProcess_actions, 
      },
      arrowsData: temporary_arrowsData

    }));

    const updatePosition: any = await ky.put(`${API_BASE_URL}/actions/drag`, {
      credentials: 'include', json: {
        processId:process.actionsFromProcess.id,
        actionId: dragItem.current.id,
        row: dragOverItem.current?.row,
        line: dragOverItem.current?.line
      }
    }).json();

    if (updatePosition.message === 'created') {
      const data: Process = await ky.post(`${API_BASE_URL}/process/focus`, {
        json: {processId: process.actionsFromProcess.id},
        credentials: 'include'
      }).json();
      dispatch(setProcess(data));
    }

    dragItem.current = null;
    dragOverItem.current = null;
  };
  //end drag action
  // graph scale logic
  const [graphScale, setGraphScale] = useState<number>(0.8)

  //start drag and scroll logic
  const comp = useRef<any>(null)

  let startX: number;
  let scrollTop: number;
  let isDown: boolean;
  let startY: number;
  let scrollLeft: number;

  function handleDragBackground(e: MouseEvent) {
    e.preventDefault()
    isDown = true;
    startX = e.pageX - comp.current.offsetLeft;
    startY = e.pageY - comp.current.offsetTop;
    scrollTop = comp.current.scrollTop;
    scrollLeft = comp.current.scrollLeft;
  }

  function mouseUp(e: MouseEvent) {

    isDown = false;
  }

  function mouseMove(e: MouseEvent) {
    if (isDown) {
      e.preventDefault();
      const y = e.pageY - comp.current.offsetTop;
      const x = e.pageX - comp.current.offsetLeft;
      const walkX = (x - startX);
      const walkY = (y - startY);
      comp.current.scrollLeft = scrollLeft - walkX;
      comp.current.scrollTop = scrollTop - walkY;
    }
    return
  }

  function haveCommonTeams(checkUser: any, teamList: any){
    if (!teamList) {
      return false
    }
    for(let i = 0; i< checkUser.teams.length; i++)
    {
      for(let j = 0; j< teamList.length; j++)
      {
        if( checkUser.teams[i].id === teamList[j].id)
        {
          return true
        }
      }
    }
    return false
  }

  function opacityCardValue(action: ActionWithEmpty){
    if (selected) {
      if( allActionArr.filter(el => [...actionObj.From, ...actionObj.To, actionObj]
      .map((el) => el.id).includes(el)).includes(action.id)){
        return 1
      }
      else
      {
        return 0.2
      }
    }
    if(!selected &&  (allActionArr.filter(el => [...action?.From, ...action?.To, action]
      .map((el) => el.id).includes(el)).includes(actionHoverId)) || !hoverArrows || actionHoverId === 0){
        return 1
      }
    return 0.2
  }

  function glowClass(action: ActionWithEmpty){
    if (action.id != 0 && heatMapMode)
    {
      if (action.id % 7 == 0){return ' positive-glow'}
      if(action.id %7 ==1){return ' negative-glow'}
      return ' neutral-glow'
    }
    return ''
  }

  function avgActionKPI(action: ActionWithEmpty){
    if (action.id != 0 && heatMapMode)
    {
      if (action.id % 7 == 0){return `~${action.id %4}h per task`}
      if(action.id %7 ==1){return `~${action.id %2}d per task`}
      return `~${action.id %10}h per task`
      
    }
  }

  function avgActionKPIGlowClass(action: ActionWithEmpty){
    if (action.id != 0 && heatMapMode)
    {
      if (action.id % 7 == 0){return ' positive-counter'}
      if(action.id %7 ==1){return ' negative-counter'}
      return ' neutral-counter'
    }
  }

  //end drag ad scroll logic
  if(process.actionsFromProcess.id !== 0){
  return (
      <div className="process-space-center">
        <div className="graph-background" ref={comp} onMouseDown={(e) => handleDragBackground(e)}
             onMouseUp={(e) => mouseUp(e)} onMouseMove={(e) => mouseMove(e)}
             onMouseLeave={(e) => mouseUp(e)}
             onClick={() => setLinkActionIdStart(false)}>
          <div style={{transform: `scale(${graphScale})`}} className="graph"                        onMouseLeave={() => handleActionMouseLeave()}>
            {/*!hoverArrows ?
                <>
                  {arrowsBuilder(process.arrowsData)}
                </>
                :
                <>
                  {arrowsHoverBuilder(process.arrowsData, actionHoverId)}
                </>
            */}
              { heatMapMode &&false  ? 
                <>
                  {/*arrowsHeatmapBuilder(process.arrowsData, processData)*/}
                </>
                :
                <>
                  {arrowsBuilder(process.arrowsData, !hoverArrows)}
                  {arrowsHoverBuilder(process.arrowsData, actionHoverId)}
                </>
                }
            <div className="cardslayer">

              {result.map((action) => (
                  // ACTION.EMPTY = 0 - ETO SUKA ZAPOLNENNIY CARD
                  <div
                      ref={dragItem}
                      className={
                        (action.empty === false ? `${actionObj.id === action.id && selected ?  'action-card active' : 'action-card'}` : `${selectedAction?.row === action.row && selected && selectedAction?.line === action.line && user.role === "founder" ? "action-card-placeholder-active" : "action-card-placeholder"} `)
                        + (` ${action.processes_quantity > 1 ? 'action-card-multi-process' : ''}`) + glowClass(action)
                        + (`${action.empty === true  && editMapMode ? 'action-card-placeholder-with-hover':''}`)
                      }
                      key={action.id !== 0 ? "action-card-" + action.id : "action-card-placeholder" + action.row + ":" + action.line}
                      style={{
                        left: gap_width * (action.row + 1) + card_width * action.row,
                        top:
                            gap_height * (action.line + padding_height_coef) +
                            card_height * action.line,
                        opacity: `${opacityCardValue(action)}`,
                        display: `${action.empty === true  && !editMapMode?'none' : ''}`
                      }}
                      onClick={action.empty === true  && !editMapMode? ()=>{} : () => handleActionClick(action)}
                      onMouseEnter={() => handleActionMouseOver(action)}
                      onDragStart={!action.empty ? (e) => {
                        dragStart(e, {id: action.id, row: action.row, line: action.line}); 
                        const element = e.target as HTMLInputElement  
                        /*element.style.visibility = "hidden";*/} : () => {
                      }}
                      onDragEnter={(e) => dragEnter(e, {row: action.row, line: action.line})}
                      onDragEnd={(e) => drop(e, action.empty!)}
                      draggable={!action.empty ? true : false}
                      
                      onMouseDown={!action.empty ? (e) => e.stopPropagation() : () => {
                      }}

                  >
                    {action.empty === false && !heatMapMode && editMapMode?
                        <div
                            onClick={linkActionIdStart ? (e) => addLink(action.id, e) : (e) => noHover(action.id, e)
                            }
                            className={linkActionIdStart ? "action-card-right-btn-no-hover material-symbols-outlined" : "action-card-right-btn material-symbols-outlined"}
                        >
                          {linkActionIdStart ? 'line_end_square' : 'line_end_arrow'}
                        </div> : <></>}
                    <b className="name">{action.empty === false ? action.name : "+"}</b>
                    {!heatMapMode?
                    <div className="teams">
                      {action.filterTeams ?
                          action.filterTeams.map((team) => (
                              <div
                                  className="tag"
                                  key={Math.ceil(Math.random() * 999999)}
                                  style={{backgroundColor: `${team.hex_code}`}}
                              >
                              </div>
                          ))
                          :
                          <div className="tag" key={Math.ceil(Math.random() * 999999)}></div>
                      }
                    </div>
                    :<></>}

                    <div className="right-bottom-icons">
                      
                      {action.empty === false && action.tasks?.length !== 0 && !heatMapMode ?
                        <small className={haveCommonTeams(user, action.filterTeams) ? "task-counter task-counter-active" : "task-counter"}>{action.tasks?.length}</small>
                        : <></>
                      }
                      {heatMapMode?
                        <small className={"task-counter" + avgActionKPIGlowClass(action)}>{avgActionKPI(action)}</small>
                        :
                        <></>
                      }

                      {action.processes_quantity > 1 ? 
                        <div style={{color:'#fff'}}className="icon material-symbols-outlined" >layers</div> 
                        : <></>
                      }

                    </div>

                  </div>
              ))}
              <div className="right-margin-holder" style={{left: row_max * card_width + row_max * gap_width}}></div>
            </div>
          </div>
        </div>
        <div className="center-header">
          <div className="top">
            <div className="caption">
              {/*<div className={!heatMapMode && !editMapMode ? "icon active-icon material-symbols-outlined" : "icon material-symbols-outlined"} 
              onClick={()=>{
                  dispatch(setEditMapMode(false));
                  dispatch(setHeatMapMode(false));
              }}>
                account_tree
            </div>*/}
              <div className={editMapMode ? "icon active-icon material-symbols-outlined" : "icon material-symbols-outlined"} 
              onClick={()=>{
                if (!editMapMode){
                  dispatch(setEditMapMode(true));
                  dispatch(setHeatMapMode(false));
                }
                else{
                  dispatch(setEditMapMode(false));
                }
              }}>
               edit
              </div>
              <div className={heatMapMode ? "icon active-icon material-symbols-outlined" : "icon material-symbols-outlined"} 
              onClick={()=>{

                if (!heatMapMode){
                  dispatch(setEditMapMode(false));
                  dispatch(setHeatMapMode(true));
                }
                else{
                  dispatch(setHeatMapMode(false));
                }
              }}>
                query_stats
              </div>
              

              <h1 className="process-name">
                {process.actionsFromProcess?.name}
              </h1>
            </div>

            {/* <div className="btns">
                  <b className="inline-btn"> + link</b>
                </div> */}
          </div>
          <div className="bottom">

            {process.teamsData.length ? process.teamsData.slice()
                    .sort((a, b) => a.localeCompare(b)).map((team) => (
                        <div className="teamtag" key={Math.ceil(Math.random() * 999999)}
                             style={{background: process.teamsTagWay[team]}}>
                          <b key={Math.ceil(Math.random() * 999999)}>
                            @
                            {team}
                          </b>
                        </div>
                    ))
                : <></>}
          </div>

        </div>
        <div className="scale-slider">
          <div className={graphScale === 1 ? "active-btn" : "btn"} onClick={() => setGraphScale(1)}>
            <div className="icon material-symbols-outlined">width_full</div>
          </div>
          <div className={graphScale === 0.8 ? "active-btn" : "btn"} onClick={() => setGraphScale(0.8)}>
            <div className="icon material-symbols-outlined">width_wide</div>
          </div>
          <div className={graphScale === 0.6 ? "active-btn" : "btn"} onClick={() => setGraphScale(0.6)}>
            <div className="icon material-symbols-outlined">width_normal</div>
          </div>

        </div>
      </div>
  );
}
  return <></>;

};

export default Actions;

