import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RESET_STORE } from '../utils/consts';

const initialState: boolean = false

const editMapModeSlice = createSlice({
    name: 'editMapMode',
    initialState,
    reducers: {
        setEditMapMode: (state, action: PayloadAction<boolean>) => {
            return action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
})

export const {setEditMapMode} = editMapModeSlice.actions
export default editMapModeSlice.reducer
