
import LeftBar from '../../components/LeftBarFounder/LeftBar';
import Actions  from '../../components/Graph/Actions';
import RightMenuProcess from '../../components/RightMenuFounder/RightMenuProcess';
import RightMenuAction from '../../components/RightMenuFounder/RightMenuAction';
import RightMenuActionCreate from '../../components/RightMenuFounder/RightMenuActionCreate';
import RightMenuActionEdit from '../../components/RightMenuFounder/RightMenuActionEdit';
import MiddleMenuTask from "../../components/RightMenuFounder/MiddleMenuTask";
import RightMenuProcessCreate from '../../components/RightMenuFounder/RightMenuProcessCreate';
import MiddleMenuTaskCreate from '../../components/RightMenuFounder/MiddleMenuTaskCreate';
import MiddleMenuTaskEdit from '../../components/RightMenuFounder/MiddleMenuTaskEdit';
import RightMenuProcessEdit from '../../components/RightMenuFounder/RightMenuProcessEdit';
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ky from "ky";
import {setProcess} from "../../slices/processObjectSlice";
import {Process} from "../../models/types";
const API_BASE_URL = process.env.REACT_APP_BASE_URL;


export default function HomePageFounder():JSX.Element {

  const createProcessClicked= useSelector((state: RootState) => state.createProcessClickedSlice)
  const createAction = useSelector((state: RootState) => state.createActionClicked)
  const actionClicked = useSelector((state:RootState) => state.actionClicked );
  const editActionMode = useSelector((state:RootState) => state.editActionMode);
  const editTaskMode = useSelector((state:RootState) => state.editTaskMode)
  const editProcessMode = useSelector((state:RootState) => state.editProcessMode)
  const taskClicked = useSelector((state: RootState) => state.taskClicked)
  const taskClickedCreate = useSelector((state: RootState) => state.taskClickedCreate)
  const process = useSelector((state:RootState) => state.process);
  const user = useSelector((state:RootState)=> state.user)
  const dispatch = useDispatch();

  async function getProcessSteps(userId: number) {
      const data:Process = await ky.post(`${API_BASE_URL}/process/steps`, { json: { userId } , credentials:'include'}).json();
      return data;
  }

  useEffect(() => {
      async function fetchData() {
          try {
              const data = await getProcessSteps(user.id);
              dispatch(setProcess(data));
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      }
      fetchData();
  }, [dispatch, user.id]);

return (
  <div className="workspace">
  <LeftBar />
  <div className="process-space">
    <Actions />
    {!createAction
      ? (
        <>
          {actionClicked
            ?

              <>
                {editActionMode ? <RightMenuActionEdit/> : <RightMenuAction />}

              </>
            :
              createProcessClicked ? <RightMenuProcessCreate/>
              :
              editProcessMode ? <RightMenuProcessEdit/> : <RightMenuProcess />
            }                
            {taskClicked && !editTaskMode && <MiddleMenuTask/>}
            {taskClickedCreate && <MiddleMenuTaskCreate/>}
            {editTaskMode && <MiddleMenuTaskEdit/>}

        </>
      )
      : (
        <RightMenuActionCreate />
      )}
  </div>
</div>
)
}
