//pages N/A
import AuthPage from './pages/nonauthpages/AuthPage'; // sigin signup page

//pages USER
//import HomePageUser from './pages/userpages/HomePageUser'; //home page for user - default functional without delete / edit functional
import ProfilePageUser from './pages/userpages/ProfilePageUser'; //defaut profile page

//pages FOUNDER
import HomePageFounder from './pages/founderpages/HomePageFounder'; //home page for founder
import ProfilePageFounder from './pages/founderpages/ProfilePageFounder'; //profile page with functional for edit teams, delete users etc...
import CompanyPageFounder from './pages/founderpages/CompanyPageFounder';
import TasksPageFounder from './pages/founderpages/TasksPageFounder';
import RoleChoicePage from "./pages/nonauthpages/RoleChoicePage";


interface IRoute {
    path: string;
    element: JSX.Element;
}

export const publicRoutes: IRoute[] = [
    {
        path: '/',
        element: <AuthPage />
    },
    {
        path: '/chooseRole',
        element: <RoleChoicePage />
    },
    {
        path: '/signin',
        element: <AuthPage />
    },
    {
        path: '/signup',
        element: <AuthPage />
    }
];

/*export const userRoutes: IRoute[] = [
    {
        path: '/',
        element: <HomePageUser />
    },
    {
        path: '/profile',
        element: <ProfilePageUser />
    }
];*/

export const founderRoutes: IRoute[] = [
    {
        path: '/',
        element: <HomePageFounder />
    },
    {
        path: '/profile',
        element: <ProfilePageFounder />
    },
    {
        path: '/company',
        element: <CompanyPageFounder />,
    },
    {
        path: '/tasks',
        element: <TasksPageFounder />,
    }
];

export const founderMobileRoutes: IRoute[] = [
    {
        path: '/',
        element: <TasksPageFounder />,
    },
    {
        path: '/profile',
        element: <TasksPageFounder />,
    },
    {
        path: '/company',
        element: <TasksPageFounder />,
    },
    {
        path: '/tasks',
        element: <TasksPageFounder />,
    }
];

