import ky from 'ky';
import React, { useEffect, useState, useRef, MouseEvent } from 'react'
import { RootState } from '../../store/store';
import { setCompanyTasks } from "../../slices/companyTasks";
import { ActionObject, ITasks, Process, Task } from "../../models/types";
import {initialState, setActionObject} from "../../slices/actionObjectSlice";
import {setProcess} from "../../slices/processObjectSlice";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTask } from "../../slices/selectedTaskSlice";
import { setTaskClicked } from "../../slices/taskClickedSlice";
import { setTabTaskName } from "../../slices/tabsInTaskSlice";
import {setTaskDataset} from "../../slices/taskDatasetSlice";
import locales from '../../utils/locales/locales'
import { setActionClicked } from '../../slices/actionClickedSlice';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export default function Dataset() {


  const selectedTask = useSelector((state:RootState) => state.selectedTask)
  const user = useSelector((state:RootState)=> state.user)
  const companyId = useSelector((state: RootState) => state.process.companyId)
  const dispatch = useDispatch();
  const process = useSelector((state:RootState) => state.process);
  const taskDataset = useSelector((state:RootState)=> state.taskDataset)
  const [inputValueTask, setInputValueTask] = useState("");
  const localisation = useSelector((state:RootState)=> state.localisation)
  const action = useSelector((state: RootState) => state.actionObject);

  useEffect(() => {
    ky.post(`${API_BASE_URL}/company/tasks`, { credentials: 'include', json: { companyId: companyId } }).json().then((result: any) => dispatch(setTaskDataset(result.actions)))
  }, [companyId])

  function getTimeDifference(dateString: string) {
    const currentDate = new Date();
    const date = Date.parse(dateString);
    const timeDifference = +currentDate - +date;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days}d`;
    } else if (hours > 1) {
      return `${hours}h`;
    } else if (hours < 1) {
      return `${minutes}m`;
    }
  }


    //start drag and scroll logic
    const comp = useRef<any>(null)

    let startX: number;
    //let scrollTop: number;
    let isDown: boolean;
    //let startY: number;
    let scrollLeft: number;
  
  
    function handleDragBackground(e: MouseEvent) {
      e.preventDefault()
      isDown = true;
      startX = e.pageX - comp.current.offsetLeft;
      //startY = e.pageY - comp.current.offsetTop;
      //scrollTop = comp.current.scrollTop;
      scrollLeft = comp.current.scrollLeft;
    }
  
    function mouseUp(e: MouseEvent) {

      isDown = false;
    }
  
    function mouseMove(e: MouseEvent) {
      if (isDown) {
        e.preventDefault();
        //const y = e.pageY - comp.current.offsetTop;
        const x = e.pageX - comp.current.offsetLeft;
        const walkX = (x - startX);
        //const walkY = (y - startY);
        comp.current.scrollLeft = scrollLeft - walkX;
        //comp.current.scrollTop = scrollTop - walkY;
        //console.log(comp.current.scrollLeft,  scrollLeft - walkX, walkX,  comp.current.offsetLeft)

      }
      return
    }
  
  function isJsonString(str:string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  function includeAttachments(desc: string){
    if (isJsonString(desc) && JSON.parse(desc).blocks && JSON.parse(desc)?.blocks.filter((b:any) => b.type === 'attaches'||b.type === 'image').length > 0)
    {
        return true;
    }
    return false
  }

  async function handleTaskClick(task: Task, actionObj: ActionObject) {
    try {
      dispatch(setActionObject(actionObj))
      dispatch(setTabTaskName({ title: "description" }));
      dispatch(setTaskClicked(true));
      dispatch(setSelectedTask(task));
    } catch (error) {
      console.log(error);
    }
  }

  
  const handleInputChangeTask = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputValueTask(event.target.value);
  };

  const datasetFiltered = taskDataset.map((action) => {
      if(action.name.toLowerCase().includes(inputValueTask.toLowerCase()))
      {
        return action
      }
      else 
      {
        let new_tasklist = action.tasks.filter((task)=> task.name.toLowerCase().includes(inputValueTask.toLowerCase()))
        let new_action = Object.assign({}, action);
        new_action.tasks = new_tasklist
        return new_action
      }
    });
    
  
  return (
      <div className='task-dataset'>
        <div className='header'>
        <h1>{locales[localisation].tasks_dataset}</h1> 
        <input
                className="task-search-bar"
                placeholder="Start typing task name..."
                value={inputValueTask}
                onChange={handleInputChangeTask}
              />
        </div>  
          <div className="task-dataset-list"  
      ref={comp} 
      onMouseDown={(e) => handleDragBackground(e)}
      onMouseUp={(e) => mouseUp(e)} 
      onMouseMove={(e) => mouseMove(e)}
      onMouseLeave={(e) => mouseUp(e)}>
            {datasetFiltered?.map((action)=>(
              <>
              { action?.tasks.length > 0 ?
              <div className='action' >
                <div className='action-title'>
                  <b>{action.name}</b>
                </div>
                <div className="action-column">

              {action.tasks?.map((task) => (
                      <div
                          style={{cursor: 'pointer'}}
                          key={Math.ceil(Math.random() * 999999)}
                          onClick={() => handleTaskClick(task, action)}
                          className={ selectedTask.id === task.id ? `task-card-focused`: `task-card`}>
                      <div className="last-update">
                      <b className="task-id-tag" >#{task.id} </b>


                        <b>{getTimeDifference(task.updatedAt)} {locales[localisation].ago}
                        {/*by lastUserChanged[task.id.toString()]*/}</b>

                        </div>
                        <b className="title">{task.name}</b>

                        <div className="icons">

                          {  isJsonString(task.description) && JSON.parse(task.description)?.blocks && JSON.parse(task.description).blocks.length>0?
                                <div className="icon material-symbols-outlined">
                                  subject</div>:<></>/*if description is not empty */}
                            { includeAttachments(task?.description) ?
                                <div className="icon material-symbols-outlined">
                                  attachment</div>:<></>/*if attachments, links or img not empty in description */}

                            { task?.actions?.length>1?
                                <div className="icon material-symbols-outlined">
                                  fork_right</div>:<></>
                            }

                            { task.priority?
                                <div className="icon material-symbols-outlined yellow-color">
                                warning</div>:<></>}

                        </div>

                      </div>
                  ))} 
                </div>
              </div>
              :
              <></>
              }
              </>
              ))}
          </div>
    </div>
  )
}
