import React, {useState} from 'react';
import ky from "ky";
import {Process} from "../../models/types";
import {setProcess} from "../../slices/processObjectSlice";
import {useSelector, useDispatch} from "react-redux";
import {RootState} from "../../store/store";
import locales from '../../utils/locales/locales'

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

interface CreateTeamFormProps {
  hex_codes: Array<{ id: number; code: string }>;
    setInfo:(value: string) => void;
    setShowCreateTeamInput: (value: number) => void;
}



const CreateTeamForm: React.FC<CreateTeamFormProps> = ({
  hex_codes, setInfo , setShowCreateTeamInput

}) => {
    const companyId = useSelector((state: RootState) => state.process.companyId);
    const processId = useSelector((state: RootState) => state.process.actionsFromProcess.id);
    const localisation = useSelector((state:RootState)=> state.localisation)

    const dispatch = useDispatch();

    const [formCreateTeamInputName, setFormCreateTeamInputName] = useState('')
    const [formCreateTeamInputHex, setFormCreateTeamInputHex] = useState(0)


    async function createTeam() {
        try {
            const hex_c = hex_codes[formCreateTeamInputHex].code
            const result: any = await ky.post(`${API_BASE_URL}/team`,
                { credentials: 'include',
                    json: {
                        hex_code: hex_c,
                        name: formCreateTeamInputName,
                        description: formCreateTeamInputName,
                        tag: formCreateTeamInputName,
                        company_id: companyId} }).json()
            if(result) {
                setFormCreateTeamInputHex(0)
                setFormCreateTeamInputName('')
                setShowCreateTeamInput(0)
                ky.post(`${API_BASE_URL}/company/info`, {
                    credentials: 'include',
                    json: {id: companyId}
                }).json().then((result: any) => setInfo(result))
                const data: Process = await ky.post(`${API_BASE_URL}/process/focus`, {
                    json: {processId},
                    credentials: 'include'
                }).json();
                dispatch(setProcess(data));
            }
        } catch (error) {

        }
    }

  return (
    <form>
      <input
        className="input"
        type="text"
        placeholder="Enter team name"
        onChange={(event) =>
          setFormCreateTeamInputName(event?.target.value)
        }
        value={formCreateTeamInputName}
      />

      <select
        name="hex_code"
        id="5"
        onChange={(event) =>
          setFormCreateTeamInputHex(Number(event?.target.value))
        }
        value={formCreateTeamInputHex}
      >
        {hex_codes.map((hex_c) => (
          <option
            value={hex_c.id}
            style={{ backgroundColor: hex_c.code }}
          >
            {hex_c.code}
          </option>
        ))}
      </select>

      <button type="button" onClick={createTeam}>
      {locales[localisation].create_team}

      </button>
    </form>
  );
};

export default CreateTeamForm;
