import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RESET_STORE } from '../utils/consts';
import { User } from '../models/types';

const initialState: User[] = [];

export const companyUsersSlice = createSlice({
  name: 'companyUsers',
  initialState,
  reducers: {
    setCompanyUsers: (state, action: PayloadAction<User[]>) => {
      return action.payload
    }
  },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});

export const { setCompanyUsers } = companyUsersSlice.actions;

export default companyUsersSlice.reducer;
