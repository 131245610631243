import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action } from "../models/types";
import { RESET_STORE } from '../utils/consts';

const initialState: Action = {
  id: 0,
  name: '',
  description: '',
  row: 999,
  line: 999,
  filterTeams: [],
  tasks: [],
  processes_quantity: 0,
  From: [],
  To: [],
}

const selectedActionSlice = createSlice({
name: 'SelectedAction',
initialState,
reducers: {
  setSelectedAction: (state, action: PayloadAction<Action>) => {
    return action.payload
  }
},
  extraReducers: (builder) => {
    builder.addCase(RESET_STORE, () => initialState);
  },
})

export const {setSelectedAction} = selectedActionSlice.actions
export default selectedActionSlice.reducer
