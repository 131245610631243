import ky from 'ky';
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../store/store";
import {  setProcess } from '../../slices/processObjectSlice';
import { Process} from '../../models/types';
import { setRightMenuVisibility } from '../../slices/rightMenuVisibility';
import { setProcessEditMode } from '../../slices/editProcessModeSlice';
import { setTabName } from '../../slices/tabsInActionSlice';
import { arrowsHover } from '../../slices/arrowsHoverSlice';
import { setCreateProcessClicked } from '../../slices/createProcessClickedSlice';
import { setProcessClicked } from '../../slices/processClickedSlice';
import { setRightMenuShrink } from "../../slices/rightMenuShrink";
import EditorJsComponent from "./EditorJsComponent"
import EditorJS from '@editorjs/editorjs';
import locales from '../../utils/locales/locales'

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export default function RightMenuProcessCreate() {

  const dispatch = useDispatch();
  const process = useSelector((state: RootState) => state.process.actionsFromProcess);
  const visibility = useSelector((state: RootState) => state.rightMenuVisibility)
  const [inputName, setInputName] = useState('')
  const user = useSelector((state: RootState) => state.user);
  const shrink = useSelector((state: RootState) => state.rightMenuShrink);
  const [editorInstance, setEditorInstance] = useState<any>(null);
  const localisation = useSelector((state:RootState)=> state.localisation)

  //const [processInfo, setProcessInfo] = useState('');

  const handleInputTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputName(event.target.value);
  };

  async function publishProcess () {
     try {
      const editorContent = await editorInstance?.save();

       const createActionResponse: any = await ky.post(`${API_BASE_URL}/process`, {
         json: {
          description: JSON.stringify(editorContent),
           name: inputName,
           companyId: user.company_id
           //process_info: JSON.stringify(editorContent),
         }, credentials: 'include',
       }).json();

       const updatedProcess: Process= await ky.post(`${API_BASE_URL}/process/focus`,{json: {processId: createActionResponse.id }, credentials: 'include'}).json();
       dispatch(setProcessClicked(createActionResponse.id))
       dispatch(setProcessEditMode(false));
       dispatch(setProcess(updatedProcess))
       dispatch(setTabName({title: 'description'}))
       dispatch(arrowsHover(true));
       dispatch(setCreateProcessClicked(false))
      setInputName('')
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
    <div className={shrink ? "process-space-right process-space-right-shrink" : "process-space-right"}
                style={
                  !visibility
                      ? { display: "none" }
                      : { display: "flex" }
                }>
      <div className="content ">
      <div className="header">
        <div className="top">
          <div  className="btns">
            <span onClick={() => dispatch(setRightMenuVisibility(!visibility))} className="right-tab-close-btn icon material-symbols-outlined">
              close
            </span>
            <span
              onClick={() => dispatch(setRightMenuShrink(!shrink))}
              className="right-tab-close-btn icon material-symbols-outlined"
            >
              {shrink ? "expand_content" : "close_fullscreen"}
            </span>
          </div>
          <div className="btns">
                  <div className="roundtag-btn-with-icon" onClick={publishProcess}>
                  <div className="icon material-symbols-outlined" >
                    SAVE</div><b>{locales[localisation].Save}</b>
                  </div>
              </div>
        </div>
        <div className="name">
          <h1 className='step-input-title'>
          <input 
            className="h1-input"
            type="text"
            name='title'
            onChange={handleInputTitle}
            placeholder= {locales[localisation].enter_new_process_name}
            value={inputName}
          />
          </h1>
        </div>
      </div>
        <div className="description">
        <div className="post editor-post">
            <EditorJsComponent setEditorInstance={setEditorInstance} selectedAction={process} editMode={true}/>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}
