import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  ITabName } from "../models/types";
import { RESET_STORE } from '../utils/consts';

export const initialState: ITabName = {
    title:'',
};

const tabName = createSlice({
  name: 'tabName',
  initialState,
  reducers: {
    setTabName: (state, action: PayloadAction<ITabName>) => {
      return action.payload
    }
},
  extraReducers: (builder) => {
    builder.addCase(RESET_STORE, () => initialState);
  },
})

export const {setTabName} = tabName.actions
export default tabName.reducer
