import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITasks } from "../models/types";
import { RESET_STORE } from '../utils/consts';

const initialState: ITasks = {actions:[]}

const companyTaskSlice = createSlice({
name: 'SelectedAction',
initialState,
reducers: {
  setCompanyTasks: (state, action: PayloadAction<ITasks>) => {
    return action.payload
  }
},
  extraReducers: (builder) => {
    builder.addCase(RESET_STORE, () => initialState);
  },
})

export const {setCompanyTasks} = companyTaskSlice.actions
export default companyTaskSlice.reducer
