import ky from 'ky'
import React, { useEffect, useState } from 'react'
import LeftBar from '../../components/LeftBarFounder/LeftBar'
import { IFormInfo } from '../../models/types';
import locales from '../../utils/locales/locales'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../store/store";

const API_BASE_URL = process.env.REACT_APP_BASE_URL;


function ProfilePageFounder() {


  const [userInfo, setUserInfo] = useState( { user: { id: 0, name: '', surname: '', email: '', phone_number: '' } })

  const [change, setChange] = useState(false)

  const [formData, setFormData] = useState<IFormInfo>({ name: userInfo.user.name, surname: userInfo.user.surname, email: userInfo.user.email, phone_number: userInfo.user.phone_number })
  const localisation = useSelector((state:RootState)=> state.localisation)

  function hendleChange(event: { target: { name: string; value: string; }; }) {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  }

  async function submitChanges() {
    const result: any = await ky.put(`${API_BASE_URL}/user/info`, {json: formData ,credentials: 'include'}).json()
    if (result.user) {
      setUserInfo(result)
      setFormData({ name: userInfo.user.name, surname: userInfo.user.surname, email: userInfo.user.email, phone_number: userInfo.user.phone_number })
      setChange(false)
    }
  }

  useEffect(() => {
  
    ky.get(`${API_BASE_URL}/user/info`, { credentials: 'include' }).json().then((result: any) => setUserInfo(result))
    setFormData({ name: userInfo.user.name, surname: userInfo.user.surname, email: userInfo.user.email, phone_number: userInfo.user.phone_number })

  }, [change, userInfo.user.email, userInfo.user.name, userInfo.user.surname, userInfo.user.phone_number])

  return (
    <div className='workspace'>
      <LeftBar/> 
      <div className='process-space profile-settings'>
        <div className='process-space-center'>
          <div className='settings-space'>
            <div className='sub-left-bar'>
            <h1>{locales[localisation].user_settings}</h1>

              <div className='menu'>
                
                <div className='item item-active'><b>{locales[localisation].personal_info}</b></div>
                <div className='item'><b>{locales[localisation].companies_and_projects}</b></div>
              </div>
            </div>
            <div className='sub-right-space'>
            <h1>{locales[localisation].personal_info}</h1> 
            
                <div className='profile-section'>
                {!change ? 
                  <>
                  <div className="photo">
                  <img  src="https://icdn.lenta.ru/images/2019/08/21/19/20190821194139294/square_1280_cc5835a5bbaee36c476d2beba6b77686.jpg"
                  alt="photozavr" style={{ width: 150 }}
                  />
                  </div>
                  

                  {userInfo &&
                  <div className='description'>
                    <b>{locales[localisation].first_name}: {userInfo.user.name}</b>
                    <b>{locales[localisation].last_name}: {userInfo.user.surname}</b>
                    <b>{locales[localisation].email}: {userInfo.user.email}</b>
                    <b>{locales[localisation].phone}: {userInfo.user.phone_number}</b>
                  <button type='button' onClick={() => setChange(true)}>{locales[localisation].change_info}</button>
                  </div>
                  }
                  </>
                  : 
                  <form name="loginform" className="description">
                    <input onChange={hendleChange}  type='text' name='name' value={formData.name} placeholder={locales[localisation].input_first_name}/>
                    <input onChange={hendleChange} type='text'name='surname' value={formData.surname} placeholder={locales[localisation].input_second_name}/>
                    <input onChange={hendleChange} type='text' name='email' value={formData.email}  placeholder={locales[localisation].input_email}/>
                    <input onChange={hendleChange} type='text' name='phone_number' value={formData.phone_number} placeholder={locales[localisation].input_phone} />
                    <div className='btns'>
                      <button type='button' onClick={() => setChange(false)}>{locales[localisation].cancel}</button>
                      <button onClick={submitChanges} type="button">{locales[localisation].save}</button>
                    </div>
                  </form>
              }

                </div>
            </div>  
          </div>
        </div>







      </div>
    </div>
  )
}

export default ProfilePageFounder