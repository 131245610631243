const locales : any=
{
    "en":{
        "lang_name":"English",
        "sign out": "sign out",
        "about" : "about",
        "workspace": "workspace",
        "sign_in": "Sign in",
        "log_in":"log in",

        "sign_out": "Sign out",
        "add_process" : "Add process",
        "tasks_dataset" : "Tasks Dataset",
        "text_404" : "something went wrong, return",
        "text_404_back" : "back",
        "save" : "save",
        "Save" : "Save",

        "landing_simplified" : "simplified",
        "landing_business_processes"  : "business processes",
        "no_video_tag_support":"Your browser does not support the video tag.",
        "landing_create":"Create",
        "landing_create_desc":"Business procesess and stages",
        "landing_link":"Link",
        "landing_link_desc":"Track all steps on the way to the result",
        "landing_describe":"Describe",
        "landing_describe_desc":"Keep all neccesary details always at hand",
        "landing_manage_tasks":"Manage tasks",
        "landing_manage_tasks_desc":"From initiating to final edits",
        "landing_forward":"Forward",
        "landing_forward_desc":"Task to multiple steps",
        "landing_set_up":"Set up",
        "landing_set_up_desc":"Company access rights",
        "landing_collaborate":"Collaborate",
        "landing_collaborate_desc":"With your team members",
        "landing_work_together":"Let's Work Together",
        "landing_order":"Order",
        "landing_service":"Service",
        "landing_company_email":"ENTER YOUR COMPANY E-MAIL",
        "landing_contact_us":"CONTACT US",
        "company_settings":"Company Settings",
        "Teams": "Teams",
        "teams": "teams",
        "users_and_token": "Users & Token",
        "teams_table":"Teams Table",
        "delete_team" : "delete team",
        "edit_team" : "edit team",
        "Edit_team" : "Edit team",
        "delete_from_team" : "delete from team",
        "Add_team": "Add team",
        "Generated_Authentication_Token": "Generated Authentication Token",
        "refresh_token" : "Refresh token",
        "company_users" : "Company Users",
        "add_to_team" : "add to team",
        "delete_from_company" :"delete from company",
        "change_role" : "change role",
        "select_team" : "Select team",
        "save_changes" : "save changes",
        "cancel" : "cancel",
        "Cancel" : "Cancel",
        "select_user_role" : "Select user role",
        "yes" : "yes",
        "do_you_want_to_delete_user" : "Do you want to delete user?",
        "create_team" : "crrate team",
        "user_settings": "User Settings",
        "personal_info": "Personal info",
        "companies_and_projects": "Companies/Projects",
        "first_name": "First name",
        "last_name": "Last name",
        "email": "Email",
        "phone": "Phone",
        "change_info": "Change Info",
        "input_first_name": "Input first name",
        "input_second_name": "Input second name",
        "input_email": "Input email",
        "input_phone": "Input phone number",
        "are_you_sure":"Are you sure?",
        "no_account_sign_up":"No account? Sign up!",
        "choose_a_role":"Choose a role",
        "user":"user",
        "founder":"founder",
        "sign_up":"sign up",
        "already_have_account":"Already have account? Sign in!",
        "continue_with_google":"Continue with Google",
        "change_account":"Change account",
        "edit_task" : "edit task",
        "delete_task" : "delete task",
        "forward_task" : "forward task",
        "no_steps_connected" : "no steps connected",
        "assigned":"assigned",
        "assign":"assign",
        "Assign":"Assign",
        "high_priority":"high priority",
        "warning":"warning",
        "set_priority":"Set priority",
        "Description":"Description",
        "History":"History",
        "tasks_in_step": "Task in steps",
        "users_assigned":"Users assigned",
        "edit_step":"edit step",
        "remove_step_from_process":"remove step from process",
        "delete_step":"delete step",
        "Links":"Links",
        "Tasks":"Tasks",
        "Monitoring":"Monitoring",
        "teams_involved_to_step":"Teams involved to step",
        "data_flow_connections":"Data-flow connections with other steps",
        "high":"high",
        "ago":"ago",
        "no_active_tasks":"No active tasks",
        "tasks_per_month":"Tasks per Month",
        "Source":"Source",
        "hours_per_task":"Hours per Task",
        "are_you_sure_delete_task":"Are you sure you want to delete this task and all decriptions?",
        "are_you_sure_delete_step":"Are you sure you want to remove this step from all processes along with the description?",
        "are_you_sure_remove_step_from_process":"Are you sure you want to remove this step from current process? It will NOT be deleted.",
        "start_typing_task":"Start typing task name...",
        "link_existing_step":"Link Existing step",
        "available_teams":"Available Teams",
        "no_external_steps_found":"No external steps found",
        "choose_step_from_another_process":"Choose step from another process, you want to add",
        "add_step":"Add step",
        "teams_involved_to_sub_actions:":"Teams involved to sub-actions:",
        "start_typing_team":"Start typing team name...",
        "enter_new_step_title":"Enter new step title...",
        "edit_process":"edit process",
        "delete_process":"delete process",
        "teams_involved_to_steps":"Teams involved to steps",
        "enter_new_process_name":"Enter new process name...",
        "enter_new_task_title":"Enter new task title...",
        "remove_task_from_step": "remove task from step",
        "tasks_per_week": "Tasks per Week",
        "upgrade_your_plan": "Upgrade your plan",
        "policy_title": "CONDQT Application Policy",
        "policy_intro_title": "Introduction",
        "policy_intro_description": "Welcome to CONDQT! This application policy outlines the terms and conditions associated with using our platform. We request all users to read and understand this policy before accessing our services.",
        "policy_user_data_title": "User Data and Privacy",
        "policy_user_data_description": "At CONDQT, we respect your privacy. Any data collected during your use of the application is kept secure and confidential. We do not sell or share your data with third-party entities without your explicit consent.",
        "policy_app_use_title": "Application Use",
        "policy_app_use_description": "CONDQT is crafted to breathe life into your business processes through intuitive descriptions, seamless automation, and meticulous monitoring. We've built the platform, but it's your imagination that defines its boundaries. Dive in and explore all the ways you can revolutionize your workflow!",
        "policy_ip_title": "Intellectual Property",
        "policy_ip_description": "All rights reserved. The design, UX, software of CONDQT is protected under international copyright laws.",
        "policy_changes_title": "Changes to the Policy",
        "policy_changes_description": "We may periodically update this application policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. Users will be informed of any significant changes.",
        "policy_contact_title": "Contact Us",
        "policy_contact_description": "For more information or inquiries about our policies, please reach out to info@condqt.com.",
        "not_verified_message": "Please verify your account via email."

    },
    "ru":{
        "lang_name":"Русский",
        "sign out": "выйти",
        "about" : "главная",
        "workspace": "процессы",
        "sign_in": "Войти",
        "log_in":"войти",
        "sign_out": "Выйти",
        "add_process" : "Добавить процесс",
        "tasks_dataset" : "Набор задач",
        "text_404" : "что-то пошло не так, вернуться",
        "text_404_back" : "назад",
        "save" : "сохранить",
        "Save" : "Сохранить",
        "landing_simplified" : "упрощенные",
        "landing_business_processes"  : "бизнес-процессы",
        "no_video_tag_support":"Ваш браузер не поддерживает видео-вставки.",
        "landing_create":"Создание",
        "landing_create_desc":"Бизнес-процессов и этапов",
        "landing_link":"Взаимосвязь",
        "landing_link_desc":"Отслеживайте все этапы на пути к результату",
        "landing_describe":"Описание",
        "landing_describe_desc":"Держите все необходимые детали всегда под рукой",
        "landing_manage_tasks":"Управление задачами",
        "landing_manage_tasks_desc":"От инициирования до конечных правок",
        "landing_forward":"Передача",
        "landing_forward_desc":"Задач на несколько этапов единовременно",
        "landing_set_up":"Настройки",
        "landing_set_up_desc":"Прав доступа пользователей компании",
        "landing_collaborate":"Кооперирование",
        "landing_collaborate_desc":"С вашими членами команды",
        "landing_work_together":"Упростите вашу работу",
        "landing_order":"Закажите услуги",
        "landing_service":"",
        "landing_company_email":"Введите электронную почту вашей компании",
        "landing_contact_us":"Свяжитесь с нами",
        "company_settings":"Настройки компании",
        "Teams": "Команды",
        "teams": "команды",
        "users_and_token": "Пользователи и доступ по ссылке",
        "teams_table":"Таблица команд",
        "delete_team" : "удалить команду",
        "edit_team" : "редактировать команду",
        "Edit_team" : "Редактировать команду",
        "delete_from_team" : "удалить из команды",
        "Add_team": "Добавить команду",
        "Generated_Authentication_Token": "Сгенерированный ключ доступа",
        "refresh_token" : "Обновить ключ",
        "company_users" : "Пользователи компании",
        "add_to_team" : "добавить в команду",
        "delete_from_company" :"удалить из компании",
        "change_role" : "изменить роль",
        "select_team" : "Выбрать команду",
        "save_changes" : "сохранить изменения",
        "cancel" : "отмена",
        "Cancel" : "Отмена",
        "select_user_role" : "Выберите роль пользователя",
        "yes" : "да",
        "do_you_want_to_delete_user" : "Вы действительно хотите удалить пользователя?",
        "create_team" : "создать команду",
        "user_settings": "Настройки пользователя",
        "personal_info": "Личная информация",
        "companies_and_projects": "Компании/Проекты",
        "first_name": "Имя",
        "last_name": "Фамилия",
        "email": "Электронная почта",
        "phone": "Телефон",
        "change_info": "Изменить информацию",
        "input_first_name": "Введите имя",
        "input_second_name": "Введите фамилию",
        "input_email": "Введите адрес электронной почты",
        "input_phone": "Введите номер телефона",
        "are_you_sure":"Вы уверены?",
        "no_account_sign_up":"Нет аккаунта? Зарегистрируйтесь!",
        "choose_a_role":"Выберите роль",
        "user":"пользователь",
        "founder":"владелец",
        "sign_up":"зарегистрироваться",
        "already_have_account":"Уже есть аккаунт? Войдите!",
        "continue_with_google":"Продолжить с Google",
        "change_account":"Сменить аккаунт",
        "edit_task" : "редактировать задачу",
        "delete_task" : "удалить задачу",
        "forward_task" : "перенаправить задачу",
        "no_steps_connected" : "нет связанных шагов",
        "assigned":"моя задача",
        "assign":"взять задачу",
        "Assign":"Взять задачу",
        "high_priority":"приоритет",
        "warning":"предупреждение",
        "set_priority":"приоритет",
        "Description":"Описание",
        "History":"История",
        "tasks_in_step": "Задачa на этапах",
        "users_assigned":"Назначенные пользователи",
        "edit_step":"редактировать этап",
        "remove_step_from_process":"убрать этап из процесса",
        "delete_step":"удалить этап",
        "Links":"Потоки",
        "Tasks":"Задачи",
        "Monitoring":"Мониторинг",
        "teams_involved_to_step":"Команды, участвующие в этапе",
        "data_flow_connections":"Приходящие/исходящие потоки задач",
        "high":"приоритет",
        "ago":"назад",
        "no_active_tasks":"Нет активных задач",
        "tasks_per_month":"Задачи за месяц",
        "Source":"Источники",
        "hours_per_task":"Часы на задачу",
        "are_you_sure_delete_task":"Вы уверены, что хотите удалить эту задачу и все описания?",
        "are_you_sure_delete_step":"Вы уверены, что хотите удалить этот этап из всех процессов вместе с описанием?",
        "are_you_sure_remove_step_from_process":"Вы уверены, что хотите убрать этот этап из текущего процесса? Он НЕ будет удален.",
        "start_typing_task":"Начните вводить название задачи...",
        "link_existing_step":"Существующий этап",
        "available_teams":"Доступные команды",
        "no_external_steps_found":"Не найдены этапы в других процессах",
        "choose_step_from_another_process":"Выберите этап из другого процесса, который вы хотите добавить",
        "add_step":"Добавить этап",
        "teams_involved_to_sub_actions:":"Команды, участвующие в этапах:",
        "start_typing_team":"Начните вводить название команды...",
        "enter_new_step_title":"Введите название нового шага...",
        "edit_process":"редактировать процесс",
        "delete_process":"удалить процесс",
        "teams_involved_to_steps":"Команды, участвующие в этапах процесса",
        "enter_new_process_name":"Введите название нового процесса...",
        "":"",
        "enter_new_task_title": "Введите заголовок новой задачи...",
        "remove_task_from_step": "убрать задачу из этапа",
        "tasks_per_week": "Задачи за неделю",
        "upgrade_your_plan": "Обновите тариф",
        "policy_title": "Политика приложения CONDQT",
        "policy_intro_title": "Введение",
        "policy_intro_description": "Добро пожаловать в CONDQT! Эта политика приложения описывает условия и положения, связанные с использованием нашей платформы. Мы просим всех пользователей ознакомиться и понять эту политику перед доступом к нашим услугам.",
        "policy_user_data_title": "Данные пользователя и конфиденциальность",
        "policy_user_data_description": "В CONDQT мы уважаем вашу личную информацию. Любые данные, собранные во время вашего использования приложения, остаются безопасными и конфиденциальными. Мы не продаем и не передаем ваши данные третьим сторонам без вашего явного согласия.",
        "policy_app_use_title": "Использование приложения",
        "policy_app_use_description": "CONDQT создано, чтобы внести новую жизнь в ваши бизнес-процессы благодаря интуитивным описаниям, бесшовной автоматизации и тщательному мониторингу. Мы создали платформу, но именно ваше воображение определяет ее границы. Погрузитесь и исследуйте все способы революционизации вашего рабочего процесса!",
        "policy_ip_title": "Интеллектуальная собственность",
        "policy_ip_description": "Все права защищены. Дизайн, UX, программное обеспечение CONDQT защищено международными законами об авторских правах.",
        "policy_changes_title": "Изменения в политике",
        "policy_changes_description": "Мы можем периодически обновлять эту политику приложения, чтобы отражать изменения в наших практиках или по другим эксплуатационным, законодательным или регуляторным причинам. Пользователи будут информированы о любых значительных изменениях.",
        "policy_contact_title": "Свяжитесь с нами",
        "policy_contact_description": "Для получения дополнительной информации или запросов о наших политиках, пожалуйста, обратитесь по адресу info@condqt.com."


    },
    "ja": {
        "lang_name":"日本語",
        "sign out": "ログアウト",
        "about" : "について",
        "workspace": "ワークスペース",
        "sign_in": "サインイン",
        "log_in":"ログイン",
        "sign_out": "ログアウト",
        "add_process" : "プロセスを追加",
        "tasks_dataset" : "タスクデータセット",
        "text_404" : "何か問題が発生しました。戻る",
        "text_404_back" : "戻る",
        "save" : "保存",
        "landing_simplified" : "シンプル",
        "landing_business_processes"  : "ビジネスプロセス",
        "no_video_tag_support":"お使いのブラウザはビデオタグをサポートしていません。",
        "landing_create":"作成する",
        "landing_create_desc":"ビジネスプロセスとステージ",
        "landing_link":"リンク",
        "landing_link_desc":"結果への道のりのすべてのステップを追跡",
        "landing_describe":"説明する",
        "landing_describe_desc":"常に必要な詳細を手元に保つ",
        "landing_manage_tasks":"タスクの管理",
        "landing_manage_tasks_desc":"開始から最終編集まで",
        "landing_forward":"転送する",
        "landing_forward_desc":"複数のステップにタスクを転送する",
        "landing_set_up":"セットアップ",
        "landing_set_up_desc":"企業のアクセス権限",
        "landing_collaborate":"共同作業する",
        "landing_collaborate_desc":"チームメンバーと協力する",
        "landing_work_together":"一緒に働きましょう",
        "landing_order":"オーダー",
        "landing_service":"サービス",
        "landing_company_email":"会社のEメールを入力してください",
        "landing_contact_us":"お問い合わせ",
        "company_settings":"会社の設定",
        "Teams": "チーム",
        "teams": "チーム",
        "users_and_token": "ユーザーとトークン",
        "teams_table":"チームテーブル",
        "delete_team" : "チームを削除",
        "edit_team" : "チームを編集",
        "Edit_team" : "チームを編集",
        "delete_from_team" : "チームから削除",
        "Add_team": "チームを追加",
        "Generated_Authentication_Token": "生成された認証トークン",
        "refresh_token" : "トークンを更新",
        "company_users" : "会社のユーザー",
        "add_to_team" : "チームに追加",
        "delete_from_company" :"会社から削除",
        "change_role" : "ロールを変更",
        "select_team" : "チームを選択",
        "save_changes" : "変更を保存",
        "cancel" : "キャンセル",
        "select_user_role" : "ユーザーロールを選択",
        "yes" : "はい",
        "do_you_want_to_delete_user" : "ユーザーを削除しますか？",
        "create_team" : "チームを作成",
        "user_settings": "ユーザー設定",
        "personal_info": "個人情報",
        "companies_and_projects": "会社/プロジェクト",
        "first_name": "名",
        "last_name": "姓",
        "email": "メール",
        "phone": "電話",
        "change_info": "情報を変更",
        "input_first_name": "名を入力",
        "input_second_name": "姓を入力",
        "input_email": "メールを入力",
        "input_phone": "電話番号を入力",
        "are_you_sure":"よろしいですか？",
        "no_account_sign_up":"アカウントがありませんか？ サインアップ！",
        "choose_a_role":"役割を選択",
        "user":"ユーザー",
        "founder":"創設者",
        "sign_up":"サインアップ",
        "already_have_account":"既にアカウントをお持ちですか？ サインイン！",
        "continue_with_google":"Googleで続行",
        "change_account":"アカウントを変更",
        "edit_task" : "タスクの編集",
        "delete_task" : "タスクの削除",
        "forward_task" : "タスクを転送",
        "no_steps_connected" : "接続されたステップはありません",
        "assigned":"割り当て済み",
        "assign":"割り当てる",
        "Assign":"割り当てる",
        "high_priority":"高優先度",
        "warning":"警告",
        "set_priority":"優先度の設定",
        "Description":"説明",
        "History":"履歴",
        "tasks_in_step": "ステップ内のタスク",
        "users_assigned":"割り当てられたユーザー",
        "edit_step":"ステップの編集",
        "remove_step_from_process":"プロセスからステップを削除",
        "delete_step":"ステップの削除",
        "Links":"リンク",
        "Tasks":"タスク",
        "Monitoring":"監視",
        "teams_involved_to_step":"ステップに関与するチーム",
        "data_flow_connections":"他のステップとのデータフロー接続",
        "high":"高",
        "ago":"前",
        "no_active_tasks":"アクティブなタスクはありません",
        "tasks_per_month":"月間タスク数",
        "Source":"ソース",
        "are_you_sure_delete_task":"このタスクと全ての説明を削除してもよろしいですか？",
        "are_you_sure_delete_step":"このステップを説明とともにすべてのプロセスから削除してもよろしいですか?",
        "are_you_sure_remove_step_from_process":"現在のプロセスからこのステップを削除してもよろしいですか？削除されません。",
        "start_typing_task":"タスク名を入力してください...",
        "link_existing_step":"既存のステップとリンク",
        "available_teams":"利用可能なチーム",
        "no_external_steps_found":"外部のステップが見つかりません",
        "choose_step_from_another_process":"追加したい他のプロセスからステップを選択してください",
        "add_step":"ステップを追加",
        "teams_involved_to_sub_actions:":"サブアクションに関与するチーム:",
        "start_typing_team":"チーム名を入力してください...",
        "enter_new_step_title":"新しいステップのタイトルを入力してください...",
        "edit_process":"プロセスの編集",
        "delete_process":"プロセスの削除",
        "teams_involved_to_steps":"ステップに関与するチーム",
        "enter_new_process_name":"新しいプロセス名を入力してください...",
        "":"",
        "enter_new_task_title": "新しいタスクのタイトルを入力してください...",
        "remove_task_from_step": "ステージからタスクを削除する",
        "tasks_per_week": "週ごとのタスク数",
        "upgrade_your_plan": "プランをアップグレードする",
        "policy_title": "CONDQTアプリケーションポリシー",
        "policy_intro_title": "導入",
        "policy_intro_description": "CONDQTへようこそ！このアプリケーションポリシーは、私たちのプラットフォームを使用する際の条件と規約を概説しています。私たちのサービスにアクセスする前に、すべてのユーザーにこのポリシーを読んで理解するようお願いします。",
        "policy_user_data_title": "ユーザーデータとプライバシー",
        "policy_user_data_description": "CONDQTでは、あなたのプライバシーを尊重しています。アプリケーションの使用中に収集されるデータは、安全かつ秘密に保管されています。明示的な同意なしに第三者とデータを販売または共有することはありません。",
        "policy_app_use_title": "アプリケーションの使用",
        "policy_app_use_description": "CONDQTは、直感的な説明、シームレスな自動化、厳密なモニタリングを通じて、ビジネスプロセスに命を吹き込むために設計されています。プラットフォームを構築しましたが、その境界を定義するのはあなたの想像力です。飛び込んで、ワークフローを革命化する方法をすべて探索してください！",
        "policy_ip_title": "知的財産",
        "policy_ip_description": "すべての権利を保有。CONDQTのデザイン、UX、ソフトウェアは、国際的な著作権法により保護されています。",
        "policy_changes_title": "ポリシーへの変更",
        "policy_changes_description": "私たちは、慣行の変更やその他の運用、法的、または規制上の理由のために、このアプリケーションポリシーを定期的に更新する場合があります。重要な変更があった場合、ユーザーに通知されます。",
        "policy_contact_title": "お問い合わせ",
        "policy_contact_description": "私たちのポリシーに関する詳細やお問い合わせについては、info@condqt.comまでお問い合わせください。"

    }
}

export default locales

/*
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../store/store";

import locales from '../../utils/locales/locales'
const localisation = useSelector((state:RootState)=> state.localisation)
{locales[localisation].}
*/
