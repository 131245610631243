import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RESET_STORE } from '../utils/consts';

const initialState: boolean = false

const editActionModeSlice = createSlice({
    name: 'editActionClicked',
    initialState,
    reducers: {
        setActionEditMode: (state, action: PayloadAction<boolean>) => {
            return action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
})

export const {setActionEditMode} = editActionModeSlice.actions
export default editActionModeSlice.reducer
