import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './pages/Policypage.css'
import App from './components/AppRouter';
import store from "./store/store"
import { Provider } from "react-redux"
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserTracing } from "@sentry/tracing";
import { createRoot }  from "react-dom/client";

Sentry.init({
    dsn: "https://e4a699c803ea4de881e6a8f200325ee5@o4504881786454016.ingest.sentry.io/4504881788289024",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});



const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);



