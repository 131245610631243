import React from 'react';
import locales from '../utils/locales/locales';
import { useSelector } from 'react-redux';
import { RootState } from "../store/store";
import {inspect} from "util";


function PolicyPage() {
    const localisation = useSelector((state: RootState) => state.localisation);

    return (
        <div className="policy-space">
            <div className="policy-block" style={{ paddingTop: 50, paddingBottom: 50, zIndex: '1' }}>
                <div className="policy-title">
                    <div className="title-font">{locales[localisation].policy_title}</div>
                </div>

                <div className="policy-content">
                    <h2>1. {locales[localisation].policy_intro_title}</h2>
                    <p>{locales[localisation].policy_intro_description}</p>

                    <h2>2. {locales[localisation].policy_user_data_title}</h2>
                    <p>{locales[localisation].policy_user_data_description}</p>

                    <h2>3. {locales[localisation].policy_app_use_title}</h2>
                    <p>{locales[localisation].policy_app_use_description}</p>

                    <h2>4. {locales[localisation].policy_ip_title}</h2>
                    <p>{locales[localisation].policy_ip_description}</p>

                    <h2>5. {locales[localisation].policy_changes_title}</h2>
                    <p>{locales[localisation].policy_changes_description}</p>

                    <h2>6. {locales[localisation].policy_contact_title}</h2>
                    <p>{locales[localisation].policy_contact_description}</p>
                </div>
            </div>

            <div className="hero-section " >
                <a  className="social-network-link" href="https://www.linkedin.com/company/condqt/">
                    <img className="social-network-icon"  src="/linked_in.svg" alt=''/>
                </a>
                <a  className="social-network-link" href="https://www.t.me/condqt">
                    <img className="social-network-icon"  src="/telegram_icon.svg" alt=''/>
                </a>
            </div>
        </div>
    )
}

export default PolicyPage;
