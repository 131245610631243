import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RESET_STORE } from '../utils/consts';

const initialState: boolean = false

const editProcessModeSlice = createSlice({
    name: 'editProcessClicked',
    initialState,
    reducers: {
        setProcessEditMode: (state, action: PayloadAction<boolean>) => {
            return action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
})

export const {setProcessEditMode} = editProcessModeSlice.actions
export default editProcessModeSlice.reducer
