import React from 'react'

import { ArrowData } from '../../models/types';

const card_width = 210;
const card_height = 88;
const gap_width = 80;
const gap_height = 80;
const padding_height_coef = 1;
const line_gap = 2;
const arrow_curve = 20;
const green_arrow_shift = 15;
const row_max = 9;
const line_max = 9;
const arrow_circuit_shift = 10;


function arrowsBuilder(newobj: ArrowData[], visibility: boolean) {


  const data = newobj.map((el) => el = JSON.parse(JSON.stringify(el)))

  function xsign_eq(arrow: ArrowData) {
    return arrow.from_row <= arrow.to_row ? 1 : -1;
  }

  function xsign(arrow: ArrowData) {
    return arrow.from_row < arrow.to_row ? 1 : -1;
  }

  function left_a1_arrow_border(arrow: ArrowData) {
    if (arrow.from_line >= arrow.to_line) {
      return -1;
    }
    if (arrow.from_line + 1 < arrow.to_line) {
      if (arrow.from_row <= arrow.to_row) {
        return arrow.from_row;
      }
      return arrow.from_row - 1;
    }
    if (arrow.from_row <= arrow.to_row) {
      return arrow.from_row;
    }
    return arrow.to_row
  }


  function right_a1_arrow_border(arrow: ArrowData) {

    if (arrow.from_line >= arrow.to_line) {
      return arrow.from_row;
    }
    if (arrow.from_line + 1 < arrow.to_line) {
      if (arrow.from_row <= arrow.to_row) {
        return arrow.from_row + 1;
      }
      return arrow.from_row;
    }
    if (arrow.from_row <= arrow.to_row) {
      return arrow.to_row;
    }
    return arrow.from_row
  }

  function left_a3_arrow_border(arrow: ArrowData) {
    if (arrow.from_line >= arrow.to_line) {
      return -1;
    }
    if (arrow.from_line + 1 < arrow.to_line) {
      if (arrow.from_row <= arrow.to_row) {
        return arrow.from_row;
      }
      return arrow.to_row;
    }
    if (arrow.from_row <= arrow.to_row) {
      return arrow.from_row;
    }
    return arrow.to_row
  }


  function right_a3_arrow_border(arrow: ArrowData) {
    if (arrow.from_line >= arrow.to_line) {
      return arrow.to_row;
    }
    if (arrow.from_line + 1 < arrow.to_line) {
      if (arrow.from_row === arrow.to_row) {
        return arrow.from_row + 1;
      }
      if (arrow.from_row > arrow.to_row) {
        return arrow.from_row;
      }
      return arrow.to_row;
    }
    if (arrow.from_row <= arrow.to_row) {
      return arrow.to_row;
    }
    return arrow.from_row
  }

  function shift_from_num(num: number): number {

    if (num % 2 === 1) {
      return arrow_circuit_shift * (-1) * (num + 1) / 2
    }
    return arrow_circuit_shift * num / 2
  }

  let a1 = 0;
  let a2 = 0;
  let a3 = 0;


  for (let i = 0; i < data.length; i++) {
    a1 = 0;
    a2 = 0;
    a3 = 0;

    if (data[i].from_row !== data[i].to_row || data[i].from_line + 1 !== data[i].to_line) {
      for (let j = 0; j < i; j++) {

        if (data[j].from_row !== data[j].to_row || data[j].from_line + 1 !== data[j].to_line) {
          if (data[i].from_line + 1 === data[j].to_line || data[i].from_line === data[j].from_line) {
            a1++;
          }


          if (data[i].from_line >= data[i].to_line && data[j].from_line >= data[j].to_line) {
            a2++;
          }

          if (data[i].from_line + 1 < data[i].to_line && data[j].from_line + 1 < data[j].to_line) {
            if (data[i].from_row === data[j].from_row &&
              ((data[i].from_row <= data[i].to_row && data[j].from_row <= data[j].to_row) || (data[i].from_row > data[i].to_row && data[j].from_row > data[j].to_row))) {
              a2++
            }
            if (data[i].from_row + 1 === data[j].from_row &&
              (data[i].from_row <= data[i].to_row && data[j].from_row > data[j].to_row)) {
              a2++
            }
            if (data[i].from_row === data[j].from_row + 1 &&
              (data[i].from_row > data[i].to_row && data[j].from_row <= data[j].to_row)) {
              a2++
            }
          }

          if (data[i].to_line === data[j].to_line || data[i].to_line === data[j].from_line + 1) {
            a3++;
          }
        }
      }
    }

    data[i].a1 = a1;
    data[i].a2 = a2;
    if (data[i].from_line + 1 === data[i].to_line) {
      data[i].a3 = a1;
    } else {
      data[i].a3 = a3;
    }

  }

  for (let i = 0; i < data.length; i++) {
    let recent_a1: any = data[i].a1
    let a = 0
    while (a < recent_a1) {
      let check = 0
      for (let j = 0; j < data.length; j++) {
        if (data[i].from_line === data[j].from_line && a === data[j].a1 && !(right_a1_arrow_border(data[i]) <= left_a1_arrow_border(data[j]) || right_a1_arrow_border(data[j]) <= left_a1_arrow_border(data[i]))) {
          check++
          j = data.length
        }
        else if (data[i].from_line === data[j].to_line - 1 && a === data[j].a3 && !(right_a1_arrow_border(data[i]) <= left_a3_arrow_border(data[j]) || right_a3_arrow_border(data[j]) <= left_a1_arrow_border(data[i]))) {
          check++
          j = data.length
        }
      }
      if (check === 0) {
        if (data[i].from_line + 1 === data[i].to_line) {
          data[i].a3 = a;
        }

        data[i].a1 = a
        a = recent_a1
      }
      else {
        a++
      }
    }
  }

  for (let i = 0; i < data.length; i++) {
    let recent_a3: any = data[i].a3
    let a = 0
    while (a < recent_a3) {
      let check = 0

      for (let j = 0; j < data.length; j++) {

        if (data[i].to_line === data[j].to_line && a === data[j].a3 && !(right_a3_arrow_border(data[i]) <= left_a3_arrow_border(data[j]) || right_a3_arrow_border(data[j]) <= left_a3_arrow_border(data[i]))) {

          check++
          j = data.length
        }
        else if (data[i].to_line === data[j].from_line + 1 && a === data[j].a1 && !(right_a1_arrow_border(data[j]) <= left_a3_arrow_border(data[i]) || right_a3_arrow_border(data[i]) <= left_a1_arrow_border(data[j]))) {
          check++
          j = data.length
        }
      }
      if (check === 0) {
        data[i].a3 = a
        a = recent_a3
      }
      else {
        a++
      }
    }
  }

  return (
    <div className="arrowslayer" style={{opacity:(visibility ? 1 : 0)}}>
      <svg width={row_max * card_width + (row_max + 1) * gap_width} height={line_max * card_height + (line_max + 1) * gap_height} >

        {data.map((arrow) => (
          <>
            <path
             
              stroke={(() => {
                if (arrow.from_line >= arrow.to_line) {
                  return '#9747FF';
                }
                if (arrow.from_line + 1 < arrow.to_line) {
                  return '#50AA94';
                }
                return '#FCCA02';
              })()}
              strokeDasharray={arrow.is_case === true ? '5,5' : 'none'}
              data-arrow-from-row={arrow.from_row}
              data-arrow-from-line={arrow.from_line}
              data-arrow-to-row={arrow.to_row}
              data-arrow-to-line={arrow.to_line}
              key={Math.ceil(Math.random() * 999999)}
              strokeWidth="2px"
              fill="none"
              className="arrow"
              data-a1={arrow.a1}
              data-a2={arrow.a2}
              data-a3={arrow.a3}
              d={(() => {
                if (arrow.from_line >= arrow.to_line) {
                  return `M ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2 + green_arrow_shift},${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + line_gap
                    } L ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2 + green_arrow_shift},${((arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + gap_height / 2) - arrow_curve + shift_from_num(arrow.a1)
                    } Q ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2 + green_arrow_shift},${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + gap_height / 2 + shift_from_num(arrow.a1)
                    } ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2 + green_arrow_shift - arrow_curve},${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + gap_height / 2 + shift_from_num(arrow.a1)
                    } L ${gap_width / 2 + arrow_curve + shift_from_num(arrow.a2)} ,${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + gap_height / 2 + shift_from_num(arrow.a1)
                    } Q ${gap_width / 2 + shift_from_num(arrow.a2)} ,${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + gap_height / 2 + shift_from_num(arrow.a1)
                    } ${gap_width / 2 + shift_from_num(arrow.a2)} ,${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + gap_height / 2 - arrow_curve + shift_from_num(arrow.a1)
                    } L ${gap_width / 2 + shift_from_num(arrow.a2)},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - gap_height / 2 + arrow_curve + shift_from_num(arrow.a3)
                    } Q ${gap_width / 2 + shift_from_num(arrow.a2)},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - gap_height / 2 + shift_from_num(arrow.a3)
                    } ${gap_width / 2 + arrow_curve + shift_from_num(arrow.a2)} ,${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - gap_height / 2 + shift_from_num(arrow.a3)

                    } L ${(arrow.to_row + 1) * gap_width + (arrow.to_row) * card_width + card_width / 2 - arrow_curve + green_arrow_shift},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - gap_height / 2 + shift_from_num(arrow.a3)
                    } Q ${(arrow.to_row + 1) * gap_width + (arrow.to_row) * card_width + card_width / 2 + green_arrow_shift},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - gap_height / 2 + shift_from_num(arrow.a3)
                    } ${(arrow.to_row + 1) * gap_width + (arrow.to_row) * card_width + card_width / 2 + green_arrow_shift},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - gap_height / 2 + arrow_curve + shift_from_num(arrow.a3)
                    } L ${(arrow.to_row + 1) * gap_width + (arrow.to_row) * card_width + card_width / 2 + green_arrow_shift},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - line_gap}`;
                }
                if (arrow.from_line + 1 < arrow.to_line) {
                  return `M ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2 + green_arrow_shift},${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + line_gap
                    } L ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2 + green_arrow_shift},${((arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + gap_height / 2) - arrow_curve + shift_from_num(arrow.a1)
                    } Q ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2 + green_arrow_shift},${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + gap_height / 2 + shift_from_num(arrow.a1)
                    } ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2 + green_arrow_shift + arrow_curve * xsign_eq(arrow)},${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + gap_height / 2 + shift_from_num(arrow.a1)
                    } L ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2 + card_width / 2 * xsign_eq(arrow) + gap_width / 2 * xsign_eq(arrow) - arrow_curve * xsign_eq(arrow) + shift_from_num(arrow.a2)},${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + gap_height / 2 + shift_from_num(arrow.a1)
                    } Q ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2 + card_width / 2 * xsign_eq(arrow) + gap_width / 2 * xsign_eq(arrow) + shift_from_num(arrow.a2)},${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + gap_height / 2 + shift_from_num(arrow.a1)
                    } ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2 + card_width / 2 * xsign_eq(arrow) + gap_width / 2 * xsign_eq(arrow) + shift_from_num(arrow.a2)},${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + gap_height / 2 + arrow_curve + shift_from_num(arrow.a1)
                    } L ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2 + card_width / 2 * xsign_eq(arrow) + gap_width / 2 * xsign_eq(arrow) + shift_from_num(arrow.a2)},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - gap_height / 2 - arrow_curve + shift_from_num(arrow.a3)
                    } Q ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2 + card_width / 2 * xsign_eq(arrow) + gap_width / 2 * xsign_eq(arrow) + shift_from_num(arrow.a2)},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - gap_height / 2 + shift_from_num(arrow.a3)
                    } ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2 + card_width / 2 * xsign_eq(arrow) + gap_width / 2 * xsign_eq(arrow) + arrow_curve * xsign(arrow) + shift_from_num(arrow.a2)},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - gap_height / 2 + shift_from_num(arrow.a3)

                    } L ${(arrow.to_row + 1) * gap_width + (arrow.to_row) * card_width + card_width / 2 - arrow_curve * xsign(arrow) + green_arrow_shift},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - gap_height / 2 + shift_from_num(arrow.a3)
                    } Q ${(arrow.to_row + 1) * gap_width + (arrow.to_row) * card_width + card_width / 2 + green_arrow_shift},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - gap_height / 2 + shift_from_num(arrow.a3)
                    } ${(arrow.to_row + 1) * gap_width + (arrow.to_row) * card_width + card_width / 2 + green_arrow_shift},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - gap_height / 2 + arrow_curve + shift_from_num(arrow.a3)
                    } L ${(arrow.to_row + 1) * gap_width + (arrow.to_row) * card_width + card_width / 2 + green_arrow_shift},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - line_gap}`;
                }
                if (arrow.from_row === arrow.to_row) {
                  return `M ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2},${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + line_gap
                    } ${(arrow.to_row + 1) * gap_width + (arrow.to_row) * card_width + card_width / 2},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - line_gap}`;
                }

                return `M ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2},${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + line_gap
                  }L ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2},${((arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + gap_height / 2) - arrow_curve + shift_from_num(arrow.a1)
                  } Q ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2},${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + gap_height / 2 + shift_from_num(arrow.a1)
                  } ${(arrow.from_row + 1) * gap_width + (arrow.from_row) * card_width + card_width / 2 + arrow_curve * xsign(arrow)},${(arrow.from_line + padding_height_coef) * gap_height + (arrow.from_line + 1) * card_height + gap_height / 2 + shift_from_num(arrow.a1)
                  } L ${(arrow.to_row + 1) * gap_width + (arrow.to_row) * card_width + card_width / 2 - arrow_curve * xsign(arrow)},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - gap_height / 2 + shift_from_num(arrow.a1)
                  } Q ${(arrow.to_row + 1) * gap_width + (arrow.to_row) * card_width + card_width / 2},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - gap_height / 2 + shift_from_num(arrow.a1)
                  } ${(arrow.to_row + 1) * gap_width + (arrow.to_row) * card_width + card_width / 2},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - gap_height / 2 + arrow_curve + shift_from_num(arrow.a1)
                  } L ${(arrow.to_row + 1) * gap_width + (arrow.to_row) * card_width + card_width / 2},${(arrow.to_line + padding_height_coef) * gap_height + (arrow.to_line) * card_height - line_gap
                  }`;
              })()}
            />

            <path
              className="animated-path"
              animation-key={arrow.id}
              key={Math.ceil(Math.random() * 999999)}
              stroke={(() => {
                if (arrow.from_line >= arrow.to_line) {
                  return '#9747FF';
                }
                if (arrow.from_line + 1 < arrow.to_line) {
                  return '#50AA94';
                }
                return '#FCCA02';
              })()}
              data-animation-arrow-from-row={arrow.from_row}
              data-animation-arrow-from-line={arrow.from_line}
              data-animation-arrow-to-row={arrow.to_row}
              data-animation-arrow-to-line={arrow.to_line}
              fill="none"
              d="" />
          </>
        ))}
      </svg >
    </div >
  );
}

export default arrowsBuilder