import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Log {
    id: number;
    task_id: number;
    from_action_name: string;
    to_action_name: string;
}

interface ChartProps {
    import_logs: Log[];
    export_logs: Log[];
}


function onlyUniqueImports(value:Log, index:number, array: Log[]) {
    return array.findIndex(el => el.from_action_name == value.from_action_name && el.task_id == value.task_id) === index;
  }


function onlyUniqueExports(value:Log, index:number, array: Log[]) {
return array.findIndex(el => el.to_action_name == value.to_action_name && el.task_id == value.task_id) === index;
}

function expandLabels(labels_array: string[], array: Record<string, number>){
    return labels_array.map(e => {
        if(array[e])
        {
            return array[e];
        }
        return 0
    })
}

const DoughnutChart: React.FC<ChartProps> = ({ import_logs, export_logs }) => {
    const enlargeDoughnutChart = useSelector((state: RootState) => state.enlargeDoughnutChart);

    const actionMapImports = import_logs.filter(onlyUniqueImports).reduce((acc: Record<string, number>, log: Log) => {
        if(log.from_action_name == null){
            acc["#created"] = (acc[log.from_action_name] || 0) + 1;
            return acc;
        }
        if(log.from_action_name == log.to_action_name){
            acc["#self-forward"] = (acc[log.from_action_name] || 0) + 1;
            return acc;
        }
        acc[log.from_action_name] = (acc[log.from_action_name] || 0) + 1;
        //acc[log.to_action_name] = (acc[log.to_action_name] || 0) + 1;
        return acc;
    }, {});

    const actionMapExports = export_logs.filter(onlyUniqueExports).reduce((acc: Record<string, number>, log: Log) => {
        if(log.to_action_name == null){
            acc["#deleted"] = (acc[log.to_action_name] || 0) + 1;
            return acc;
        }
        if(log.from_action_name == log.to_action_name){
            acc["#self-forward"] = (acc[log.from_action_name] || 0) + 1;
            return acc;
        }
        acc[log.to_action_name] = (acc[log.to_action_name] || 0) + 1;
        //acc[log.to_action_name] = (acc[log.to_action_name] || 0) + 1;
        return acc;
    }, {});

    const labels_not_unique = Object.keys(actionMapImports).concat(Object.keys(actionMapExports));
    //console.log("actionMapExports:", actionMapExports)

    const labels = labels_not_unique.filter((item, idx) => labels_not_unique.indexOf(item) === idx)
    // const data = Object.values(actionMapImports);

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: '# of export forwards',
                data: Object.values(expandLabels(labels, actionMapExports)),
                backgroundColor: [
                    'rgba(255, 159, 64, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                ],
                borderColor: ['#18191D'],
                borderWidth: 10,
            },
            {
                label: '# of import forwards',
                data: Object.values(expandLabels(labels, actionMapImports)),
                backgroundColor: [
                    'rgba(255, 159, 64, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                ],
                borderColor: ['#18191D'],
                borderWidth: 10,
            },
            
        ],
    };

    return (
        <Doughnut
            height={100}
            width={100}
            data={chartData}
            options={{
                maintainAspectRatio: false,
                responsive: true,
                plugins:{
                    title: {
                        display: false,
                        text: 'Tasks source',
                        font: {
                            size: 16,
                            family: 'Inter',
                            weight: '300',
                        },
                    },
                    legend:{
                        display: enlargeDoughnutChart,
                        position: 'right',
                        labels:{
                            font: {
                                size: 14,
                                family: 'Inter',
                                weight: '300',
                            },
                            color: '#fff'
                        },

                    }
                }
            }}
        />
    );
};

export default DoughnutChart;
