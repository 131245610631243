import ky from "ky";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import { delAction, setProcess } from "../../slices/processObjectSlice";
import { ActionObject, Process, Task, IMessage, ActionTaskForwardLogs, Input  } from "../../models/types";
import arrowJoinItemGenerator from "../../utils/functions/arrowJoinItemGenerator";
import { setRightMenuVisibility } from "../../slices/rightMenuVisibility";
import { renderSavedInput } from "../../utils/functions/renderSavedInput";
import { setTabName } from "../../slices/tabsInActionSlice";
import { setActionClicked } from "../../slices/actionClickedSlice";
import { setRightMenuShrink } from "../../slices/rightMenuShrink";
import { setActionEditMode } from "../../slices/editActionModeSlice";
import { setSelectedTask } from "../../slices/selectedTaskSlice";
import { setTaskClicked } from "../../slices/taskClickedSlice";
import { setTabTaskName } from "../../slices/tabsInTaskSlice";
import { setEnlargeDoughnutChart } from "../../slices/enlargeDoughnutChart";
import { setTaskClickedCreate } from "../../slices/taskClickedCreateSlice";
import {setActionObject} from "../../slices/actionObjectSlice";
import EditorJsComponent from "./EditorJsComponent"
import EditorJS from '@editorjs/editorjs';
import BarChart from "../Charts/BarChart";
import DoughnutChart from "../Charts/DoughnutChart";
import LineChart from "../Charts/LineChart";
import AvgSpeedChart from "../Charts/AvgSpeedChart";
import locales from '../../utils/locales/locales'

const API_BASE_URL = process.env.REACT_APP_BASE_URL;


function RightMenuAction() {
  const dispatch = useDispatch();
  const action = useSelector((state: RootState) => state.actionObject);
  const process = useSelector((state: RootState) => state.process);
  const user = useSelector((state:RootState)=> state.user);
  const selectedTask = useSelector((state:RootState) => state.selectedTask)
  //const selectedAction = useSelector((state:RootState) => state.selectedAction);
  
  const [enlargeLineChart, setEnlargeLineChart] = useState(true);
  const [enlargeAvgSpeedChart, setEnlargeAvgSpeedChart] = useState(false);
  //const [enlargeDonutChart, setEnlargeDonutChart] = useState(true);
  const enlargeDoughnutChart = useSelector((state: RootState) => state.enlargeDoughnutChart);

  const visibility = useSelector(
      (state: RootState) => state.rightMenuVisibility
  );

  const shrink = useSelector((state: RootState) => state.rightMenuShrink);
  const localisation = useSelector((state:RootState)=> state.localisation)

  const [delModal, setDelModal] = useState(false);
  const [taskForwardLog, setTaskForwardLog] = useState<ActionTaskForwardLogs>({export_logs: [], import_logs:[]});
  const [removeModal, setRemoveModal] = useState(false);
  const tab_name = useSelector((state: RootState) => state.tabName);
  const [inputValueTask, setInputValueTask] = useState("");
  const [lastUserChanged, setLastUserChanged] = useState<any>({})
  const task = useSelector((state: RootState) => state.selectedTask)
  async function deleteAction() {
    const result: IMessage = await ky
        .delete(`${API_BASE_URL}/actions`, {
          json: { actionId: action.id },
          credentials: "include",
        })
        .json();
    if (result.message === "ok") {
      dispatch(delAction(action.id));
      const data: Process = await ky
          .post(`${API_BASE_URL}/process/focus`, {
            json: { processId: process.actionsFromProcess.id },
            credentials: "include",
          })
          .json();
      dispatch(setProcess(data));
      setDelModal(false);
      dispatch(setActionClicked(false));
    }
  }

  const temporary_data2= [
    {
      id:1,
      year: 2016,
      userGain: 80000,
      userLost: 823
    },
    {
      id:2,
      year: 2017,
      userGain: 23560,
      userLost: 1823
    },
    {
      id:3,
      year: 2018,
      userGain: 56560,
      userLost: 1223
    },
  ]

  const temporary_data= {
    labels: temporary_data2.map((data) => data.year),
    datasets: [{
      label: "Users Gained",
      data: temporary_data2.map((data) => data.userGain),
    }]
  }

  async function removeAction() {
    const result: IMessage = await ky
        .post(`${API_BASE_URL}/process/remove`, {
          json: { processId: process.actionsFromProcess.id, actionId: action.id },
          credentials: "include",
        })
        .json();
    if (result.message === "ok") {
      dispatch(delAction(action.id));
      const data: Process = await ky
          .post(`${API_BASE_URL}/process/focus`, {
            json: { processId: process.actionsFromProcess.id },
            credentials: "include",
          })
          .json();
      dispatch(setProcess(data));
      setRemoveModal(false);
      dispatch(setActionClicked(false));
    }
  }

  async function handleArrowCaseReverse(id: number, old_case: boolean) {
    const result: IMessage = await ky
        .put(`${API_BASE_URL}/actions/link`, {
          json: { id, is_case: !old_case},
          credentials: "include",
        })
        .json();
    if (result.message === "ok") {
      const data: Process = await ky
          .post(`${API_BASE_URL}/process/focus`, {
            json: { processId: process.actionsFromProcess.id },
            credentials: "include",
          })
          .json();
      dispatch(setProcess(data));
      const actionObj: ActionObject = await ky.post(`${API_BASE_URL}/actions/focus`, {
        credentials: 'include',
        json: {actionid: action.id}
      }).json()
      dispatch(setActionObject(actionObj));
    }
  }

  async function getForwardLogs(id:number){
    const logs: ActionTaskForwardLogs = await ky.post(`${API_BASE_URL}/actions/forwardlogs`, {
      credentials: 'include',
      json: {actionId: action.id}
    }).json()
    //console.log(`LOGS`,logs);
    setTaskForwardLog(logs)
  }

  async function handleArrowDelete(id: number) {
    const result: IMessage = await ky
        .delete(`${API_BASE_URL}/actions/link`, {
          json: { arrowId: id },
          credentials: "include",
        })
        .json();
    if (result.message === "ok") {
      const data: Process = await ky
          .post(`${API_BASE_URL}/process/focus`, {
            json: { processId: process.actionsFromProcess.id },
            credentials: "include",
          })
          .json();
      dispatch(setProcess(data));
      const actionObj: ActionObject = await ky.post(`${API_BASE_URL}/actions/focus`, {
        credentials: 'include',
        json: {actionid: action.id}
      }).json()
      dispatch(setActionObject(actionObj));
    }
  }


  function includeAttachments(desc: string){
    if (isJsonString(desc) && JSON.parse(desc).blocks && JSON.parse(desc)?.blocks.filter((b:any) => b.type === 'attaches'||b.type === 'image').length > 0)
    {
        return true;
    }
    return false
  }

  async function handleTaskClick(task: Task) {
    try {
      dispatch(setTabTaskName({ title: "description" }));
      dispatch(setTaskClicked(true));
      dispatch(setSelectedTask(task));
    } catch (error) {
      console.log(error);
    }
  }

  async function handleTask() {
    try {
      dispatch(setTaskClicked(false));
      dispatch(setTaskClicked(true));
      dispatch(setTaskClickedCreate(true));
    } catch (error) {
      console.log(error);
    }
  }

  const handleInputChangeTask = (
      event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputValueTask(event.target.value);
  };

  const tasksNow = action.tasks?.filter((task) =>
      task.name.toLowerCase().includes(inputValueTask.toLowerCase())
  );

  function getTimeDifference(dateString: string) {
    const currentDate = new Date();
    const date = Date.parse(dateString);
    const timeDifference = +currentDate - +date;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days}d`;
    } else if (hours > 1) {
      return `${hours}h`;
    } else if (hours < 1) {
      return `${minutes}m`;
    }
  }

  function isJsonString(str:string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  function closeRightMenu(){
    dispatch(setActionClicked(false));
    dispatch(setRightMenuVisibility(!visibility))
  }

  async function getLastUser(actionId: number) {
    const data:ActionObject = await ky.post(`${API_BASE_URL}/actions/lastUser`, { json: { actionId } , credentials:'include'}).json();
    return data;
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getLastUser(action.id);
        setLastUserChanged(data)
        if (tab_name.title === 'monitoring'){
          getForwardLogs(action.id)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, [task, action.id]);

  return (
      <>
        <div
            className={shrink ? "process-space-right process-space-right-shrink": "process-space-right" }
            style={
              !visibility
                  ? { display: "none" }
                  : { display: "flex" }
            }
        >
          <div className="content">
            <div className="header">
              <div className="top">
                <div className="btns">
                <span
                    onClick={closeRightMenu}
                    className="right-tab-close-btn icon material-symbols-outlined"
                >
                  close
                </span>

                  <span
                      onClick={() => dispatch(setRightMenuShrink(!shrink))}
                      className="right-tab-close-btn icon material-symbols-outlined"
                  >
                  {shrink ? "expand_content" : "close_fullscreen"}
                </span>

                  <span className="dropdown-btn icon material-symbols-outlined">
                  more_horiz
                  <ul>
                    <li onClick={() => dispatch(setActionEditMode(true))}>
                    {locales[localisation].edit_step}
                    </li>
                    <li onClick={() => setRemoveModal(true)}>{locales[localisation].remove_step_from_process}</li>
                    <li onClick={() => setDelModal(true)}>{locales[localisation].delete_step}</li>
                  </ul>
                </span>
                </div>
              </div>
              <div className="name">
                <h1>{action.name}</h1>
              </div>
              <div className="right-panel-tab-btns">
                <div
                    className={
                      tab_name.title === "description"
                          ? "tab-btn-active"
                          : "tab-btn"
                    }
                    onClick={() => dispatch(setTabName({ title: "description" }))}
                >
                <span className="icon material-symbols-outlined">
                  menu_book
                </span>
                  <b>{locales[localisation].Description}</b>
                </div>
                <div
                    className={
                      tab_name.title === "arrows" ? "tab-btn-active" : "tab-btn"
                    }
                    onClick={() => dispatch(setTabName({ title: "arrows" }))}
                >
                <span className="icon material-symbols-outlined">
                  conversion_path
                </span>
                  <b>{locales[localisation].Links}</b>
                </div>
                <div
                    className={
                      tab_name.title === "tasks" ? "tab-btn-active" : "tab-btn"
                    }
                    onClick={() => {  ; dispatch(setTabName({ title: "tasks" }))}}
                >
                <span className="icon material-symbols-outlined">
                  checklist
                </span>
                  <b>{locales[localisation].Tasks}</b>
                  <small className="tab-counter">{action.tasks?.length}</small>
                </div>
                <div
                    className={
                      tab_name.title === "monitoring" ? "tab-btn-active" : "tab-btn"
                    }
                    onClick={() => {
                      dispatch(setTabName({ title: "monitoring" }));
                      getForwardLogs(action.id)
                  }}
                >
                <span className="icon material-symbols-outlined">
                  monitoring
                </span>
                  <b>{locales[localisation].Monitoring}</b>
                </div>
              </div>
            </div>
            <div
                className="description"
                style={
                  tab_name.title === "description"
                      ? { display: "flex" }
                      : { display: "none" }
                }
            >
              <div className="teams" style={action.teams.length ===0? {display: "none"}: {}}>
                <h3>{locales[localisation].teams_involved_to_step}</h3>
                <div className="tags">
                  {action.teams.map((team) => (
                      <div
                          className="teamtag"
                          key={Math.ceil(Math.random() * 999999)}
                          style={{ background: `${team.hex_code}` }}
                      >
                        <b>@{team.tag}</b>
                      </div>
                  ))}
                </div>
              </div>

              <div className="post view-post" onClick={() => dispatch(setActionEditMode(true))}>
                <b className="post-body">
                  <EditorJsComponent   key={Math.ceil(Math.random() * 999999)}  selectedAction={action} editMode={false}/>
              </b>
            </div>
          </div>
          <div
            className="description"
            style={
              tab_name.title === "arrows"
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <div className="arrow-joins-section">
              <h3>
              {locales[localisation].data_flow_connections}
              </h3>
              <div className="arrow-joins">
                {action.To.map((arrow) =>
                  arrowJoinItemGenerator(
                    arrow,
                    false,
                    "#fff",
                    Math.ceil(Math.random() * 99999),
                    user.role,
                    handleArrowDelete,
                    handleArrowCaseReverse
                  )
                )}
                {action.From.map((arrow) =>
                  arrowJoinItemGenerator(
                    arrow,
                    true,
                    "#fff",
                    Math.ceil(Math.random() * 99999),
                    user.role,
                    handleArrowDelete,
                    handleArrowCaseReverse
                  )
                )}
              </div>
            </div>
          </div>

          <div
            className="description"
            style={
              tab_name.title === "tasks"
                ? { display: "flex" }
                : { display: "none" }
            }
          >

            <div className="in-action-task-header">
              <input
                className="task-search-bar"
                placeholder={locales[localisation].start_typing_task}
                value={inputValueTask}
                onChange={handleInputChangeTask}
              />
              <div className="icon-btn icon material-symbols-outlined" onClick={handleTask}>
              assignment_add</div> {/*if attachments, links or img not empty in description */}
              </div>
              <div className="in-action-task-section" style={
                tasksNow.length > 0
                    ? { display: "flex" }
                    : { display: "none" }
              }>

                {tasksNow?.map((task) => (
                    <div
                        style={{cursor: 'pointer'}}
                        key={Math.ceil(Math.random() * 999999)}
                        onClick={() => handleTaskClick(task)}
                        className={ selectedTask.id === task.id ? `task-card-focused`: `task-card`}>
                      <div className="last-update">
                      <b className="task-id-tag" >#{task.id} </b>



                        <b>{getTimeDifference(task.updatedAt)} {locales[localisation].ago}
                        {/*by lastUserChanged[task.id.toString()]*/}</b>

                      </div>


                      <b className="title">{task.name}</b>

                      <div className="icons">

                        {  isJsonString(task.description) && JSON.parse(task.description)?.blocks && JSON.parse(task.description).blocks.length>0?
                              <div className="icon material-symbols-outlined">
                                subject</div>:<></>/*if description is not empty */}
                          { includeAttachments(task?.description) ?
                              <div className="icon material-symbols-outlined">
                                attachment</div>:<></>/*if attachments, links or img not empty in description */}


                          { task?.actions?.length>1?
                              <div className="icon material-symbols-outlined">
                                fork_right</div>:<></>
                          }

                            { task.priority?
                                <div className="icon material-symbols-outlined yellow-color">
                                warning</div>:<></>}


                      </div>

                    </div>
                ))}


              </div>
              <div className="in-action-task-section" style={
                tasksNow.length > 0
                    ? { display: "none" }
                    : { display: "flex" }
              }> <b className="inactive" style={{textAlign: "center"}}>{locales[localisation].no_active_tasks}</b>
              </div>
            </div>

            <div
                className="description"
                style={
                  tab_name.title === "monitoring"
                      ? { display: "flex" }
                      : { display: "none" }
                }
            >

                  <div className="charts">

                    
                  <div className={enlargeLineChart ?  "large-widget" : "widget"} >
                      <b className="chart-name">{locales[localisation].tasks_per_week}</b>
                      <span
                          onClick={() => setEnlargeLineChart(!enlargeLineChart)}
                          className="enlarge-btn icon material-symbols-outlined"
                      >
                      {!enlargeLineChart ? "expand_content" : "close_fullscreen"}
                    </span>

                      <LineChart data={taskForwardLog}/>

                    </div>

                  <div className={enlargeDoughnutChart ?  "large-widget" : "widget"}>
                    <b className="chart-name">
                      {locales[localisation].Source}
                    </b>
                    <span
                          onClick={() => dispatch(setEnlargeDoughnutChart(!enlargeDoughnutChart))}
                          className="enlarge-btn icon material-symbols-outlined"
                      >
                      {!enlargeDoughnutChart ? "expand_content" : "close_fullscreen"}
                    </span>

                    <DoughnutChart import_logs={taskForwardLog.import_logs} export_logs={taskForwardLog.export_logs}/>

                    </div>






                    <div className={enlargeAvgSpeedChart ?  "large-widget" : "widget"}>
                     <b className="chart-name">{locales[localisation].hours_per_task}</b>
                     <span
                          onClick={() => setEnlargeAvgSpeedChart(!enlargeAvgSpeedChart)}
                          className="enlarge-btn icon material-symbols-outlined"
                      >
                      {!enlargeAvgSpeedChart ? "expand_content" : "close_fullscreen"}
                    </span>
                    
                     <AvgSpeedChart data={taskForwardLog}/>

                    </div>

                  </div>

            </div>

          </div>
        </div>
        <div
            className="modal-form"
            style={{ display: delModal ? "block" : "none", zIndex: "13" }}
        >
          <div className="modal-content">
            <h3>{locales[localisation].are_you_sure_delete_step}</h3>
            <button onClick={deleteAction} type="button">
            {locales[localisation].yes}
            </button>
            <button onClick={() => setDelModal(false)} type="button">
            {locales[localisation].cancel}
            </button>
          </div>
        </div>
        <div
            className="modal-form"
            style={{ display: removeModal ? "block" : "none", zIndex: "13" }}
        >
          <div className="modal-content">
            <h3>{locales[localisation].are_you_sure_remove_step_from_process}</h3>
            <button onClick={removeAction} type="button">
            {locales[localisation].yes}
            </button>
            <button onClick={() => setRemoveModal(false)} type="button">
            {locales[localisation].cancel}
            </button>
          </div>
        </div>
      </>
  );
}

export default RightMenuAction;
