/*
// processSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Process } from '../models/types';

const initialState: Process = {
        actionsFromProcess: {
            id: 1,
            name: '',
            process_info: '[]',
            actions: [],
            },
        arrowsData: [],
        teamsData: [],
        selectActionId: [],
        teamsTagWay: {},
        processes: [],
        companyId: 0,
        companyName: '',
        allTeamsInCompany:[],
};

export const processObjectSlice = createSlice({
    name: 'process',
    initialState,
    reducers: {
        setProcess: (state, action: PayloadAction<Process>) => {
            state.actionsFromProcess = action.payload.actionsFromProcess;
            state.arrowsData = action.payload.arrowsData;
            state.teamsData = action.payload.teamsData;
            state.selectActionId = action.payload.selectActionId;
            state.teamsTagWay = action.payload.teamsTagWay;
            state.processes = action.payload.processes;
            state.companyId = action.payload.companyId;
            state.companyName = action.payload.companyName;
            state.allTeamsInCompany = action.payload.allTeamsInCompany;
            // state = {...action.payload}
        },
        delProcess: (state, action:PayloadAction<number>)=>{
            state.processes.filter((process)=> process.id !== action.payload )
        },
        delAction: (state, action:PayloadAction<number>)=> {
            state.actionsFromProcess.actions.filter((act)=> act.id !== action.payload )
        },
    }
});

export const { setProcess, delProcess, delAction,} = processObjectSlice.actions;

export default processObjectSlice.reducer;
*/
// processSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Process } from '../models/types';
import { RESET_STORE } from '../utils/consts';

const initialState: Process = {
    actionsFromProcess: {
        id: 0,
        name: '',
        description: '[]',
        actions: [],
    },
    arrowsData: [],
    teamsData: [],
    selectActionId: [],
    teamsTagWay: {},
    processes: [],
    companyId: 0,
    companyName: '',
    allTeamsInCompany: [],
    externalSteps: []
};

export const processObjectSlice = createSlice({
    name: 'process',
    initialState,
    reducers: {
        setProcess: (state, action: PayloadAction<Process>) => {
            const updatedState = { ...initialState, ...action.payload };
            return updatedState;
        },
        delProcess: (state, action: PayloadAction<number>) => {
            state.processes = state.processes.filter((process) => process.id !== action.payload);
        },
        resetProcess: (state) => {
            return initialState;
        },
        delAction: (state, action: PayloadAction<number>) => {
            state.actionsFromProcess.actions = state.actionsFromProcess.actions.filter(
                (act) => act.id !== action.payload
            );
        },
        delTask: (state, action: PayloadAction<number>) => {
            state.actionsFromProcess.actions = state.actionsFromProcess.actions.filter(
                (act) => act.id !== action.payload
            );
        },
    },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});

export const { setProcess, delProcess, delAction, resetProcess, delTask } = processObjectSlice.actions;

export default processObjectSlice.reducer;
