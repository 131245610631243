import ky from "ky";
import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../store/store";
import { setProcess} from '../../slices/processObjectSlice'
import { Process, User} from "../../models/types";
import { setActionClicked } from "../../slices/actionClickedSlice";
import { setCreateActionClicked } from "../../slices/createActionClickedSlice";
import { arrowsHover } from "../../slices/arrowsHoverSlice";
import { setCreateProcessClicked } from "../../slices/createProcessClickedSlice";
import  { setProcessEditMode } from "../../slices/editProcessModeSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { setProcessClicked } from "../../slices/processClickedSlice";
import rightMenuVisibility, { setRightMenuVisibility } from "../../slices/rightMenuVisibility";
import {setTaskClicked} from '../../slices/taskClickedSlice';
import locales from '../../utils/locales/locales'


const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export default function LeftBar() {
  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const processes = useSelector((state: RootState) => state.process.processes);
  const companyName = useSelector((state:RootState)=> state.process.companyName)
  const user = useSelector((state: RootState) => state.user);
  const [animation, setAnimation] = useState(0)
  const localisation = useSelector((state:RootState)=> state.localisation)
  const processActive = useSelector((state: RootState) => state.processClickedSlice)
  async function focusProcess(processId:number) {
    dispatch(setProcessClicked(processId))
    dispatch(setCreateProcessClicked(false))
    dispatch(setProcessEditMode(false));
    dispatch(arrowsHover(false));
    dispatch(setTaskClicked(false));
    const data:Process = await ky.post(`${API_BASE_URL}/process/focus`, { json: { processId }, credentials:'include' }).json();
    dispatch(setProcess(data));
    dispatch(setActionClicked(false))
    dispatch(setCreateActionClicked(false));
    dispatch(setRightMenuVisibility(true));
  }

  async function addProcessFunc() {
    dispatch(setTaskClicked(false));
    dispatch(setCreateActionClicked(false));
    dispatch(setProcessEditMode(true));
    dispatch(setActionClicked(false));
    dispatch(setCreateProcessClicked(true))
    dispatch(setRightMenuVisibility(true));
  }

  const [hideNavBar, setHideNavBar] = useState(false);


  return (
    <div className="left-bar" style= {hideNavBar ? {width:52} : {}}>
    <div className="left-bar-top">
      <div className="company" >
        <div className="icon material-symbols-outlined" style={{cursor: 'pointer'}}
        onClick={hideNavBar ? ()=> setHideNavBar(false) : ()=> setHideNavBar(true)}>
         {hideNavBar ? 'menu' : 'menu_open'}</div>
        <div className="name inactive" onClick={()=> navigate('/company')} style={{cursor: 'pointer'}}>
          <b className="inactive" >{companyName}</b>
        </div>
      </div>
      <div className="menu">
        {processes?.map((process) => (
          <div onClick={ location.pathname.includes('company') || location.pathname.includes('tasks') ? () => { setTimeout(() => focusProcess(process.id) , 0);  navigate('/');  }:
              () => focusProcess(process.id)} className={processActive === process.id ? "item item-active":"item"} key={process.id}>

{/*
          <div onClick={ location.pathname.includes('company') || location.pathname.includes('tasks') ? () => { navigate('/'); focusProcess(process.id); }: () => focusProcess(process.id)} className={processActive === process.id ? "item item-active":"item"} key={process.id}>
*/}

            <span className="icon material-symbols-outlined">account_tree</span>
            <b>{process.name}</b>
          </div>
        ))}
        {processes.length >= 5 ? 
        <div className="item addtask">
        <span className="icon material-symbols-outlined">lock</span>
        <b>{locales[localisation].upgrade_your_plan}</b>
      </div>
        :
            <div onClick={addProcessFunc} className="item addtask">
              <span className="icon material-symbols-outlined">add</span>
              <b>{locales[localisation].add_process}</b>
            </div>
            }
      </div>
      <div className="menu">
          <div onClick={()=> navigate('/tasks')} className="item">
            <span className="icon material-symbols-outlined">dataset</span>
            <b>{locales[localisation].tasks_dataset}</b>
          </div>
      </div>
      {/*<div className="menu preferences-menu" style={{display:'none'}}>
        {animation
          ? (
            <div onClick={() => setAnimation(0)} className="item inactive-toggler">
              <span className="icon material-symbols-outlined">toggle_off</span>
              <b>Animation</b>
            </div>
          )
          : (
            <div onClick={() => setAnimation(1)} className="item active-toggler">
              <span className="icon material-symbols-outlined">toggle_on</span>
              <b>Animation</b>
            </div>
          )}
          </div>*/}
    </div>
    <div className="left-bar-user" onClick={()=> navigate('/profile')} style={{ cursor: 'pointer' }}>
      <img
        className="icon"
        src="https://icdn.lenta.ru/images/2019/08/21/19/20190821194139294/square_1280_cc5835a5bbaee36c476d2beba6b77686.jpg"
        alt="photozavr"
      />
      <div className="caption">
        <b className="name">{user.name} {user.surname}</b>

        <small className="role">{'Company ' + user.role || 'Hello'}</small>
      </div>
    </div>
  </div>
  );
}


