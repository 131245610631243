import React from 'react';
import locales from '../utils/locales/locales';
import { useSelector } from 'react-redux';
import { RootState } from "../store/store";
import {inspect} from "util";


function NotVerifiedPage() {
    const localisation = useSelector((state: RootState) => state.localisation);

    return (
        <div className="policy-space">
        <div className="policy-block" style={{ paddingTop: 50, paddingBottom: 50, zIndex: '1' }}>
            <h1>{locales[localisation].not_verified_message}</h1>
        </div>
        </div>
)
}

export default NotVerifiedPage;
