import React, { useState } from 'react';
import ky from 'ky';
import locales from '../utils/locales/locales'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../store/store";

// import './About.css'
// import styles from "./About.module.css"
function Aboutpage() {
    const API_BASE_URL = process.env.REACT_APP_BASE_URL;
    const localisation = useSelector((state:RootState)=> state.localisation)


    const [email, setEmail] = useState('');

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        try {
            await ky.post(`${API_BASE_URL}/google/submit-form`, { json: { email } });
            alert('Success! We will contact you soon!');
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Failed to save form data');
        }
    };
  return (
    <div className="landing-space about-overflow">
    <div className="landing-block" style={{ paddingTop: 100,paddingBottom: 50, zIndex: '1'}}>
      <div className='title-one-third'>
      <div className="landing-title-font first-condqt-name" ><div className="extra-black">CONDQT</div> </div> </div>
      <div className='title-two-third'>
      <div className="landing-title-font">{locales[localisation].landing_simplified}</div>
        <div className="landing-title-font">{locales[localisation].landing_business_processes}</div>
      </div>
    </div>

  <div className="landing-block" >
    <div className='function'>
    <video className='function-image' style={{ marginTop:-30,marginLeft:-20,  width:'110%'}}  autoPlay loop muted playsInline>
        <source src="/landing-link.mp4" type="video/mp4"/>
        {locales[localisation].no_video_tag_support}
      </video>
        <div className='landing-sub-title-font'>
          {locales[localisation].landing_create}
        </div>
        <h1>
        {locales[localisation].landing_create_desc}
        </h1>
    </div>
    <div className='function'>

    <img src="/landing-links.jpg" className='function-image' style={{ paddingTop:10, paddingLeft:10, width:'126%'}} alt='' />

        <div className='landing-sub-title-font'>
          {locales[localisation].landing_link}
        </div>
        <h1>
          {locales[localisation].landing_link_desc}
        </h1>
    </div>
    <div className='function'>
    <video className='function-image' style={{ paddingTop:'10px',marginLeft:-10, width:'120%'}}  autoPlay loop muted playsInline>
        <source src="/landing-decription.mp4" type="video/mp4"/>
        {locales[localisation].no_video_tag_support}
      </video>
    <div className='landing-sub-title-font'>
    {locales[localisation].landing_describe}
        </div>
        <h1>
        {locales[localisation].landing_describe_desc}
                </h1>
    </div>

    <div className='function double-function'>
        <video className='function-image' style={{ paddingTop:10, paddingLeft:0, width:'98%'}}  autoPlay loop muted playsInline>
        <source src="/landing-tasks.mp4" type="video/mp4"/>
        {locales[localisation].no_video_tag_support}
        </video>
        <div className='landing-sub-title-font'>
        {locales[localisation].landing_manage_tasks}
        </div>
        <h1>
        {locales[localisation].landing_manage_tasks_desc}
        </h1>
    </div>
    <div className='function'>
    <img src="/landing-forward.jpg" className='function-image'  style={{ marginTop:-30, width:'100%'}} alt='' />

        <div className='landing-sub-title-font'>
        {locales[localisation].landing_forward}
        </div>
        <h1>
        {locales[localisation].landing_forward_desc}
        </h1>
    </div>

    <div className='function'>
        <img src="/landing-collab.jpg" className='function-image' style={{ paddingTop:20, width:'90%'}} alt=''/>
        <div className='landing-sub-title-font'>
          {locales[localisation].landing_set_up}
        </div>
        <h1>
        {locales[localisation].landing_set_up_desc}
        </h1>
    </div>
    <div className='function double-function'>
    <img src="/landing-teams.jpg" className='function-image' style={{ paddingTop:20, width:'95%'}} alt='' />

        <div className='landing-sub-title-font'>
          {locales[localisation].landing_collaborate}
        </div>
        <h1>
        {locales[localisation].landing_collaborate_desc}
        </h1>
    </div>

    

    <div className="hero-section " >
    <div className='landing-sub-title-font'>
    {locales[localisation].landing_work_together}

    </div>
    <div className='landing-sub-title-font'>
    {locales[localisation].landing_order} <div className="extra-black">CONDQT</div> {locales[localisation].landing_service}
    </div>
    </div>
      <form name="mailForm" id="modalForm" className="form" onSubmit={handleSubmit}>
          <input
              name="e-mail"
              type="text"
              placeholder={locales[localisation].landing_company_email}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit" >{locales[localisation].landing_contact_us}</button>
      </form>

    <div className="hero-section " >
    <a  className="social-network-link" href="https://www.linkedin.com/company/condqt/">
      <img className="social-network-icon"  src="/linked_in.svg" alt=''/>
    </a>
    <a  className="social-network-link" href="https://www.t.me/condqt">
      <img className="social-network-icon"  src="/telegram_icon.svg" alt=''/>
    </a>

    </div>
    </div>
  </div>

  )
}

export default Aboutpage
