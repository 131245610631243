import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RESET_STORE } from '../utils/consts';

const initialState: number = 0

const processClickedSlice = createSlice({
  name: 'actionClicked',
  initialState,
  reducers: {
    setProcessClicked: (state, action: PayloadAction<number>) => {
      return action.payload
    }
},
  extraReducers: (builder) => {
    builder.addCase(RESET_STORE, () => initialState);
  },
})

export const {setProcessClicked} = processClickedSlice.actions
export default processClickedSlice.reducer
