import ky from 'ky';
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Input, Process } from '../../models/types';
import { setProcessEditMode } from '../../slices/editProcessModeSlice';
import { setProcessClicked } from '../../slices/processClickedSlice';
import { setProcess, delProcess, resetProcess } from '../../slices/processObjectSlice';
import { setRightMenuVisibility } from '../../slices/rightMenuVisibility';
import { RootState } from "../../store/store";
import { renderSavedInput } from '../../utils/functions/renderSavedInput';
import { setRightMenuShrink } from "../../slices/rightMenuShrink";
import EditorJsComponent from "./EditorJsComponent"
import EditorJS from '@editorjs/editorjs';
import locales from '../../utils/locales/locales'

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

function RightMenuProcess() {
    const [isDeleting, setIsDeleting] = useState(false);
    const dispatch = useDispatch();
    let process = useSelector((state: RootState) => state.process);
    const visibility = useSelector((state: RootState) => state.rightMenuVisibility)
    const shrink = useSelector((state: RootState) => state.rightMenuShrink);
    const editor = new EditorJS();
    const localisation = useSelector((state:RootState)=> state.localisation)

    const [delModal, setDelModal]= useState(false)
    interface IMessage{
        message:string
    }

    async function deleteProcess(): Promise<void> {
        const result: IMessage = await ky
            .delete(`${API_BASE_URL}/process`, {
                credentials: "include",
                json: { processId: process.actionsFromProcess.id },
            })
            .json();
        if (result.message === "ok") {
            dispatch(delProcess(process.actionsFromProcess.id));
            setIsDeleting(true);
        }
    }

    useEffect(() => {
        if (isDeleting) {
            (async () => {
                if (process.processes.length > 0) {
                    const data: Process = await ky
                        .post(`${API_BASE_URL}/process/focus`, {
                            json: { processId: process.processes[0]?.id },
                            credentials: "include",
                        })
                        .json();
                    if (data.actionsFromProcess) {
                        dispatch(setProcessClicked(data.actionsFromProcess.id));
                        dispatch(setProcess(data));
                    }
                } else {
                    dispatch(resetProcess());
                }
                setDelModal(false);
                setIsDeleting(false);
            })();
        }
    }, [process.processes, isDeleting, dispatch]);

    return (
    <>
    <div className={shrink ? "process-space-right process-space-right-shrink" : "process-space-right"}
                  style={
                    !visibility
                        ? { display: "none" }
                        : { display: "flex" }
                  }>
      <div className="content process-content">
      <div className="header">
        <div className="top">
        <div  className="btns">
          <span onClick={() => dispatch(setRightMenuVisibility(!visibility))} className="right-tab-close-btn icon material-symbols-outlined">
            close
          </span>
          <span
              onClick={() => dispatch(setRightMenuShrink(!shrink))}
              className="right-tab-close-btn icon material-symbols-outlined"
            >
              {shrink ?  "expand_content" : "close_fullscreen"}
            </span>
          <span  className="dropdown-btn icon material-symbols-outlined">
            more_horiz
            <ul>
              <li onClick={()=> dispatch(setProcessEditMode(true))}>
                {locales[localisation].edit_process}
              </li>
              <li onClick={()=> setDelModal(true)}>
              {locales[localisation].delete_process}
              </li>
            </ul>
          </span>
        </div>
        </div>
        <div className="name" style={{marginBottom: 30}}>
        <div className="icon material-symbols-outlined">account_tree</div>
          <h1>
          {process.actionsFromProcess.name}
          </h1>
        </div>
      </div>
        <div className="description">
          <div className="teams" style={process.teamsData.length ===0? {display: "none"}: {}}>
            <h3>
            {locales[localisation].teams_involved_to_steps}
            </h3>
            <div className="tags">
              {process.teamsData.map((team) => (
                <div className="teamtag" key={Math.floor(Math.random() * 999999)} style={{ background: process.teamsTagWay[team] }}>
                  <b>
                    @
                    {team}
                  </b>
                </div>
              ))}
            </div>
          </div>
          <div className="post view-post" onClick={() => dispatch(setProcessEditMode(true))}>
              <b className="post-body">
                  <EditorJsComponent selectedAction={process.actionsFromProcess} editMode={false}/>
              </b>
            </div>
        </div>
      </div>
    </div>

    <div  className='modal-form'  style={{ display: delModal ? 'block' :'none', zIndex: '13'}}>
      <div className="modal-content">
        <h3>{locales[localisation].are_you_sure}</h3>
      <button onClick={deleteProcess} type='button'>{locales[localisation].yes}</button>
      <button onClick={()=> setDelModal(false)} type='button'>{locales[localisation].cancel}</button>
    </div>
  </div>
  </>
)

}

export default RightMenuProcess
