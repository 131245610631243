// store.ts
import { configureStore } from "@reduxjs/toolkit";
import actionObjectReducer from "../slices/actionObjectSlice";
import processReducer from "../slices/processObjectSlice";
import selectedActionReducer from '../slices/selectedActionSlice';
import actionClickedReducer from '../slices/actionClickedSlice';
import editActionModeReducer from "../slices/editActionModeSlice";
import editTaskModeReducer from '../slices/editTaskModeSlice';
import heatMapModeSlice from '../slices/heatMapModeSlice';
import editMapModeSlice from '../slices/editMapModeSlice';
import editProcessModeReducer from '../slices/editProcessModeSlice';
import createActionClickedReducer from '../slices/createActionClickedSlice'
import selectedTaskReducer from "../slices/selectedTaskSlice";
import taskClickedReducer from "../slices/taskClickedSlice";
import taskDatasetReducer from "../slices/taskDatasetSlice";
import userSlice from '../slices/userSlice'
import rightMenuVisibility from "../slices/rightMenuVisibility";
import tabName from '../slices/tabsInActionSlice';
import arrowsHoverSlice from '../slices/arrowsHoverSlice';
import createProcessClickedSlice from "../slices/createProcessClickedSlice";
import tabTaskNameReducer from '../slices/tabsInTaskSlice';
import taskClickedCreateReducer from '../slices/taskClickedCreateSlice'
import processClickedSlice from "../slices/processClickedSlice";
import companyTaskSliceReducer from "../slices/companyTasks";
import rightMenuShrink from "../slices/rightMenuShrink";
import localisation from "../slices/localisationSlice";
import enlargeDoughnutChartSlice from "../slices/enlargeDoughnutChart";
import companyUsersSlice from "../slices/companyUsersSlice"

const store = configureStore({
    reducer: {
        actionObject: actionObjectReducer,
        selectedAction: selectedActionReducer,
        actionClicked: actionClickedReducer,
        createActionClicked: createActionClickedReducer,
        editActionMode: editActionModeReducer,
        editTaskMode: editTaskModeReducer,
        editProcessMode: editProcessModeReducer,
        process: processReducer,
        user: userSlice,
        rightMenuVisibility: rightMenuVisibility,
        rightMenuShrink: rightMenuShrink,
        tabName: tabName,
        selectedTask: selectedTaskReducer,
        taskClicked: taskClickedReducer,
        taskClickedCreate: taskClickedCreateReducer,
        arrowsHover:arrowsHoverSlice,
        createProcessClickedSlice:createProcessClickedSlice,
        taskDataset: taskDatasetReducer,
        tabTaskName: tabTaskNameReducer,
        processClickedSlice:processClickedSlice,
        companyTaskSlice: companyTaskSliceReducer,
        localisation: localisation,
        heatMapMode: heatMapModeSlice,
        editMapMode: editMapModeSlice,
        enlargeDoughnutChart: enlargeDoughnutChartSlice,
        companyUsers: companyUsersSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
