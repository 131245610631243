import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RESET_STORE } from '../utils/consts';

const initialState: boolean = true

const rightMenuVisibility = createSlice({
  name: 'rightMenuVisibility',
  initialState,
  reducers: {
    setRightMenuVisibility: (state, action: PayloadAction<boolean>) => {
      return action.payload
    }
},
  extraReducers: (builder) => {
    builder.addCase(RESET_STORE, () => initialState);
  },
})

export const {setRightMenuVisibility} = rightMenuVisibility.actions
export default rightMenuVisibility.reducer
