import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../store/store";
import { delTask, setProcess } from "../../slices/processObjectSlice";
import {Input, ActionTo, ActionObject, Process, IMessage} from '../../models/types';
import { renderTaskSavedInput } from '../../utils/functions/renderTaskSavedInput';
import {setTabTaskName} from '../../slices/tabsInTaskSlice';
import {setTaskClicked} from '../../slices/taskClickedSlice';
import { setTaskEditMode } from '../../slices/editTaskModeSlice';
import {setActionObject} from '../../slices/actionObjectSlice';
import { setRightMenuShrink } from "../../slices/rightMenuShrink";
import EditorJsComponent from "./EditorJsComponent";
import EditorJS from '@editorjs/editorjs';
import {resetSelectedTask} from '../../slices/selectedTaskSlice';
import ky from "ky";
import { useState } from 'react';
import locales from '../../utils/locales/locales'

import {setSelectedTask} from "../../slices/selectedTaskSlice";
const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export default function MiddleMenuTask() {

  const dispatch = useDispatch();
  const action = useSelector((state: RootState) => state.actionObject);
  const visibility = useSelector((state: RootState) => state.rightMenuVisibility);
  const task = useSelector((state: RootState) => state.selectedTask)
  const tab_task_name = useSelector((state: RootState) => state.tabTaskName)
  const [formData, setFormData] = useState<number[]>(noCaseForwardActions)
  const process = useSelector((state: RootState) => state.process)
  const shrink = useSelector((state: RootState) => state.rightMenuShrink);
  const user = useSelector((state:RootState)=> state.user);
  const localisation = useSelector((state:RootState)=> state.localisation)
  const combinedLogs : any[] = [...task.taskAssignLogs, ...task.taskForwardLogs]
  const [delModal, setDelModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  /*async function HandleTaskPush(taskId: number, newActionId: number, oldActionId: number) {
    await ky.post(`${API_BASE_URL}/task/push`,{credentials: 'include',json: {taskId: taskId, newActionId: newActionId, oldActionId:oldActionId}}).json()
    dispatch(setTaskClicked(false));
    const updatedActionObject:ActionObject = await ky.post(`${API_BASE_URL}/actions/focus`, {
      credentials: "include",
      json: { actionid: action.id },
    }).json();
    dispatch(setActionObject(updatedActionObject));
    const data: Process = await ky.post(`${API_BASE_URL}/process/focus`, { json: { processId: process.actionsFromProcess.id }, credentials:'include' }).json();
    dispatch(setProcess(data));
  }*/

  function HandleCloseTask() {
    dispatch(setTaskClicked(false));
    dispatch(resetSelectedTask());
  }

  function getDateOnly(dateString: string) {
    const date  = new Date(dateString);
    return date.toLocaleDateString("en-IN",{
      day: "numeric",
      year: "numeric",
      month: "numeric",
    }) + "\n" +
    date.toLocaleDateString("en-IN",{
      weekday: "long",
    });
  }

  function getTimeOnly(dateString: string) {
    const date  = new Date(dateString);
    return date.toLocaleString("en-US",{
      //weekday: "long",
      hour12: false,
      hour: "numeric",
      minute: "numeric"
    });
  }

  function compareLogs( log_a:any, log_b:any ) {
    if ( log_a.createdAt < log_b.createdAt ){
      return 1;
    }
    if ( log_a.createdAt > log_b.createdAt ){
      return -1;
    }
    return 0;
  }

  async function deleteTask() {
    const result: IMessage = await ky
      .delete(`${API_BASE_URL}/task`, {
        json: { taskId: task.id,
          actionId: action.id },
        credentials: "include",
      })
      .json();

    if (result.message === "ok") {
      dispatch(delTask(action.id));
      const data: Process = await ky
        .post(`${API_BASE_URL}/process/focus`, {
          json: { processId: process.actionsFromProcess.id },
          credentials: "include",
        })
        .json();
      dispatch(setProcess(data));
      setDelModal(false);
      dispatch(setTaskClicked(false));
      dispatch(resetSelectedTask());
    }
  }

  async function removeTask() {
    const result: IMessage = await ky
      .put(`${API_BASE_URL}/task/remove`, {
        json: { taskId: task.id,
          actionId: action.id },
        credentials: "include",
      })
      .json();

    if (result.message === "ok") {
      const data: Process = await ky
        .post(`${API_BASE_URL}/process/focus`, {
          json: { processId: process.actionsFromProcess.id },
          credentials: "include",
        })
        .json();
      dispatch(setProcess(data));
      setRemoveModal(false);
      dispatch(setTaskClicked(false));
      dispatch(resetSelectedTask());
    }
  }

  async function setPriority(value: boolean){
    try {
      await ky.put(`${API_BASE_URL}/task/setpriority`, {
        json: {
          id: task.id,
          priority: value
        }, credentials: 'include',
      }).json();

      const updatedActionObject:ActionObject = await ky.post(`${API_BASE_URL}/actions/focus`, {
        credentials: "include",
        json: { actionid: action.id },
      }).json();

      dispatch(setActionObject(updatedActionObject))
      let new_task = updatedActionObject.tasks.filter((e)=>e.id === task.id)[0]
      dispatch(setSelectedTask(new_task));
      dispatch(setTaskEditMode(false));
      dispatch(setTabTaskName({title : "description"}));
      dispatch(setTaskClicked(true));
    } catch (error) {
      console.log(error);
    }
  }

  async function assignUser(value: boolean, user_id: number){
    try {
      if (value){
        await ky.put(`${API_BASE_URL}/task/assign`, {
          json: {
            taskId: task.id,
            user_id,
            action_id: action.id,
          }, credentials: 'include',
        }).json();
      }else{
        await ky.put(`${API_BASE_URL}/task/unassign`, {
          json: {
            taskId: task.id,
            user_id,
            action_id: action.id,
          }, credentials: 'include',
        }).json();
      }
      const updatedActionObject:ActionObject = await ky.post(`${API_BASE_URL}/actions/focus`, {
        credentials: "include",
        json: { actionid: action.id },
      }).json();

      dispatch(setActionObject(updatedActionObject))
      let new_task = updatedActionObject.tasks.filter((e)=>e.id === task.id)[0]
      dispatch(setSelectedTask(new_task));
      dispatch(setTaskEditMode(false));
      dispatch(setTabTaskName({title : "description"}));
      dispatch(setTaskClicked(true));
    } catch (error) {
      console.log(error);
    }
  }


  function noCaseForwardActions(){
    let arr: number[] = []
    for(let i = 0; i< action.To.length; i++){
      if (!action.To[i]['Actions_Actions_joins'].is_case) {
        arr.push( action.To[i].id)
      }
    }
    return arr
  }

  function handleChange(event: {target: {checked: boolean}}, to_id:number) {
    if(event.target.checked){
      setFormData([...formData, Number(to_id)])
    }
    if(!event.target.checked){
      setFormData([...formData].filter((e)=>e !== Number(to_id)))
    }
  }

  async function forwardTaskToSteps(){
  const resultForward: IMessage =  await ky.post(`${API_BASE_URL}/task/forward`,{
      credentials: 'include', json: {
        taskId: task.id, actionsArray: formData, currentActionId: action.id
      }
  }).json()

  if(resultForward.message === "Task was forwarded"){
    const processData: Process = await ky.post(`${API_BASE_URL}/process/focus`, { json: { processId: process.actionsFromProcess.id }, credentials:'include' }).json();
    const updatedActionObject:ActionObject = await ky.post(`${API_BASE_URL}/actions/focus`, {
      credentials: "include",
      json: { actionid: action.id },
    }).json();

    dispatch(setProcess(processData))
    dispatch(setActionObject(updatedActionObject))
    HandleCloseTask()
  }

  }

return(
  <>
    <div id={shrink ? "process-space-middle-shrink" : "process-space-middle"}
    className={shrink ? "process-space-right process-space-right-shrink" : "process-space-right"}
     style={!visibility ?
      { transform: /*"translateX(95%)"*/ "translateX(104%)" } : { transform: 'translateX(0px)' }}>
      <div className="content">
        <div className="header">
          <div className="top">
            <div  className="btns">
              <span onClick={() => HandleCloseTask()} className="right-tab-close-btn icon material-symbols-outlined">
                close
              </span>
              <span  className="dropdown-btn icon material-symbols-outlined">
                more_horiz
                <ul>
                  <li onClick={()=> dispatch(setTaskEditMode(true))}>
                    {locales[localisation].edit_task}
                  </li>

                  <li onClick={() => setRemoveModal(true)}>
                  {locales[localisation].remove_task_from_step}
                  </li>
                  <li onClick={() => setDelModal(true)}>
                  {locales[localisation].delete_task}
                  </li>
                </ul>
              </span>
              <span  className="dropdown-btn icon material-symbols-outlined">
                forward
                <form>

                  <ul>
                    {action.To.map((newAction: ActionTo) => (
                        <li key={Math.ceil(Math.random() * 999999)}>
                          <label>
                          <input onChange={(e)=>handleChange(e, newAction.id)}className="forward-check-box" type="checkbox" checked={formData.includes(newAction.id)}/>
                          <span className='icon material-symbols-outlined'>forward</span> {newAction.name}</label>
                        </li>
                    ))}
                        {action.From.map((newAction: ActionTo) => (
                        <li key={Math.ceil(Math.random() * 999999)}>
                          <label>
                          <input onChange={(e)=>handleChange(e, newAction.id)}  checked={formData.includes(newAction.id)} className="forward-check-box" type="checkbox" />
                          <span className='icon material-symbols-outlined'>undo</span> {newAction.name}</label>
                        </li>
                    ))}
                        <li key={Math.ceil(Math.random() * 999999)}>
                          <label>
                          <input onChange={(e)=>handleChange(e, action.id)} checked={formData.includes(action.id)} className="forward-check-box" type="checkbox" />
                          <span className='icon material-symbols-outlined'>refresh</span> {action.name}</label>
                        </li>
                    {(action.To.length !== 0 || action.From.length !== 0) ?
                    <li className='no-hover' key={Math.ceil(Math.random() * 999999)}>
                      <button type="button" onClick={forwardTaskToSteps}>{locales[localisation].forward_task}</button>
                    </li>
                    :<li>
                      <label>{locales[localisation].no_steps_connected}</label>
                  </li>
                    }
                  </ul>
                </form>

              </span>
            </div>
            <div  className="btns" >
            <b className="task-id-tag" >#{task.id} </b>
            { task.users.filter((u)=>u.id === user.id).length > 0?
                <div className="roundtag" onClick={() => assignUser(false, user.id)}>
                  <b>{locales[localisation].assigned}</b>
                  </div>:
              <div className="roundtag-btn-with-icon" onClick={() => assignUser(true, user.id)}>
              <div className="icon material-symbols-outlined" >bookmark_remove</div>
              <b>{locales[localisation].Assign}</b>
              </div>
            }

            { task.priority?
                <div className="roundtag" onClick={() => setPriority(false)}>
                  <b>{locales[localisation].high_priority}</b>
                  </div>:
                  <div className="roundtag-btn-with-icon" onClick={() => setPriority(true)}>
                  <div className="icon material-symbols-outlined" >warning</div>
                  <b>{locales[localisation].set_priority}</b>
                  </div>
              }


            </div>

          </div>
          <div className="name">
            <h1>
              {task.name}
            </h1>
          </div>
          <div className="right-panel-tab-btns">
            <div className={(tab_task_name.title === "description" ? 'tab-btn-active' : 'tab-btn')} onClick={() => dispatch(setTabTaskName({title: 'description'}))}>
              <span className="icon material-symbols-outlined">
                menu_book
              </span>
              <b>{locales[localisation].Description}</b>
            </div>
            <div className={(tab_task_name.title === "history" ? 'tab-btn-active' : 'tab-btn')} onClick={() => dispatch(setTabTaskName({title: 'history'}))}>
              <span className="icon material-symbols-outlined">
                history
              </span>
              <b>{locales[localisation].History}</b>
            </div>
          </div>
        </div>
        <div className="description" style={tab_task_name.title === "description" ? { display:'flex' } : { display:'none' }}>
          <div id="showDiv" className="post">
          <h3>{locales[localisation].tasks_in_step}:</h3>
            <div className='steps-include'>
              <div className='step'>
                <span className="icon material-symbols-outlined" style={task.actions?.length > 1 ? { display:'flex' } : { display:'none' }}>
                fork_right</span>
                {task.actions?.map((action)=> (
                  <div className="roundtag"><div className='round-divider-dot'/>
                  <b>{action.name}</b>
                  </div>
                ))}

              </div>
            </div>

            {task.users?.length > 0 ? <>
              <h3>{locales[localisation].users_assigned}: </h3>
              <div className='users-include'>
              {task.users?.map((user)=> (
                <>
                   <div className='round-divider-dot'/><b className="active-text">{user.name} {user.surname}</b>
                </>
                ))}
              </div>
            </>:<></>
            }

            </div>
            <div className="post view-post " onClick={() => dispatch(setTaskEditMode(true))}>
                <b className="post-body" >
                  <EditorJsComponent   key={Math.ceil(Math.random() * 999999)}  selectedAction={task} editMode={false}/>
                </b>
            </div>
        </div>
        <div className="description" style={tab_task_name.title === "history" ? { display:'flex' } : { display:'none' }}>

          <div className="log-history">

            {combinedLogs.sort(compareLogs)?.map((log)=> (
              <>
              <div className='log-post'>
                <div className='left-log-part'>
                <b>{getTimeOnly(log.createdAt)}</b>
                <b className='secondary-text'>{getDateOnly(log.createdAt)}</b>
                  <div className='log-point'/>
                </div>
                <div className='right-log-part'>
                  <b>{log.forward_type? "Forward" : "Assign"}</b>
                  <b className='secondary-text'>
                    {log.forward_type? 
                  <>
                  {log.from_action_name ? `"${log.from_action_name}"` : "[created]"} {"->"} {log.to_action_name ? `"${log.to_action_name}"` : "[removed]"} {"\n"}by user: #{log.user_id}
                  </>
                  : <>
                  {log.moderator_id == log.user_id? log.moderator_id + " " + log.assign_type +" himself" : log.moderator_id + " " + log.assign_type + " user " + log.user_id} 
                  </>
                  }  
                  </b>
                  </div>

              </div>
              <div className='vertical-line'/>
              </>
                  ))}
              <div className='log-post'>
                <div className='left-log-part'>
                  <div className='log-point'/>
                </div>
                <div className='right-log-part'>
                  <b>
                    </b>
                  </div>

              </div>

          </div>
        </div>



      </div>
    </div>
    <div
        className="modal-form"
        style={{ display: delModal ? "block" : "none", zIndex: "13" }}
      >
        <div className="modal-content">
          <h3>{locales[localisation].are_you_sure}</h3>
          <button onClick={deleteTask} type="button">
          {locales[localisation].yes}
          </button>
          <button onClick={() => setDelModal(false)} type="button">
          {locales[localisation].cancel}
          </button>
        </div>
      </div>

      <div
        className="modal-form"
        style={{ display: removeModal ? "block" : "none", zIndex: "13" }}
      >
        <div className="modal-content">
          <h3>{locales[localisation].are_you_sure}</h3>
          <button onClick={removeTask} type="button">
          {locales[localisation].yes}
          </button>
          <button onClick={() => setRemoveModal(false)} type="button">
          {locales[localisation].cancel}
          </button>
        </div>
      </div>
  </>
  )

}
