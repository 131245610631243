import React from 'react';
import locales from '../utils/locales/locales'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../store/store";

function ErrorPage(): JSX.Element {
    const localisation = useSelector((state:RootState)=> state.localisation)

    return (
        <div className='msg-404'>
            <div className='landing-title-font'>404</div>
            <b>{locales[localisation].text_404} <a href='/'>{locales[localisation].text_404_back}</a></b>
        </div>
    );
}

export default ErrorPage;
