/*
import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { ActionObject, Task } from "../models/types";

export const initialState: ActionObject = {
    id: 0,
    name: "",
    description: "",
    createdAt: "",
    updatedAt: "",
    teams: [],
    tasks: [],
    From: [],
    To: [],
};

const actionObjectSlice = createSlice({
    name: "actionObject",
    initialState,
    reducers: {
        setActionObject: (state, action: PayloadAction<ActionObject>) => {
            return action.payload;
        },
        updateDescriptionFN: (state, action: PayloadAction<string>)=> {
            state.description = action.payload;
        },
        setTaskstoObject: (state, action: PayloadAction<Task[]>) => {
          state.tasks = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateDescription, (state, action) => {
            state.description = action.payload.description;
        });
    },
});


export const { setActionObject,updateDescriptionFN, setTaskstoObject} = actionObjectSlice.actions;


export const updateDescription = createAction<{ description: string }>(
    "actionObject/updateDescription"
);


export default actionObjectSlice.reducer;
*/

import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { ActionObject, Task } from "../models/types";
import { RESET_STORE } from '../utils/consts';

export const initialState: ActionObject = {
    id: 0,
    name: "",
    description: "",
    createdAt: "",
    updatedAt: "",
    teams: [],
    tasks: [],
    From: [],
    To: [],
};

const actionObjectSlice = createSlice({
    name: "actionObject",
    initialState,
    reducers: {
        setActionObject: (state, action: PayloadAction<ActionObject>) => {
            const updatedState = { ...initialState, ...action.payload };
            return updatedState;
        },
        updateDescriptionFN: (state, action: PayloadAction<string>) => {
            state.description = action.payload;
        },
        setTaskstoObject: (state, action: PayloadAction<Task[]>) => {
            state.tasks = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateDescription, (state, action) => {
                state.description = action.payload.description;
            })
            .addCase(RESET_STORE, () => initialState);
    },
});

export const { setActionObject, updateDescriptionFN, setTaskstoObject } = actionObjectSlice.actions;

export const updateDescription = createAction<{ description: string }>(
    "actionObject/updateDescription"
);

export default actionObjectSlice.reducer;
