import ky from 'ky'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { RootState } from '../store/store'
import { RESET_STORE } from '../utils/consts';
import locales from '../utils/locales/locales'
import { setLocalisation } from '../slices/localisationSlice'

function NavBar():JSX.Element {
const dispatch = useDispatch();
const navigate = useNavigate();

  const user = useSelector((state:RootState)=> state.user)
  const localisation = useSelector((state:RootState)=> state.localisation)

  const API_BASE_URL = process.env.REACT_APP_BASE_URL;

  async function logout(){
    ky.get(`${API_BASE_URL}/user/logout`,{credentials: 'include'})
    dispatch({ type: RESET_STORE });
  navigate('/')
  }

  return (
    <nav>
        <div className="nav-organize">
          <div className="nav-logo">
            {user.id ?
            <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
              <b><div className="extra-black">CONDQT</div></b>
            </Link>
            :
            <Link to="/about" style={{ textDecoration: 'none', color: 'white' }}>
              <b><div className="extra-black">CONDQT</div></b>
            </Link>
            }
          </div>
          <div className="nav-items">
            <div className="nav-item">
              <Link
                to="/about"
                style={{ textDecoration: 'none', color: 'white' }}
              >
                <b>{locales[localisation].about}</b>
              </Link>
            </div>
            {user.id ? <div className="nav-item">
              <Link
                to="/"
                style={{ textDecoration: 'none', color: 'white' }}
              >
                <b>{locales[localisation].workspace}</b>
              </Link>
            </div> : <></>
            }
          </div>

            <div className="nav-user-section">
              <div className='localisation-btn'>
              <span className="dropdown-btn dropdown-btn-left icon material-symbols-outlined">
                  language
                  <ul>
                    <li onClick={() => dispatch(setLocalisation('en'))}>
                      English
                    </li>
                    <li onClick={() => dispatch(setLocalisation('ru'))}>
                      Русский</li>                    
                      <li onClick={() => dispatch(setLocalisation('ja'))}>
                      日本語</li>
                  </ul>
                </span>
              </div>
              {user.id ? (

                <b onClick={logout} className="secondary-btn">{locales[localisation].sign_out}</b>
                ) : (
                  <Link to="/signin" style={{ textDecoration: 'none', color: 'white' }}>
                    <b className="secondary-btn">{locales[localisation].sign_in}</b>
                  </Link>
                  )}


            </div>
        </div>
      </nav>
  )
}

export default NavBar
