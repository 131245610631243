import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ky from 'ky';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { setUser } from '../../slices/userSlice';
import { User as IUser } from '../../models/types';
import locales from '../../utils/locales/locales'

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export default function RoleChoicePage(): JSX.Element {

    function getCookie(name: any) {
        const cookieArr = document.cookie.split(";");

        for(let i = 0; i < cookieArr.length; i++) {
            let cookiePair = cookieArr[i].split("=");

            if(name === cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]);
            }
        }

        return null;
    }

    const localisation = useSelector((state:RootState)=> state.localisation)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userId, setUserId] = useState(null);
    const [formData, setFormData] = useState({ role: 'user', company: '', token: '' });

    useEffect(() => {
        const userIdFromCookie = getCookie('userId');
        if(userIdFromCookie) {
            setUserId(JSON.parse(userIdFromCookie)); // parse JSON, because the value was stringified when setting the cookie
        }
    }, []);

    function handleChange(event: { target: { name: string; value: string; }; }) {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    async function formSubmitHandler() {
        try {
            const result: IUser = await ky.post(`${API_BASE_URL}/user/set-role`, { json: { ...formData, userId }, credentials: 'include' }).json();
            if (result) {
                dispatch(setUser(result));
                setFormData({ role: 'user', company: '', token: '' });
                navigate('/');
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
        <div className="login-page">
        <div className="form">
        <form className="role-choice-form">
        <b style={{ color: "#fff" }}>{locales[localisation].choose_a_role}</b>
    <select name="role" onChange={handleChange} value={formData.role}>
    <option value="user">{locales[localisation].user}</option>
        <option value="founder">{locales[localisation].founder}</option>
        </select>

    {formData.role === 'founder'
        ?
        <input onChange={handleChange} name="company" type="text" placeholder="company" value={formData.company} />
    :
        <input onChange={handleChange} name="token" type="text" placeholder="invite token" value={formData.token} />}

    <button onClick={formSubmitHandler} type="button">{locales[localisation].save}</button>
    <Link to='/signin'>{locales[localisation].change_account}</Link>
    </form>
    </div>
    </div>
    </>);
    }
