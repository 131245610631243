import ky from 'ky';
import React, { useEffect, useState, useCallback} from 'react'
import {useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import LeftBar from '../../components/LeftBarFounder/LeftBar'
import { RootState } from '../../store/store';
import './teams.css';
import {FilterTeam, Process, Team} from "../../models/types";
import {setProcess} from "../../slices/processObjectSlice";
import CreateTeamForm from './CreateTeamForm';
import locales from '../../utils/locales/locales'


const API_BASE_URL = process.env.REACT_APP_BASE_URL;

interface IUserInfo{
  id: number;
  name: string;
  surname: string;
  login: string;
  email: string;
  role: string;
  teams: ITeamInfo[]
}

interface ITeamInfo {
  id: number;
  name: string;
  tag: string;
  hex_code: string;
  description: string;
  Users_Teams_joins: IJoinsInfo;
}

interface IJoinsInfo {
  id: number;
  user_id: number;
  team_id: number;
  createdAt: any;
  updatedAt: any;
}

function CompanyPageFounder() {

  const user = useSelector((state:RootState)=> state.user)
  const teams = useSelector((state: RootState) => state.process.allTeamsInCompany)
  const companyId = useSelector((state: RootState) => state.process.companyId);
  const processId = useSelector((state: RootState) => state.process.actionsFromProcess.id);
  const localisation = useSelector((state:RootState)=> state.localisation)

  const [info, setInfo] = useState<any>(null)

  const [showAddTeamInput, setShowAddTeamInput] = useState(0)
  const [showChangeRoleInput, setShowChangeRoleInput] = useState(0)
  const [showEditTeamInput, setShowEditTeamInput] = useState(0)
  const [formAddTeamData, setFormAddTeamData] = useState(teams[0]?.id)
  const [formChangeRoleData, setFormChangeRoleData] = useState(0)

  const [formEditTeamInputName, setFormEditTeamInputName] = useState('')
  const [formEditTeamInputTag, setFormEditTeamInputTag] = useState('')
  const [formEditTeamInputHex, setFormEditTeamInputHex] = useState(0)

  const [showCreateTeamInput, setShowCreateTeamInput] = useState(0)


  const [delModal, setDelModal] = useState(0)

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const fetchData = useCallback(async () => {
    try {
      const result: any = await ky.post(`${API_BASE_URL}/company/info`, { credentials: 'include', json: { id: companyId } }).json();
      if (!result || !result.users) {
        navigate('/');
      } else {
        setInfo(result);
      }
    } catch (error) {
      console.error('Error fetching company info:', error);
      navigate('/');
    }
  }, [companyId, navigate]);

  const roles = [
    {id: 0, name: 'admin'},
    {id: 1, name: 'user'},
  ]

  const hex_codes = [
    {id:0, code: '#50AA94'},
    {id:1, code: '#CBA9F6'},
    {id:2, code: '#6B5B95'},
    {id:3, code: '#4CB5F5'},
    {id:4, code: '#AF556B'},
    {id:5, code: '#F9A26C'},
  ]

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  async function addUserToTeam() {
    try {
      const result: any = await ky.post(`${API_BASE_URL}/user/team`, { credentials: 'include', json: { teamId: formAddTeamData, targetId: showAddTeamInput } }).json()
      if(result) {
        setFormAddTeamData(0)
        setShowAddTeamInput(0)
        ky.post(`${API_BASE_URL}/company/info`, { credentials: 'include', json: { id: companyId } }).json().then((result: any) => setInfo(result))
      }
    } catch (error) {

    }
  }


  async function changeRole() {
    try {
      const role = roles[formChangeRoleData].name
      const result: any = await ky.post(`${API_BASE_URL}/user/role`, { credentials: 'include', json: { role, targetUserId: showChangeRoleInput, companyId} }).json()
      if(result) {
        setFormChangeRoleData(0)
        setShowChangeRoleInput(0)
        ky.post(`${API_BASE_URL}/company/info`, { credentials: 'include', json: { id: companyId } }).json().then((result: any) => setInfo(result))
      }
    } catch (error) {

    }
  }

  async function saveEditTeamInput(){
    try {
      const hex_c = hex_codes[formEditTeamInputHex].code
      const result: any = await ky.put(`${API_BASE_URL}/team`,
      { credentials: 'include',
        json: {
          id: showEditTeamInput,
          hex_code: hex_c,
          name: formEditTeamInputName,
          description: formEditTeamInputName,
          tag: formEditTeamInputTag} }).json()
      if(result) {
        setFormEditTeamInputHex(0)
        setFormEditTeamInputName('')
        setFormEditTeamInputTag('')
        setShowEditTeamInput(0)
        ky.post(`${API_BASE_URL}/company/info`, {
          credentials: 'include',
          json: {id: companyId}
        }).json().then((result: any) => setInfo(result))
        const data: Process = await ky.post(`${API_BASE_URL}/process/focus`, {
          json: {processId},
          credentials: 'include'
        }).json();
        dispatch(setProcess(data));
      }
    } catch (error) {

    }
  }



  async function deleteUser() {
    try {
      const result: any = await ky.delete(`${API_BASE_URL}/user/company`, { credentials: 'include', json: { user_id: delModal, company_id: companyId } }).json()
      if(result) {
        setDelModal(0)
        ky.post(`${API_BASE_URL}/company/info`, { credentials: 'include', json: { id: companyId } }).json().then((result: any) => setInfo(result))
      }
    } catch (error) {

    }
  }

  async function handleEditTeam(team: FilterTeam) {
    setFormEditTeamInputName(team.name)
    setFormEditTeamInputTag(team.tag)
    const hex_index = hex_codes.findIndex(x => x.code === team.hex_code)
    setFormEditTeamInputHex(hex_index)
    setShowEditTeamInput(team.id)
  }

  async function handleDeleteTeam(teamId:number) {
  try {
    const result = await ky.delete(`${API_BASE_URL}/team` , { credentials: 'include', json: { id: teamId }}).json();
    if(result) {
      ky.post(`${API_BASE_URL}/company/info`, {
        credentials: 'include',
        json: {id: companyId}
      }).json().then((result: any) => setInfo(result))
      const data: Process = await ky.post(`${API_BASE_URL}/process/focus`, {
        json: {processId},
        credentials: 'include'
      }).json();
      dispatch(setProcess(data));
    }
  } catch (error) {
  }
  }

  async function handleDeleteFromTeam(user_id: number, team_id: number) {
    try {
      const result: any = await ky.delete(`${API_BASE_URL}/user/team`, { credentials: 'include', json: { user_id: user_id, team_id: team_id } }).json()
      if(result) {
        ky.post(`${API_BASE_URL}/company/info`, { credentials: 'include', json: { id: companyId } }).json().then((result: any) => setInfo(result))
      }
    } catch (error) {

    }
  }

  async function refreshToken() {
    try {
      const result: any = await ky.put(`${API_BASE_URL}/company/info`, { credentials: 'include', json: { id: companyId } }).json()
      if(result) {
        ky.post(`${API_BASE_URL}/company/info`, { credentials: 'include', json: { id: companyId } }).json().then((result: any) => setInfo(result))

      }
    } catch (error) {

    }
  }

  return (
    <div className='workspace' >
      <LeftBar/>
      <div className='process-space'>
      <div className='process-space-center'>
        <div className='settings-space'>
        <div className='sub-left-bar'>
          <h1>{locales[localisation].company_settings}</h1>

          <div className='menu'>

          <div className='item item-active'><b>{locales[localisation].Teams}</b></div>
          <div className='item'><b>{locales[localisation].users_and_token}</b></div>
          </div>
        </div>
        <div className='sub-right-space'>
        <h1>{locales[localisation].teams_table}</h1>
          <div className='teams-section'>
              {teams?.map((team) =>
              <div key={Math.ceil(Math.random() * 999999)} className='team-settings'>
                  <div className='team'>
                  <h3>{team.name}</h3>

                  <div className='teamtag'  style={{ backgroundColor: `${team.hex_code}`, color: "black"}}>
                    <b>@{team.tag}</b>
                  </div>
                  <span className="dropdown-btn dropdown-btn icon material-symbols-outlined" 
                  style={user.role !== 'founder' && user.role !== 'admin' ? {display: "none"} : {display: "flex"}}>
                    more_horiz
                    <ul>
                    <li key={Math.ceil(Math.random() * 999999)} onClick={() => handleDeleteTeam(team.id)}>
                      {locales[localisation].delete_team}</li>
                    <li key={Math.ceil(Math.random() * 999999)} onClick={() => handleEditTeam(team)}>
                    {locales[localisation].edit_team}</li>
                    </ul>
                  </span>
                  </div>

                  <div className='team-members'>
                      {info?.users?.map((userElement: IUserInfo) => {
                        if (userElement?.teams?.map((zal: ITeamInfo) => zal.id).includes(team?.id)) {
                        return ( <><div className='member' key={Math.ceil(Math.random() * 999999)}>
                          <b>{userElement?.name} {userElement?.surname}</b>
                          <b>@{userElement?.login}</b>
                          <b>{userElement?.email}</b>
                          <span className="dropdown-btn dropdown-btn-left icon material-symbols-outlined"
                          style={user.role !== 'founder' && user.role !== 'admin' ? {display: "none"} : {display: "flex"}}>
                          more_horiz
                          <ul>
                              <li onClick={() => handleDeleteFromTeam(userElement?.id , team.id)}>{locales[localisation].delete_from_team}</li>
                    </ul></span>
                        </div></> )
                        } else {
                        return ( <></> )
                        }
                      })}
                  </div>
              </div>
              )}
            <div key={Math.ceil(Math.random() * 999999)} className='team-settings'
            style={user.role !== 'founder' && user.role !== 'admin' ? {display: "none"} : {display: "flex"}}>
              {showCreateTeamInput ? (
                  <CreateTeamForm
                      hex_codes={hex_codes}
                      setInfo={setInfo}
                      setShowCreateTeamInput={setShowCreateTeamInput}
                  />
              ) : (
                  <div
                      className="right-menu-arrow-button right-menu-arrow-edit-button"
                      onClick={() => setShowCreateTeamInput(1)}
                  >
                    {locales[localisation].Add_team}
                  </div>
              )}

            </div>
          </div>
          <div>
            <h1>{locales[localisation].Generated_Authentication_Token}</h1>
            <div className='token' style={{ marginTop: 30}}>
              <b className='token-field'>{info?.token}</b>
              <button type='button' onClick={refreshToken} style={user.role !== 'founder'? {display: "none"} : {display: "flex", width: 200}}>{locales[localisation].refresh_token}</button>
            </div>
          </div>
            <h1>{locales[localisation].company_users}</h1>
            <div className='users-section'>
              {info?.users.map((userElement: IUserInfo) => {
                return (<div key={Math.ceil(Math.random() * 999999)}>
                <div className='user'>

                <div className='user-description'>
                  <h3>{userElement.name} {userElement.surname}</h3>
                  <b className='inactive'>@{userElement?.login}</b>

                </div>
                <div className='user-description'>
                          <b>{userElement?.email}</b>
                          <b className='inactive'>{userElement.role}</b>

                </div>

                  <div className='teams'>

                  {userElement.teams.map((team) =>
                    <div key={Math.ceil(Math.random() * 999999)} className="teamtag" style={{ backgroundColor: `${team.hex_code}`}}>
                      <b>@{team.tag}</b>
                      </div>
                  )}


                  </div>
                  <span className="dropdown-btn dropdown-btn-left icon material-symbols-outlined"
                  style={user.role !== 'founder' && user.role !== 'admin' ? {display: "none"} : {display: "flex"}}>
                    more_horiz
                    <ul>
                      <li  onClick={() => setShowAddTeamInput(userElement.id)}>
                      {locales[localisation].add_to_team}
                      </li>
                      <li onClick={() => setDelModal(userElement.id)}>{locales[localisation].delete_from_company}</li>
                      {userElement.role !== 'founder'?<li onClick={() => setShowChangeRoleInput(userElement.id)}>{locales[localisation].change_role}</li>:<></>}

                    </ul>
                  </span>
                </div>
            </div>
  )})}
          </div>
          <div  className='modal-form'  style={{ display: showAddTeamInput ? 'block' :'none', zIndex: '13'}}>
            <div className="modal-content">
              <b>{locales[localisation].select_team}</b>
              <select name="team" id="1" onChange={(event) => setFormAddTeamData(Number(event?.target.value))} value={formAddTeamData}>
                {teams.map((team) =>
                <option key={Math.ceil(Math.random() * 999999)}  value={team.id}>{team.name}</option>
              )}
              </select>
              <button onClick={addUserToTeam} type='button'>{locales[localisation].save_changes}</button>
              <button onClick={()=> setShowAddTeamInput(0)} type='button'>{locales[localisation].cancel}</button>
            </div>
          </div>

          <div  className='modal-form'  style={{ display: showChangeRoleInput ? 'block' :'none', zIndex: '13'}}>
            <div className="modal-content">
              <b>{locales[localisation].select_user_role}</b>

              <select name="role" id="2" onChange={(event) => setFormChangeRoleData(Number(event?.target.value))} value={formChangeRoleData}>
                {roles.map((role) =>
                  <option key={Math.ceil(Math.random() * 999999)} value={role.id}>{role.name}</option>
                )}
              </select>
              <button onClick={changeRole} type='button'>{locales[localisation].save_changes}</button>
              <button onClick={()=> setShowChangeRoleInput(0)} type='button'>{locales[localisation].cancel}</button>
            </div>
          </div>

          <div  className='modal-form'  style={{ display: showEditTeamInput ? 'block' :'none', zIndex: '13'}}>
            <div className="modal-content">
              <b>{locales[localisation].Edit_team}</b>
              <input name="team_name" id="3" type="text"  onChange={(event) => setFormEditTeamInputName(String(event?.target.value))} value={formEditTeamInputName}/>

              <input name="tag_name" id="4" type="text"  onChange={(event) => setFormEditTeamInputTag(String(event?.target.value))} value={formEditTeamInputTag}/>

              <select name="hex_code" id="5" onChange={(event) => setFormEditTeamInputHex(Number(event?.target.value))} value={formEditTeamInputHex}>
                {hex_codes.map((hex_c) =>
                  <option  key={Math.ceil(Math.random() * 999999)} value={hex_c.id} style={{backgroundColor:hex_c.code}}>{hex_c.code}</option>
                )}
              </select>

              <button onClick={saveEditTeamInput}  type='button'>{locales[localisation].save_changes}</button>
              <button onClick={()=> setShowEditTeamInput(0)} type='button'>{locales[localisation].cancel}</button>
            </div>
          </div>

          </div>
          <div  className='modal-form'  style={{ display: delModal ? 'block' :'none', zIndex: '13'}}>
            <div className="modal-content">
              <h3>{locales[localisation].do_you_want_to_delete_user}</h3>
              <button onClick={ deleteUser } type='button'>{locales[localisation].yes}</button>
              <button onClick={() => setDelModal(0)} type='button'>{locales[localisation].cancel}</button>
            </div>
          </div>
          </div>
        </div>
      </div>
  </div>
  )
}

export default CompanyPageFounder
