import React, { useEffect, useState } from 'react';
import ky from 'ky';
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import { publicRoutes, /*userRoutes,*/ founderRoutes, founderMobileRoutes } from '../routes';
import { isMobile } from 'react-device-detect';
import ErrorPage from '../pages/Errorpage';
import AboutPage from '../pages/Aboutpage';
import Policypage from '../pages/Policypage'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../store/store";
import NavBar from './NavBar';
import { setUser } from '../slices/userSlice';
import {User} from '../models/types'
import VerificationSuccess from "../pages/VerificationSuccess";
import VerificationError from "../pages/VerificationError"
import Notverifiedpage from "../pages/Notverifiedpage";
import VerificationFromEmail from "../pages/VerificationFromEmail";

function AppRouter(): React.ReactElement {
    const user = useSelector((state:RootState)=> state.user)
    let userId = user.id;
    const userRole = user.role;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();


    const API_BASE_URL = process.env.REACT_APP_BASE_URL;



    useEffect(() => {
        const fetchUser = async () => {
            try {
                const result: User = await ky.get(`${API_BASE_URL}/user/auth`, { credentials: 'include' }).json();
                console.log(`RESULT`, result);
                console.log(`USER ROLE`, result.role)
                console.log('RESULT IF', !result.is_verified)
                dispatch(setUser(result));
                if (result.is_verified === false ){
                    navigate('/verification');
                }
                if (result.role === undefined) {
                    navigate('/chooseRole');
                }

            } catch (error) {
                console.error('Could not fetch user:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUser();
    }, [dispatch, navigate]);


    if (isLoading) {
        return(
            <>
                </>
        )
    }

    return (
        <>
            <NavBar/>
            <Routes>
                {!userRole &&
                    publicRoutes.map(({ path, element }) => (
                        <Route path={path} element={element} key={Math.ceil(Math.random() * 99999)} />
                    ))}
                {userRole && !isMobile &&
                    (userRole === 'user' || userRole === 'founder' || userRole === 'admin')&&
                    founderRoutes.map(({ path, element }) => (

                                <Route path={path} element={element} key={Math.ceil(Math.random() * 99999)} />

                    ))}
                {userRole && isMobile &&
                    (userRole === 'user' || userRole === 'founder' || userRole === 'admin')&&
                    founderMobileRoutes.map(({ path, element }) => (

                        <Route path={path} element={element} key={Math.ceil(Math.random() * 99999)} />
                        ))}
                {userRole &&
                    userRole === 'founder' &&
                    founderRoutes.map(({ path, element }) => (
                        <Route path={path} element={element} key={Math.ceil(Math.random() * 99999)} />
                    ))}
                {/*userRole &&
                    userRole === 'founder' &&
                    companyCreatorId !== userId &&
                    userRoutes.map(({ path, element }) => (
                        <Route path={path} element={element} key={Math.ceil(Math.random() * 99999)} />
                    ))*/}
                <Route path='/about' element={<AboutPage />} />
                <Route path='/policy' element={<Policypage />} />
                <Route path='/verification' element={<Notverifiedpage />} />
                <Route path='/verification-success' element={<VerificationSuccess />} />
                <Route path='/verification-error' element={<VerificationError />} />
                <Route path='/verification-email' element={<VerificationFromEmail />} />
                <Route path="*" element={<ErrorPage />} />

            </Routes>
        </>
    );
}

export default AppRouter;
