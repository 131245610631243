
import Header from '@editorjs/header';

import List from '@editorjs/list';

import Paragraph from '@editorjs/paragraph';

import Checklist from '@editorjs/checklist'

import Table from '@editorjs/table';

import Image from '@editorjs/image';

import Attaches from '@editorjs/attaches';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const EDITOR_JS_TOOLS = {
    header: {
        class: Header,
        config: {
            placeholder: 'Enter a header',
            levels: [1, 2, 3],
            defaultLevel: 1
          }
    },
    list: List,
    paragraph: Paragraph,
    checklist: Checklist,
    table: Table,
    image: {
        class: Image,
        config: {
            caption: false,
            endpoints: {
                byFile: `${API_BASE_URL}/uploadImage/`,
            }
        },
    },
    attaches: {
        class: Attaches,
        config: {
            endpoint: `${API_BASE_URL}/uploadFile/`
        }
    },
};
