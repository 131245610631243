import React, { useEffect, useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import { EDITOR_JS_TOOLS } from '../../utils/editorJsTools';
import { Action } from '../../models/types';
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";

interface EditorJsComponentProps {
  selectedAction: any;
  setEditorInstance?: (editorInstance: EditorJS | null) => void;
  editMode: boolean;
}

const EditorJsComponent: React.FC<EditorJsComponentProps> = ({ selectedAction, setEditorInstance, editMode }) => {
  const editorContainer = useRef<HTMLDivElement>(null);
  //const editActionMode = useSelector((state: RootState) => state.editActionMode);
  let editorInstance: EditorJS | null = null;

  useEffect(() => {
    let blocks;

    try {
      blocks = JSON.parse(selectedAction.description).blocks;
    } catch (error) {
      console.error('Invalid JSON:', selectedAction?.description);
      blocks = [];
    }

    if (editorContainer.current) {
      const readOnly = !editMode

      editorInstance = new EditorJS({
        holder: editorContainer.current,
        autofocus: true,
        tools: EDITOR_JS_TOOLS,
        data: {
          blocks: blocks,
        },
        readOnly
      });

      if (setEditorInstance) {
        setEditorInstance(editorInstance);
      }
    }

    return () => {
      if (editorInstance) {
        editorInstance.destroy?.();
        editorInstance = null;
      }
    };
  }, [selectedAction, setEditorInstance]);

  return (
    <>
      <div id="editorjs" ref={editorContainer} className="editorjs-container"></div>
    </>
  );
};

export default EditorJsComponent;