import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RESET_STORE } from '../utils/consts';

const initialState: boolean = false

const taskClickedSlice = createSlice({
  name: 'taskClicked',
  initialState,
  reducers: {
    setTaskClicked: (state, action: PayloadAction<boolean>) => {
      return action.payload
    }
},
  extraReducers: (builder) => {
    builder.addCase(RESET_STORE, () => initialState);
  },
})

export const {setTaskClicked} = taskClickedSlice.actions
export default taskClickedSlice.reducer
