import ky from 'ky';
import React, { useEffect, useState } from 'react'
import LeftBar from '../../components/LeftBarFounder/LeftBar'
import Dataset from '../../components/TasksDataset/Dataset'
import RightMenuTask from '../../components/TasksDataset/RightMenuTask'
import RightMenuTaskEdit from '../../components/TasksDataset/RightMenuTaskEdit'
import { RootState } from '../../store/store';
import { setCompanyTasks } from "../../slices/companyTasks";
import { ActionObject, ITasks, Process, Task } from "../../models/types";
import {setProcess} from "../../slices/processObjectSlice";
import { useDispatch, useSelector } from "react-redux";
import locales from '../../utils/locales/locales'

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

function TasksPageFounder() {

  const taskClicked = useSelector((state: RootState) => state.taskClicked)
  const taskClickedCreate = useSelector((state: RootState) => state.taskClickedCreate)
  const editTaskMode = useSelector((state:RootState) => state.editTaskMode)
  const localisation = useSelector((state:RootState)=> state.localisation)

  const user = useSelector((state:RootState)=> state.user)
  const companyId = useSelector((state: RootState) => state.process.companyId)
  const dispatch = useDispatch();

  async function getProcessSteps(userId: number) {
    const data:Process = await ky.post(`${API_BASE_URL}/process/steps`, { json: { userId } , credentials:'include'}).json();
    return data;
}

  useEffect(() => {
      async function fetchData() {
          try {
              const data = await getProcessSteps(user.id);
              dispatch(setProcess(data));
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      }
      fetchData();
  }, [dispatch, user.id]);

  
  return (
    <div className='workspace' >
      <LeftBar/>  
      <Dataset/>
      {taskClicked && !editTaskMode && <RightMenuTask/>}
      {/*taskClickedCreate && <MiddleMenuTaskCreate/>*/}
      {editTaskMode && <RightMenuTaskEdit/>}
      
          <div  className='modal-form'  style={{ display: false ? 'block' :'none', zIndex: '13'}}>
            <div className="modal-content">
              <h3>{locales[localisation].are_you_sure}</h3>
              <button type='button'>{locales[localisation].yes}</button>
              <button  type='button'>{locales[localisation].cancel}</button>
            </div>
          </div>
      </div>
  )
}

export default TasksPageFounder