import { ActionTo } from "../../models/types"

export default function arrowJoinItemGenerator(arrow: ActionTo, is_from: boolean, color:string, randomNumber: number, user_role: string, handleArrowDelete: (id: number) => Promise<void>, handleArrowCaseReverse: (id: number,  old_case: boolean) => Promise<void>) {
  return (
      <div key={randomNumber} className="arrow-joins-item">
        <div className="arrow-joins-first-line" >
        {(() => {
            if (arrow.Actions_Actions_joins?.is_case && !is_from) {
              return (
                <div className="arrow-icon">
                  <div className="icon material-symbols-outlined action-case" style={{  color: `${color}` }}>
                    keyboard_option_key
                  </div>
                  <div className="icon material-symbols-outlined action-case" style={{ color: `${color}` }}>
                    east
                  </div>
                </div>
              )
            } else if (!is_from) {
              return (
                <div className="arrow-icon">

                  <div className="icon material-symbols-outlined action-case" style={{ color: `${color}` }}>
                    east
                  </div>
                </div>

              )
            } else if (arrow.Actions_Actions_joins?.is_case) {
              return (
                <div className="arrow-icon">

                  <div className="icon material-symbols-outlined action-case" style={{ color: `${color}` }}>
                    west
                  </div>
                  <div className="icon material-symbols-outlined action-case" style={{transform: 'scaleX(-1)', color: `${color}` }}>
                    keyboard_option_key
                  </div>
                </div>

              )
            } else {
              return (
                <div className="arrow-icon">

                <div className="icon material-symbols-outlined action-case" style={{ color: `${color}` }}>
                  west
                </div>
                </div>

              )
            }
          })()}
          
          <b className="action-name">
            {arrow.name}
          </b>


          <span className="dropdown-btn dropdown-btn-left icon material-symbols-outlined" 
            style={user_role !== 'founder' && user_role !== 'admin' ? {display: "none"} : {display: "flex"}}>
            more_horiz
            <ul>
              <li onClick={() => handleArrowDelete(arrow.Actions_Actions_joins.id)}>Delete Connection</li>
              <li onClick={() => handleArrowCaseReverse(arrow.Actions_Actions_joins.id, arrow.Actions_Actions_joins.is_case)}>Case / Always {arrow.Actions_Actions_joins.is_case}</li>
            </ul>
          </span>
        </div>

      </div>

  )
}


            {/*
      <div className="arrow-joins-item-form">
        <div className="arrow-joins-first-line">
         {arrow.name && <b className="action-name">
            {arrow.name}
          </b>}

          {(() => {
            if (arrow.Actions_Actions_joins?.is_case && is_from) {
              return (<>
                <div className="icon material-symbols-outlined action-case" style={{ transform: 'scaleX(-1)', color: `${color}` }}>
                  keyboard_option_key
                </div>
                  <div className="icon material-symbols-outlined action-case" style={{ color: `${color}` }}>
                  east
                </div>
                </>
                )
            } else if (is_from) {
              return (
                <div className="icon material-symbols-outlined action-case" style={{ color: `${color}` }}>
                  east
                </div>)
            } else if (arrow.Actions_Actions_joins?.is_case) {
              return (
                <>
                <div className="icon material-symbols-outlined action-case" style={{ color: `${color}` }}>
                  keyboard_option_key
                </div>
                <div className="icon material-symbols-outlined action-case" style={{ color: `${color}` }}>
                west
              </div>
              </>)
            } else {
              return (
                <div className="icon material-symbols-outlined action-case" style={{ color: `${color}` }}>
                  west
                </div>)
            }
          })()}
         {arrow.Actions_Actions_joins.data && <b className="action-data"
            style={arrow.Actions_Actions_joins.data == null ? { color: "#888" } : {}}
          >
            {arrow.Actions_Actions_joins.data}
          </b>}
        </div>
        
        <div className="arrow-joins-body">
          <div className="line-item">
            <b className="item-name">
              Connected to:
            </b>
            {arrow.name &&<b className="item">
              {arrow.name}
            </b>}
          </div>
          <form name="editArrow" >
            {arrow.id ? <input type="hidden" name="arrowid" value={arrow.id} />: <input type="hidden" name="arrowid"  />}
            <div className="line-item">
              <b className="item-name">
                Data:
              </b>
              <b className="item">
                {arrow.Actions_Actions_joins?.data ? <input name="data" value={arrow.Actions_Actions_joins?.data} type="text" placeholder="Post Title" autoComplete="off" /> : <input name="data"  type="text" placeholder="Post Title" autoComplete="off" />}
              </b>
            </div>
            <div className="line-item">
              <b className="item-name">
                Conditions/Always:
              </b>
              <select name="is_case">
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
            </div>

            <div className="line-item" >
              <b className="item-name" >
                Condition:
              </b>
              {arrow.Actions_Actions_joins?.description && <textarea name="condition" placeholder="Case Conditions" autoComplete="off">{arrow.Actions_Actions_joins?.description}</textarea>}
            </div>
            <div className="line-item">
              <b className="item-name" >
                Case:
              </b>
              {arrow.Actions_Actions_joins?.description && <textarea name="description" placeholder="Description" autoComplete="off">{arrow.Actions_Actions_joins?.description}</textarea>}
            </div>
            <div className="line-item">
              <div className="right-menu-arrow-button right-menu-arrow-cancel-button">
                Cancel
              </div>
              <button type="submit" className="right-menu-arrow-button right-menu-arrow-save-button">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
      */}