// @ts-nocheck
import ky from "ky";
import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../store/store";
import { ActionTaskForwardLogs } from "../../models/types";
import {
  Chart as ChartJS,
  CategoryScale,
  LineController,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineController,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface ChartProps {
  data: ActionTaskForwardLogs;
  type?: "monthly" | "weekly";
}

const LineChart: React.FC<ChartProps> = ({ data, type= "weekly"}) => {
  const now = new Date();
  const action = useSelector((state: RootState) => state.actionObject);
  const completedCounts = {};
  const importedCounts = {};
  const totalPeriods = type === "monthly" ? 4 : 7; // 1 month or 4 weeks


  function onlyUniqueLogs(value:Log, index:number, array: Log[]) {
    return array.findIndex(el => el.task_id == value.task_id) === index;
    }


  for (let i = 0; i < totalPeriods; i++) {
    const periodDate = new Date();
    if (type === "monthly") {
      periodDate.setMonth(now.getMonth() - i);
    } else {
      periodDate.setDate(now.getDate() - (i * 7));
    }
    periodDate.setDate(type === "monthly" ? 1 : periodDate.getDate() - periodDate.getDay() + 1);
    const key = `${periodDate.getDate()}-${periodDate.getMonth() + 1}`//`${periodDate.getFullYear()}-${periodDate.getMonth() + 1}-${periodDate.getDate()}`;
    completedCounts[key] = 0;
    importedCounts[key] = 0;
  }

  data.export_logs.filter(onlyUniqueLogs).forEach( (log) => {
    const date = new Date(log.createdAt);
    date.setDate(type === "monthly" ? 1 : date.getDate() - date.getDay() + 1);
    const key = `${date.getDate()}-${date.getMonth() + 1}`//`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

   /* const date = new Date(log.createdAt);
    date.setDate(type === "monthly" ? 1 : date.getDate() - date.getDay() + 1);
    const key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;*!/
*/

    // Check if the task is joined with the action from which it was exported
    const isJoined = action.tasks.find(task => task.id === log.task_id);
    //console.log(`action.tasks`, action.tasks, log.task_id)
    //console.log(`log + ${log} . Is isJoined:`, isJoined)



    if (!isJoined && completedCounts[key] !== undefined) {
      completedCounts[key]++;
    }
    importedCounts[key]++;
  });

  data.import_logs.filter(onlyUniqueLogs).forEach( (log) => {
    const date = new Date(log.createdAt);
    date.setDate(type === "monthly" ? 1 : date.getDate() - date.getDay() + 1);
    const key = `${date.getDate()}-${date.getMonth() + 1}`//`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    importedCounts[key]++;
  });


  const labels = Object.keys(completedCounts).slice(0, totalPeriods).reverse();
  const completedData = Object.values(completedCounts).slice(0, totalPeriods).reverse();
  const importedData = Object.values(importedCounts).slice(0, totalPeriods).reverse();

  console.log('importedData', importedData)

  const chartData = {
    labels,
    datasets: [
      {
        label: type === "monthly" ? "Completed per Month" : "Completed per Week",
        backgroundColor: "#FCCA02",
        type: "line",
        fill: 'origin',
        borderColor: '#FCCA02', 
        lineTension: 0.3,  
        data: completedData,
      },
      {
        label: type === "monthly" ? "Forwarded to step per Month" : "Forwarded to step per Week",
        backgroundColor: "#888",
        type: "bar",
        fill: 'origin',
        borderColor: "#50AA94", 
        lineTension: 0.3,  
        data: importedData,
      },
    ],
  };

  //console.log(`CHART_DATA`,chartData);

  return (
      <Line 
          height={200}
          width={200}
          data={chartData}
          options={{
            
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              x: {
                ticks: {
                  font: {
                    size: 14,
                    family: "Inter",
                    weight: "300",
                  },
                  
                },
                grid: {
                  color: "rgba(0, 0, 0, 0)",
              }
              },
              y: {
                stacked: false,
                beginAtZero: true,
                ticks: {
                  stepSize: 1,
                  font: {
                    size: 14,
                    family: "Inter",
                    weight: "300",
                  },
                },
                grid: {
                  color: "rgba(0, 0, 0, 0)",
              }
              },
            },
            plugins: {
              title: {
                display: false,
                text: type === "monthly" ? "Tasks per Month" : "Tasks per Week",
                font: {
                  size: 16,
                  family: "Inter",
                  weight: "300",
                },
              },
              legend: {
                position: "bottom",
                labels: {
                  font: {
                    size: 14,
                    family: "Inter",
                    weight: "300",
                  },
                  color: "#fff",
                },
              },
            },
          }}
      />
  );
};

export default LineChart;
