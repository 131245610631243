import React from 'react';
import locales from '../utils/locales/locales'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../store/store";
import { BrowserRouter as Router, Route } from 'react-router-dom';

function VerificationSuccess(): JSX.Element {
    const localisation = useSelector((state:RootState)=> state.localisation)

    return (
        <div className="policy-space">
            <div className="policy-block" style={{ paddingTop: 50, paddingBottom: 50, zIndex: '1' }}>
                <h1>Your email has been successfully verified! Please log in.</h1>
            </div>
        </div>
    )
}

export default VerificationSuccess;
