import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RESET_STORE } from '../utils/consts';
import { ActionObject } from "../models/types";

const initialState: ActionObject[] = [];

const taskDatasetSlice = createSlice({
  name: 'taskDataset',
  initialState,
  reducers: {
    setTaskDataset: (state, action: PayloadAction<ActionObject[]>) => {
      return action.payload
    }
},
  extraReducers: (builder) => {
    builder.addCase(RESET_STORE, () => initialState);
  },
})

export const {setTaskDataset} = taskDatasetSlice.actions
export default taskDatasetSlice.reducer
