import ky from 'ky';
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../store/store";
import { ActionObject, Process, Task } from '../../models/types';
import { setActionObject } from '../../slices/actionObjectSlice';
import { setProcess } from '../../slices/processObjectSlice';
import locales from '../../utils/locales/locales'

import {setTaskClickedCreate} from '../../slices/taskClickedCreateSlice';
import { setTaskstoObject } from '../../slices/actionObjectSlice';
import {setTaskClicked} from '../../slices/taskClickedSlice'
import {setSelectedTask} from "../../slices/selectedTaskSlice";
import {setTabTaskName} from "../../slices/tabsInTaskSlice";
import { setRightMenuShrink } from "../../slices/rightMenuShrink";
import EditorJsComponent from "./EditorJsComponent";
import EditorJS from '@editorjs/editorjs';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export default function MiddleMenuTaskCreate() {
  const dispatch = useDispatch();
  const visibility = useSelector((state: RootState) => state.rightMenuVisibility);
  const [inputTitle, setInputTitle] = useState('')
  const [taskDescription, setTaskDescription] = useState('');
  const selectedAction = useSelector((state:RootState) => state.selectedAction)
  const user = useSelector((state: RootState) => state.user)
  const process = useSelector((state: RootState) => state.process);
  const shrink = useSelector((state: RootState) => state.rightMenuShrink);
  const [editorInstance, setEditorInstance] = useState<any>(null);
  const selectedTask = useSelector((state:RootState) => state.selectedTask);
  const localisation = useSelector((state:RootState)=> state.localisation)

  const handleInputTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputTitle(event.target.value);
  };

  function HandleCloseTask() {
    dispatch(setTaskClickedCreate(false));
  }

  async function handleCreateTask() {
    try {
      const editorContent = await editorInstance?.save();

      const createTaskResponse:Task = await ky.post(`${API_BASE_URL}/task`, {
        json: {
          name: inputTitle,
          description: JSON.stringify(editorContent),
          actionId: selectedAction.id,
          userId: user.id,
        }, credentials: 'include',
      }).json();
      const updatedActionObject:ActionObject = await ky.post(`${API_BASE_URL}/actions/focus`, {
        credentials: "include",
        json: { actionid: selectedAction.id },
      }).json();
      const updatedProcess:Process= await ky.post(`${API_BASE_URL}/process/focus`,{json: { processId: process.actionsFromProcess.id }, credentials: 'include'}).json();

      dispatch(setActionObject(updatedActionObject))
      dispatch(setProcess(updatedProcess))
      let new_task = updatedActionObject.tasks.filter((e)=>e.id === createTaskResponse.id)[0]
      dispatch(setSelectedTask(new_task));
      
      
      //dispatch(setTaskstoObject(createTaskResponse))
      dispatch(setTaskClickedCreate(false));
      dispatch(setTabTaskName({title : "description"}));
      dispatch(setTaskClicked(true));
    } catch (error) {
      console.log(error);
    }
  }


return(
  <>
    <div id={shrink ? "process-space-middle-shrink" : "process-space-middle"}
    className={shrink ? "process-space-right process-space-right-shrink" : "process-space-right"}
     style={!visibility ? 
      { transform: /*"translateX(95%)"*/ "translateX(104%)" } : { transform: 'translateX(0px)' }}>
      <div className="content">
        <div className="header">
          <div className="top">
            <div  className="btns">
              <span onClick={() => HandleCloseTask()} className="right-tab-close-btn icon material-symbols-outlined">
                close
              </span>
            </div>
            <div className="btns">

                <div className="roundtag-btn-with-icon" onClick={handleCreateTask}>
                <div className="icon material-symbols-outlined" >
                  SAVE</div><b>{locales[localisation].Save}</b>
                </div>
            </div>
          </div>
            <div className="name">
          <h1 className='step-input-title'>
          <input className="h1-input" type="text" name='title' onChange={handleInputTitle} placeholder={locales[localisation].enter_new_task_title} value={inputTitle}/>
          </h1>
        </div>
      </div>
      <div className="description">
        <div className="post editor-post">
            <EditorJsComponent  setEditorInstance={setEditorInstance} selectedAction={selectedTask} editMode={true}/>
          </div>
      </div>
    </div>
    </div>
  </>
  )
}



