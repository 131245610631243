import ky from 'ky';
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../store/store";

import { ActionObject, Process, Task } from '../../models/types';
import { setActionObject } from '../../slices/actionObjectSlice';
import { setTaskEditMode } from '../../slices/editTaskModeSlice';
import { setProcess } from '../../slices/processObjectSlice';
import { setTaskClicked } from '../../slices/taskClickedSlice';
import {setTabTaskName} from "../../slices/tabsInTaskSlice";
import {setSelectedTask} from "../../slices/selectedTaskSlice";
import { setRightMenuShrink } from "../../slices/rightMenuShrink";
import EditorJsComponent from "./EditorJsComponent";
import locales from '../../utils/locales/locales'

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export default function MiddleMenuTaskEdit() {
  const dispatch = useDispatch();
  const visibility = useSelector((state: RootState) => state.rightMenuVisibility);
  const [editorInstance, setEditorInstance] = useState<any>(null);
  const [taskDescription, setTaskDescription] = useState('');
  const user = useSelector((state: RootState) => state.user)
  const selectedTask = useSelector((state:RootState) => state.selectedTask)
  const [inputTitle, setInputTitle] = useState(selectedTask.name)
  const action = useSelector((state: RootState) => state.actionObject);
  const process = useSelector((state: RootState) => state.process);
  const shrink = useSelector((state: RootState) => state.rightMenuShrink);
  const localisation = useSelector((state:RootState)=> state.localisation)



  const handleInputTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputTitle(event.target.value);
  };

  function HandleCloseTask() {
    dispatch(setTaskEditMode(false));
  }

  async function updateTaskinDB() {
    try {

      const editorContent = await editorInstance?.save();

      interface IupdateTaskResponse {
        task: Task;
      }

      const updateTaskResponse: IupdateTaskResponse = await ky.put(`${API_BASE_URL}/task`, {
        json: {
          id: selectedTask.id,
          name: inputTitle,
          description: JSON.stringify(editorContent),
          userId: user.id,
          actionId: action.id,
        }, credentials: 'include',
      }).json();

      const updatedActionObject:ActionObject = await ky.post(`${API_BASE_URL}/actions/focus`, {
        credentials: "include",
        json: { actionid: action.id },
      }).json();
      const updatedProcess:Process= await ky.post(`${API_BASE_URL}/process/focus`,{json: { processId: process.actionsFromProcess.id }, credentials: 'include'}).json();

      //dispatch(setActionObject(updatedActionObject))
      dispatch(setProcess(updatedProcess))
      let new_task = updatedActionObject.tasks.filter((e)=>e.id === selectedTask.id)[0]
      dispatch(setSelectedTask(new_task));
      dispatch(setTaskEditMode(false));
      dispatch(setTabTaskName({title : "description"}));
      dispatch(setTaskClicked(true));
    } catch (error) {
      console.log(error);
    }
  }

  function cancelHandler() {
    dispatch(setTaskEditMode(false));
  }

return(
  <>
    <div id={shrink ? "process-space-middle-shrink" : "process-space-middle"}
    className={shrink ? "process-space-right process-space-right-shrink" : "process-space-right"}
    style={!visibility ? 
      { transform:/*"translateX(95%)"*/ "translateX(104%)"  } : { transform: 'translateX(0px)' }}>
      <div className="content">
        <div className="header">
          <div className="top">
            <div  className="btns">
              <span onClick={() => HandleCloseTask()} className="right-tab-close-btn icon material-symbols-outlined">
                close
              </span>
            </div>
            <div className="btns">
              <div className="roundtag-btn-with-icon" onClick={cancelHandler}>

               <div className="icon material-symbols-outlined" >
                cancel</div><b>{locales[localisation].Cancel}</b>
              </div>

                <div className="roundtag-btn-with-icon" onClick={updateTaskinDB}>
                <div className="icon material-symbols-outlined" >
                  SAVE</div><b>{locales[localisation].Save}</b>
                </div>
            </div>
          </div>
            <div className="name">
          <h1 className='step-input-title'>
          <input className="h1-input" type="text" name='title' onChange={handleInputTitle} placeholder={locales[localisation].enter_new_task_title} value={inputTitle}/>
          </h1>
        </div>
      </div>
      <div className="description">
      <div className="post editor-post">
              <EditorJsComponent  setEditorInstance={setEditorInstance} selectedAction={selectedTask} editMode={true}/>
      </div>
      </div>
    </div>
    </div>
  </>
  )
}



