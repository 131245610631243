// @ts-nocheck
import ky from "ky";
import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../store/store";
import { ActionTaskForwardLogs } from "../../models/types";
import {
  Chart as ChartJS,
  CategoryScale,
  LineController,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  LineController,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ChartProps {
  data: ActionTaskForwardLogs;
  type?: "monthly" | "weekly";
  }
  
  const AvgSpeedChart: React.FC<ChartProps> = ({ data, type= "weekly"}) => {
    
    const now = new Date();
    const action = useSelector((state: RootState) => state.actionObject);
    const completedCounts = {};
    const completedValues = {};
    const totalPeriods = type === "monthly" ? 4 : 7; // 1 month or 4 weeks
    
    const tasks_start_end = {} 
    const tasksCounts = []
  
  
    function onlyUniqueLogs(value:Log, index:number, array: Log[]) {
      return array.findIndex(el => el.task_id == value.task_id) === index;
      }
  
  
    for (let i = 0; i < totalPeriods; i++) {
      const periodDate = new Date();
      if (type === "monthly") {
        periodDate.setMonth(now.getMonth() - i);
      } else {
        periodDate.setDate(now.getDate() - (i * 7));
      }
      periodDate.setDate(type === "monthly" ? 1 : periodDate.getDate() - periodDate.getDay() + 1);
      const key = `${periodDate.getDate()}-${periodDate.getMonth() + 1}`//`${periodDate.getFullYear()}-${periodDate.getMonth() + 1}-${periodDate.getDate()}`;
      completedCounts[key] = 0;
      completedValues[key] = 0;
    }

    data.import_logs.forEach((log) => {
      const start_date = new Date(log.createdAt);
      if (tasks_start_end[log.task_id] !== undefined)
      {
        if (tasks_start_end[log.task_id]["start_date"] > start_date)
        {
          tasks_start_end[log.task_id]["start_date"] = start_date
        }
      }
      else{
        let end_date = start_date
        data.export_logs.forEach((exlog) => {
          const temp_date = new Date(exlog.createdAt);
          if(exlog.task_id == log.task_id){
            if (end_date < temp_date)
            {
              end_date = temp_date
            }
          }
        })
        tasks_start_end[log.task_id]= {
          "start_date": start_date,
          "end_date": end_date,
          "task_id" : log.task_id,
          "delta": ((end_date - start_date)  / 36e5).toFixed(1)
        }

      }
      //date.setDate(type === "monthly" ? 1 : date.getDate() - date.getDay() + 1);
      //const key = `${date.getDate()}-${date.getMonth() + 1}`//`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    });

    data.import_logs.filter(onlyUniqueLogs).forEach( (log) => {
      const date = new Date(log.createdAt);
      date.setDate(type === "monthly" ? 1 : date.getDate() - date.getDay() + 1);
      const key = `${date.getDate()}-${date.getMonth() + 1}`//`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      const isJoined = action.tasks.find(task => task.id === log.task_id);
      if (!isJoined && completedCounts[key] !== undefined) {
        completedCounts[key]++;
        completedValues[key] = completedValues[key] * (completedCounts[key] - 1) / completedCounts[key] + tasks_start_end[log.task_id]["delta"] / completedCounts[key];
      }
    });
    //console.log("tasks_start_end", tasks_start_end)
    //console.log("completedValues", completedValues)




  const labels = Object.keys(completedValues).slice(0, totalPeriods).reverse();
  const completedData = Object.values(completedValues).slice(0, totalPeriods).reverse();
  //const importedData = Object.values(importedCounts).slice(0, totalPeriods).reverse();

  //console.log("completedCounts",completedCounts)

  const chartData = {
    labels,
    datasets: [
      {
        label: type === "monthly" ? "Average per Month" : "Average per Week",
        backgroundColor: "#FCCA02",
        type: "line",
        fill: 'origin',
        borderColor: '#FCCA02', 
        lineTension: 0.3,  
        data: completedData,
      },
      /*{
        label: type === "monthly" ? "Median per Month" : "Median per Week",
        backgroundColor: "#50AA94",
        type: "line",
        fill: 'origin',
        borderColor: "#50AA94", 
        lineTension: 0.3,  
        data: completedData,
      },*/
    ],
  };

  /*
  const temporary_data2= [
    {
      id:1,
      year: 2016,
      userGain: 80000,
      userLost: 823
    },
    {
      id:2,
      year: 2017,
      userGain: 23560,
      userLost: 1823
    },
    {
      id:3,
      year: 2018,
      userGain: 56560,
      userLost: 1223
    },
  ]

  const temporary_data= {
    labels: temporary_data2.map((data) => data.year),
    datasets: [],
  }
    const labels= ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']
    const data2= {
      labels,
      datasets: [
        {
          label: 'Median',
          backgroundColor: '#FCCA02',
          type: "line",
          fill: 'origin',
          borderColor: '#FCCA02', 
          lineTension: 0.3,  
          data: [48, 30, 25, 34, 18, 23, 17]
        },
        {
          label: 'Average',
          backgroundColor: '#50AA94',
          borderColor: '#50AA94',
          lineTension: 0.3,                
          type: "line",

          data: [35, 29, 28, 38, 19, 20, 16]
        }
      ]}
*/

    return <Line 
    height={200} 
    width={200} 
    data={chartData} 
    
    options={{
      elements: {
        point:{
            radius: 0
        }
    },
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        x: {
          stacked: false,
          ticks: {
            font: {
              size: 14,
              family: 'Inter',
              weight: '300',
            }
          },
          grid: {
            color: "rgba(0, 0, 0, 0)",
          }
        },
        y: {
          stacked: false,
          ticks: {
            font: {
              size: 14,
              family: 'Inter',
              weight: '300',
            },
            stepSize: 1 // <----- This prop sets the stepSize
          },
          grid: {
            color: "rgba(0, 0, 0, 0)",
          }
        },

      },
        

      plugins:{
        title: {
          display: false,
          text: 'Tasks per Day',
          font: {
            size: 16,
            family: 'Inter',
            weight: '300',
          },
        },
        legend:{
          position: 'bottom', 
          labels:{
            font: {
              size: 14,
              family: 'Inter',
              weight: '300',
            },
            color: '#fff'
          },
          
        }
      }
    }}
    />
    
    
     
  };
  
  export default AvgSpeedChart;