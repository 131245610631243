import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RESET_STORE } from '../utils/consts';

const initialState: string = 'en'

const localisation = createSlice({
  name: 'localisation',
  initialState,
  reducers: {
    setLocalisation: (state, action: PayloadAction<string>) => {
      return action.payload
    }
},
  extraReducers: (builder) => {
    builder.addCase(RESET_STORE, () => initialState);
  },
})

export const {setLocalisation} = localisation.actions
export default localisation.reducer
