import ky from "ky";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import { setProcess } from "../../slices/processObjectSlice";
import { setSelectedAction } from "../../slices/selectedActionSlice";

import {
  setActionObject
} from "../../slices/actionObjectSlice";
import { Process, FilterTeam, ActionObject, ActionWithEmpty, Action} from "../../models/types";
import { setTabName } from '../../slices/tabsInActionSlice';
import { setRightMenuVisibility } from "../../slices/rightMenuVisibility";
import { setActionEditMode } from "../../slices/editActionModeSlice";
import { setActionClicked } from "../../slices/actionClickedSlice";
import { setRightMenuShrink } from "../../slices/rightMenuShrink";
import EditorJsComponent from "./EditorJsComponent";
import locales from '../../utils/locales/locales'

const API_BASE_URL = process.env.REACT_APP_BASE_URL;


function RightMenuAction() {
  const dispatch = useDispatch();
  const action = useSelector((state: RootState) => state.actionObject);
  const tab_name = useSelector((state: RootState) => state.tabName)
  const [inputTitle, setInputTitle] = useState<string>(action.name);
//   const editMode = useSelector((state: RootState) => state.editMode);
  const [actionDescription, setActionDescription] = useState('');
  // начальное значение тегов из actionObject(actionTags)
  const actionTags = action.teams.map((team) => ({
    id: team.id,
    name: team.name,
    tag: team.tag,
    hex_code: team.hex_code
  }));
  const [selectedTags, setSelectedTags] = useState<FilterTeam[]>([...actionTags]);
  const tagsObjects = useSelector((state:RootState) => state.process.allTeamsInCompany)
    .filter(el => !selectedTags.some(e => el.id === e.id))
  const [tags, setTags] = useState<FilterTeam[]>(tagsObjects);
  const [inputValue, setInputValue] = useState("");
  const visibility = useSelector((state: RootState) => state.rightMenuVisibility);
  const process = useSelector((state: RootState) => state.process)
  const shrink = useSelector((state: RootState) => state.rightMenuShrink);
  const selectedAction = useSelector((state:RootState) => state.selectedAction);
  const [editorInstance, setEditorInstance] = useState<any>(null);
  const localisation = useSelector((state:RootState)=> state.localisation)

  const tagsNow = tags.filter((tag) =>
    tag.name.toLowerCase().includes(inputValue.toLowerCase())
  );

  const handleDeleteTag = (tagToDelete: FilterTeam) => {
    const newTags = selectedTags.filter(
      (tag) => JSON.stringify(tag) !== JSON.stringify(tagToDelete)
    );
    setSelectedTags(newTags);
    setTags([...tags, tagToDelete]);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputTitle(event.target.value);
  };

  const handleTagClick = (tag: FilterTeam) => {
    if (!selectedTags.includes(tag)) {
      setSelectedTags([...selectedTags, tag]);
      setTags(
        [...tags].filter((el) => JSON.stringify(el) !== JSON.stringify(tag))
      );
      setInputValue("");
    }
  };

  const updateActionInDB = async () => {
    try {

      const editorContent = await editorInstance?.save();


      await ky.put(`${API_BASE_URL}/actions`, {
          credentials: "include",
          json: {
            actionId: action.id,
            name: inputTitle,
            description: JSON.stringify(editorContent),
            teamIds: selectedTags.map((tag) => tag.id),///????
          },
        })
        .json();
        const updatedActionObject:ActionObject = await ky.post(`${API_BASE_URL}/actions/focus`, {
            credentials: "include",
            json: { actionid: action.id },
          }).json();
        const updatedProcess:Process= await ky.post(`${API_BASE_URL}/process/focus`,{json: { processId: process.actionsFromProcess.id }, credentials: 'include'}).json();

        if (updatedActionObject) {
          dispatch(setActionClicked(true));
          dispatch(setProcess(updatedProcess))
         setInputTitle('')
         setInputValue('')
         setSelectedTags([])
         //dispatch(setSelectedAction(updatedActionObject));
        dispatch(setActionObject(updatedActionObject))
        dispatch(setActionEditMode(false));
      }
    } catch (error) {
     console.log('ERROR', error);
    }
   };



   function cancelHandler() {
    dispatch(setActionEditMode(false));
   }

   
  function closeRightMenu(){
    dispatch(setActionClicked(false));
    dispatch(setRightMenuVisibility(!visibility))
  }


  return (
    <>
      <div
        className={shrink ? "process-space-right process-space-right-shrink" :"process-space-right" }
        style={
          !visibility
              ? { display: "none" }
              : { display: "flex" }
        }
      >
        <div className="content">
          <div className="header">
            <div className="top">
              <div className="btns">
                <span
                  onClick={closeRightMenu}
                  className="right-tab-close-btn icon material-symbols-outlined"
                >
                  close
                </span>
                <span
                  onClick={() => dispatch(setRightMenuShrink(!shrink))}
                  className="right-tab-close-btn icon material-symbols-outlined"
                >
                  {shrink ?  "expand_content" : "close_fullscreen"}
                </span>
              </div>
              <div className="btns">
                <div className="roundtag-btn-with-icon" onClick={cancelHandler}>
                <div className="icon material-symbols-outlined" >
                  cancel</div><b>{locales[localisation].Cancel}</b>
                </div>

                  <div className="roundtag-btn-with-icon" onClick={updateActionInDB}>
                  <div className="icon material-symbols-outlined" >
                    SAVE</div><b>{locales[localisation].Save}</b>
                  </div>
              </div>
            </div>
            <div className="name">
              <h1 className='step-input-title'>
                <input
                  className="h1-input"
                  type="text"
                  name="title"
                  onChange={handleInputTitle}
                  placeholder={locales[localisation].enter_new_step_title}
                  value={inputTitle}
                />
              </h1>
            </div>

          </div>

          <div className="description">
            <div className="teams">
              <h3>{locales[localisation].teams_involved_to_sub_actions}</h3>
              <div className="team-search-bar">
                {selectedTags.map((tag) => (
                  <div
                    className="teamtag"
                    style={{ backgroundColor: tag.hex_code, cursor: "pointer" }}
                    key={tag.id}
                    onClick={() => handleDeleteTag(tag)}
                  >
                    <b>@{tag.tag} &times;</b>
                  </div>
                ))}
                <input
                  className="team-search-bar-input"
                  placeholder={locales[localisation].start_typing_team}
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
              <ul className="teams-suggestion">
                <b className="teams-suggestion-title">{locales[localisation].available_teams}:</b>
                {tagsNow.map((tag) => (
                  <li
                    style={{ backgroundColor: tag.hex_code, cursor: "pointer" }}
                    className="teamtag"
                    key={tag.id}
                    onClick={() => handleTagClick(tag)}
                  >
                    <b>@{tag.tag}</b>
                  </li>
                ))}
              </ul>
            </div>
            <div className="post editor-post">
              <EditorJsComponent  setEditorInstance={setEditorInstance} selectedAction={action} editMode={true}/>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default RightMenuAction;
