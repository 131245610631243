import ky from 'ky';
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../store/store";
import {  setProcess } from '../../slices/processObjectSlice';
import { Process} from '../../models/types';
import { setRightMenuVisibility } from '../../slices/rightMenuVisibility';
import { FilterTeam } from '../../models/types';
import { setSelectedAction } from "../../slices/selectedActionSlice";
import { setRightMenuShrink } from "../../slices/rightMenuShrink";
import { IMessage } from "../../models/types";
import locales from '../../utils/locales/locales'

import { setActionClicked } from '../../slices/actionClickedSlice';
import { setActionEditMode } from '../../slices/editActionModeSlice';
import { setActionObject } from '../../slices/actionObjectSlice';
import { setCreateActionClicked } from '../../slices/createActionClickedSlice';
import { setTabName } from '../../slices/tabsInActionSlice';
import { arrowsHover } from '../../slices/arrowsHoverSlice';
import EditorJsComponent from "./EditorJsComponent";
const API_BASE_URL = process.env.REACT_APP_BASE_URL;

function RightMenuActionCreate() {

  const dispatch = useDispatch();
  const tagsObjects = useSelector((state:RootState) => state.process.allTeamsInCompany)
  const process = useSelector((state:RootState) => state.process);
  const selectedAction = useSelector((state:RootState) => state.selectedAction);
  const visibility = useSelector((state: RootState) => state.rightMenuVisibility)
  const [tags, setTags] = useState<FilterTeam[]>(tagsObjects)

  const localisation = useSelector((state:RootState)=> state.localisation)

  const [editorInstance, setEditorInstance] = useState<any>(null);
  const [inputTitle, setInputTitle] = useState('')
  const [inputValue, setInputValue] = useState('');
  const [selectedTags, setSelectedTags] = useState<FilterTeam[]>([]);
  //const [actionDescription, setActionDescription] = useState('');
  const shrink = useSelector((state: RootState) => state.rightMenuShrink);
  const [showLinkedActionModal, setShowLinkedActionModal] = useState(false);


  const external_steps = process.externalSteps
  const [linkedActionFormData, setLinkedActionFormData] = useState(external_steps.length >0 ?  external_steps[0].id: 0)

  const handleInputTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputTitle(event.target.value);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const handleTagClick = (tag: FilterTeam) => {
    if (!selectedTags.includes(tag)) {
      setSelectedTags([...selectedTags, tag]);
      setTags([...tags].filter(el=> JSON.stringify(el) !== JSON.stringify(tag)));
      setInputValue('');
    }
  };

  async function addLinkedAction() {
    try {
      if (linkedActionFormData !== 0){
        const result: any = await ky
        .post(`${API_BASE_URL}/process/add`, {
            credentials: 'include',
            json: {
              actionId: linkedActionFormData,
              row: selectedAction.row,
              line: selectedAction.line,
              processId: process.actionsFromProcess.id,
            }
          }
        ).json()
        if(result) {
          setLinkedActionFormData(0)
          setShowLinkedActionModal(false)
          const updatedProcess: Process= await ky.post(`${API_BASE_URL}/process/focus`,{json: {processId: process.actionsFromProcess.id }, credentials: 'include'}).json();
          dispatch(setActionEditMode(false));
          dispatch(setCreateActionClicked(false));
          dispatch(setProcess(updatedProcess))
          dispatch(setActionObject(result.action))
          dispatch(setActionClicked(true));
          dispatch(setTabName({title: 'description'}))
          dispatch(arrowsHover(true));
          dispatch(setSelectedAction(result.action))
         setInputTitle('')
         setInputValue('')
         setSelectedTags([])
        }
      }
      else
      {
        console.log('Trying to link step with id=0')
      }

    } catch (error) {

    }
  }


  const tagsNow = tags.filter((tag) =>
      tag.name.toLowerCase().includes(inputValue.toLowerCase())
  );


  const handleDeleteTag = (tagToDelete: FilterTeam) => {
    const newTags = selectedTags.filter((tag) => JSON.stringify(tag) !== JSON.stringify(tagToDelete));
    setSelectedTags(newTags);
    setTags([...tags, tagToDelete])
  };


  async function publishAction () {
     try {

       const editorContent = await editorInstance?.save();

       const createActionResponse: any = await ky.post(`${API_BASE_URL}/actions`, {
         json: {
           row: selectedAction.row,
           line: selectedAction.line,
           processid: process.actionsFromProcess.id,
           name: inputTitle,
           description: JSON.stringify(editorContent),
           teamIds: selectedTags.map((tag) => tag.id),
         }, credentials: 'include',
       }).json();

       const updatedProcess: Process= await ky.post(`${API_BASE_URL}/process/focus`,{json: {processId: process.actionsFromProcess.id }, credentials: 'include'}).json();
       dispatch(setActionEditMode(false));
       dispatch(setCreateActionClicked(false));
       dispatch(setProcess(updatedProcess))
       dispatch(setActionObject(createActionResponse.action))
       dispatch(setActionClicked(true));
       dispatch(setTabName({title: 'description'}))
       dispatch(arrowsHover(true));
       dispatch(setSelectedAction(createActionResponse.action))
      setInputTitle('')
      setInputValue('')
      setSelectedTags([])
    } catch (error) {
      console.error("Error:", error);
    }
  }

  function closeRightMenu(){
    dispatch(setActionClicked(false));
    dispatch(setRightMenuVisibility(!visibility))
  }

  return (
    <>
    <div className={shrink ?  "process-space-right process-space-right-shrink" : "process-space-right"}
                 style={
                  !visibility
                      ? { display: "none" }
                      : { display: "flex" }
                }>
      <div className="content">
      <div className="header">
        <div className="top">
          <div  className="btns">
            
            <span onClick={closeRightMenu}
             className="right-tab-close-btn icon material-symbols-outlined">
              close
            </span>
            <span
              onClick={() => dispatch(setRightMenuShrink(!shrink))}
              className="right-tab-close-btn icon material-symbols-outlined"
            >
              {shrink ? "expand_content" : "close_fullscreen" }
            </span>
          </div>
          <div className="btns">
                <div className="roundtag-btn-with-icon"  onClick={() => setShowLinkedActionModal(true)} >
                <div className="icon material-symbols-outlined" >
                layers</div><b>{locales[localisation].link_existing_step}</b>
                </div>
                <div className="roundtag-btn-with-icon" onClick={publishAction}>
                <div className="icon material-symbols-outlined" >
                  SAVE</div><b>{locales[localisation].Save}</b>
                </div>
            </div>
        </div>
        <div className="name">
          <h1 className='step-input-title'>
          <input className="h1-input" type="text" name='title' onChange={handleInputTitle} placeholder={locales[localisation].enter_new_step_title} value={inputTitle}/>
          </h1>
        </div>
      </div>

        <div className="description">
          <div className="teams">
            <h3>
              {locales[localisation].teams_involved_to_step}
            </h3>
              <div className="team-search-bar">
                {selectedTags.map((tag) => (
                  <div className="teamtag" style={{backgroundColor: tag.hex_code, cursor:'pointer'}} key={tag.id} onClick={() => handleDeleteTag(tag)}>
                    <b>@{tag.tag} &times;</b>
                  </div>
                ))}
                <input className="team-search-bar-input" placeholder={locales[localisation].start_typing_team} value={inputValue} onChange={handleInputChange} />
              </div>
              <ul className="teams-suggestion">
                <b className="teams-suggestion-title">{locales[localisation].available_teams}:</b>
                {tagsNow.map((tag) => (
                    <li style={{ backgroundColor: tag.hex_code, cursor:'pointer'}} className="teamtag" key={tag.id} onClick={() => handleTagClick(tag)}>
                    <b>@{tag.tag}</b>
                  </li>
                ))}
              </ul>
          </div>
          <div className="post editor-post">
            <EditorJsComponent  setEditorInstance={setEditorInstance} selectedAction={selectedAction} editMode={true}/>
          </div>
        </div>

      </div>
    </div>

    <div
        className="modal-form"
        style={{ display: showLinkedActionModal? "block" : "none", zIndex: "13" }}
      >
        <div className="modal-content">
          <h3 style={{display: external_steps.length > 0 ?  "none" : "block" }}>{locales[localisation].no_external_steps_found}</h3>
          <h3 style={{display: external_steps.length > 0 ?  "block" : "none"}}
          >
            {locales[localisation].choose_step_from_another_process}
          </h3>
          <select style={{display: external_steps.length > 0 ?  "block" : "none"}}
           name="stepjoin" id="1" onChange={(event) => setLinkedActionFormData(Number(event?.target.value))} value={linkedActionFormData}>
                {external_steps?.map((ex_step) =>
                <option key={Math.ceil(Math.random() * 999999)}  value={ex_step.id}>{ex_step.name}</option>
              )}
          </select>
          <button style={{display: external_steps.length > 0 ?  "block" : "none"}}
           onClick={addLinkedAction} type="button">
            {locales[localisation].add_step}
          </button>
          <button onClick={() => setShowLinkedActionModal(false)} type="button">
          {locales[localisation].cancel}
          </button>
        </div>
      </div>

  </>
  )
}

export default RightMenuActionCreate
