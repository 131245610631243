import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RESET_STORE } from '../utils/consts';
const initialState: boolean = false

const editTaskModeSlice = createSlice({
    name: 'editTaskClicked',
    initialState,
    reducers: {
        setTaskEditMode: (state, action: PayloadAction<boolean>) => {
            return action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
})

export const {setTaskEditMode} = editTaskModeSlice.actions
export default editTaskModeSlice.reducer
