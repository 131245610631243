import { createSlice } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';
import { User } from '../models/types';

const initialState: User = {
  id: 0,
  login:'',
  name: '',
  surname: '',
  email:'',
  role: '',
  company_id: 0,
    is_verified: false,
  teams: []
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
      setUser: (state, action) => {
          state.id = action.payload?.id;
          state.name = action.payload?.name;
          state.surname = action.payload?.surname;
          state.login = action.payload?.login;
          state.email = action.payload?.email;
          state.role = action.payload?.role;
          state.company_id = action.payload?.company_id;
          state.teams = action.payload?.teams;
      }
  },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
