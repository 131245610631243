import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RESET_STORE } from '../utils/consts';

const initialState: boolean = false

const createProcessClickedSlice = createSlice({
  name: 'createActionClicked',
  initialState,
  reducers: {
    setCreateProcessClicked: (state, action: PayloadAction<boolean>) => {
      return action.payload
    }
},
  extraReducers: (builder) => {
    builder.addCase(RESET_STORE, () => initialState);
  },
})

export const {setCreateProcessClicked} = createProcessClickedSlice.actions
export default createProcessClickedSlice.reducer
