import React, {useEffect} from 'react';
import locales from '../utils/locales/locales'
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../store/store";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import {User} from "../models/types";
import ky from "ky";
import {setUser} from "../slices/userSlice";

function VerificationFromEmail(): JSX.Element {
    const localisation = useSelector((state:RootState)=> state.localisation)
    const params = new URLSearchParams(window.location.search);
    const message = params.get('token');
    const API_BASE_URL = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();

    useEffect(() => {

        const ConfirmEmail = async () => {
            const result = await ky.post(`${API_BASE_URL}/user/confirm-email`,{credentials: 'include',json: { token: message }}).json();

            console.log(`RESULT`, result);
            console.log(`RESULT message`, result.message);

            result.message === 'User is verified' ?  navigate('/verification-success') :   navigate('/verification-error');
        }


        ConfirmEmail();

    }, []);

    return (
        <div className="policy-space">
            <div className="policy-block" style={{ paddingTop: 50, paddingBottom: 50, zIndex: '1' }}>
                <h1>Error verifying email: {message}</h1>
            </div>
        </div>
    )
}

export default VerificationFromEmail;
