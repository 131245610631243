import ky from "ky";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import { setProcess } from "../../slices/processObjectSlice";
import { Process } from "../../models/types";
import { setRightMenuVisibility } from "../../slices/rightMenuVisibility";
import { setProcessEditMode } from "../../slices/editProcessModeSlice";
import { setRightMenuShrink } from "../../slices/rightMenuShrink";
import { setProcessClicked } from "../../slices/processClickedSlice";
import EditorJsComponent from "./EditorJsComponent"
import EditorJS from '@editorjs/editorjs';
import locales from '../../utils/locales/locales'

const API_BASE_URL = process.env.REACT_APP_BASE_URL;


export default function RightMenuProcessEdit() {
  const dispatch = useDispatch();
  const process = useSelector((state: RootState) => state.process.actionsFromProcess);
  const [inputName, setInputName] = useState<string>(process.name);
  const [processInfo, setProcessInfo] = useState('');
  const visibility = useSelector((state: RootState) => state.rightMenuVisibility);
  const shrink = useSelector((state: RootState) => state.rightMenuShrink);
  const [editorInstance, setEditorInstance] = useState<any>(null);
  const localisation = useSelector((state:RootState)=> state.localisation)

  const handleInputName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputName(event.target.value);
  };

interface ProcessDto{
  id:number;
  name: string;
  process_info: string;
  company_id: number;
}
  const updateProcessInDB = async () => {
    try {
      const editorContent = await editorInstance?.save();
     const updateProcess:ProcessDto = await ky.put(`${API_BASE_URL}/process`, {
          credentials: "include",
          json: {
            processId: process.id,
            name: inputName,
            description: JSON.stringify(editorContent),
          },
        })
        .json();
        const focusProcess:Process= await ky.post(`${API_BASE_URL}/process/focus`,{ json: {processId: process.id }, credentials: 'include'}).json();
        if (updateProcess) {
          dispatch(setProcessClicked(updateProcess.id));
          dispatch(setProcess(focusProcess))
          dispatch(setProcessEditMode(false));
      }
    } catch (error) {
     console.log('ERROR', error);
    }
   };

   function cancelHandler() {
    dispatch(setProcessEditMode(false));
   }


  return (
    <>
    <div
      className={shrink ? "process-space-right process-space-right-shrink" : "process-space-right"}
      style={
        !visibility
            ? { display: "none" }
            : { display: "flex" }
      }
    >
      <div className="content">
        <div className="header">
          <div className="top">
            <div className="btns">
              <span
                onClick={() => dispatch(setRightMenuVisibility(!visibility))}
                className="right-tab-close-btn icon material-symbols-outlined"
              >
                close
              </span>
              <span
                onClick={() => dispatch(setRightMenuShrink(!shrink))}
                className="right-tab-close-btn icon material-symbols-outlined"
              >
                {shrink ? "expand_content" : "close_fullscreen"}
              </span>
            </div>
            <div className="btns">
                <div className="roundtag-btn-with-icon" onClick={cancelHandler}>
                <div className="icon material-symbols-outlined" >
                  cancel</div><b>{locales[localisation].Cancel}</b>
                </div>

                  <div className="roundtag-btn-with-icon" onClick={updateProcessInDB}>
                  <div className="icon material-symbols-outlined" >
                    SAVE</div><b>{locales[localisation].Save}</b>
                  </div>
              </div>
          </div>
          <div className="name">
            <h1 className='step-input-title'>
              <input
                className="h1-input"
                type="text"
                name="title"
                onChange={handleInputName}
                placeholder={locales[localisation].enter_new_process_name}
                value={inputName}
              />
            </h1>
          </div>
        </div>

        <div className="description">
          <div className="post editor-post">
            <EditorJsComponent  setEditorInstance={setEditorInstance} selectedAction={process} editMode={true}/>
          </div>
        </div>
      </div>
    </div>

    
  </>
  )
}
